import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import {store} from '../../stores/generalStore';
import {DOWNLOAD_FILE} from '../../middleware/downloadMiddleware';

import {NotificationManager} from 'react-notifications';


export const getCurrencies = (refresh = false) => dispatch =>  {

    const theUrl = rootApiUrl + urls.getCurrencies;

    if(!refresh) dispatch({
        type: "GET_CURRENCIES_BEGIN",
    })

    axios({
        withCredentials: true,
        url: theUrl
    }).then((response) => {
        if(response.data.Currencies){

            let modifiedCurrencies = {};
            response.data.Currencies.map(currency => {
                modifiedCurrencies[currency._id] = currency; 
            })

            dispatch({
                type: "GET_CURRENCIES_SUCCESS",
                payload: response.data,
                modifiedCurrencies: modifiedCurrencies
            })
        }
    })

}

export const visitLibrary = () => async dispatch => {
    dispatch({
        type: "VISIT_LIBRARY_END" 
    })
    dispatch({
        type: "VISIT_LIBRARY_START" 
    })
    setTimeout(() => {
        dispatch({
            type: "VISIT_LIBRARY_END" 
        })
    })
}

export const buyTrack = (currencyId, tracksArray, trackData, autoDownload = true, confirm = true) => async dispatch => {
   
    const checkDownloadLimitUrl = rootApiUrl + urls.tracks.checkDownloadLimit(currencyId);

    dispatch({
        type: "DOWNLOAD_TRACK_BEGIN"
    })

    const isLimitOk = await axios({
        url: checkDownloadLimitUrl,
        withCredentials: true
    }).then(response => {
        if(response.data.enabled){
            return true;
        } else {
            return false;
        }
    }).catch(error => {
        console.error(error)
    })

    const isPurchased = typeof trackData != 'undefined' && trackData.isPurchased;

    if(!isPurchased && !isLimitOk){ 
        alert('you reached your limit');
        dispatch({type: "DOWNLOAD_TRACK_FAILURE"})
        return false; 
    }

    console.log("continue");

    const currentState = store.getState().currencyReducer;
    const selectedCurrency = currentState.selectedCurrency;
    const creditBalance = selectedCurrency.creditBalance;

    //check if user has unlimited 
    const isInfinite = (creditBalance === -1);
    
    if(!isInfinite && (typeof trackData == 'undefined' || typeof trackData != 'undefined' && !trackData.isPurchased) && confirm){
          
        if(!window.confirm(`Do you wish to continue at the cost of ${tracksArray.length} credit?`)){ 
            dispatch({type: "DOWNLOAD_TRACK_FAILURE"});
            return false;
        }

    } 

    const purchaseTrackUrl = rootApiUrl + urls.tracks.purchaseTrack(currencyId);


    const params = tracksArray

    // return false;
    const canDownload = await axios({
        url: purchaseTrackUrl,
        data: params,
        method: "POST",
        withCredentials: true
    }).then(response => {
        const downloadResponse = response.data;

     
        if(downloadResponse.err){
            if(downloadResponse.err && downloadResponse.err === "insufficient-credits"){
                // alert("insuficient credits");
                NotificationManager.error('insuficient credits');
                dispatch({
                    type: "DOWNLOAD_TRACK_FAILURE" 
                })
            }
            return false;
        } else {
            if (downloadResponse.status && downloadResponse.status === 'already-purchased'){
                return downloadResponse
            } else if(downloadResponse.creditsDeducted){
                

                if(tracksArray.length > 1){
                    dispatch({
                        type: "OPEN_LIBRARY_MODAL_END" 
                    })
                    dispatch({
                        type: "OPEN_LIBRARY_MODAL_START" 
                    })
                    setTimeout(() => {
                        dispatch({
                            type: "OPEN_LIBRARY_MODAL_END" 
                        })
                    })
                }



                NotificationManager.success("Track(s) purchased, credits deducted: " + downloadResponse.creditsDeducted + '', null, 3000, ((event) => {
                    

                  
                    dispatch({
                        type: "VISIT_LIBRARY_START" 
                    })
                    setTimeout(() => {
                        dispatch({
                            type: "VISIT_LIBRARY_END" 
                        })
                    })


                }))

                return downloadResponse;

                
            } else if(downloadResponse.creditBalance === -1){

                if(tracksArray.length > 1){

                    dispatch({
                        type: "OPEN_LIBRARY_MODAL_END" 
                    })
                    
                    dispatch({
                        type: "OPEN_LIBRARY_MODAL_START" 
                    })
                    setTimeout(() => {
                        dispatch({
                            type: "OPEN_LIBRARY_MODAL_END" 
                        })
                    })
                }

                NotificationManager.success("Track(s) purchased", null, 3000, ((event) => {
                   
                }))

                return downloadResponse;
            }

        } 

    }).catch(error => {
        console.error(error)
        dispatch({
            type: "DOWNLOAD_TRACK_FAILURE" 
        })
    }) 
    
    if(!canDownload){ return false }; 

    //refresh track data

    const body = {
        downloadId: canDownload.download,
        trackId: canDownload.track[0],
        downloadType: canDownload.downloadType
    }

    const downloadUrl = rootApiUrl + urls.tracks.downloadTrack();
      
 
    if(autoDownload){
         await axios({
        url: downloadUrl,
        withCredentials: true,
        method: "POST",
        data: body,
        }).then(response => {

            // const url = window.URL.createObjectURL(new Blob([response.data.url]));f
            const url = response.data.url;
            const link = document.createElement('a');
            link.href = url;

            var filename = response.data.url.substring(response.data.url.lastIndexOf('/')+1);

    
        
            link.setAttribute('download', filename);
            // link.href = filename;
            document.body.appendChild(link);
            link.click();

            dispatch({
                type: "DOWNLOAD_TRACK_SUCCESS" 
            })

            dispatch(getCurrencies(true))  
        })

    } else {

        dispatch({
            type: "DOWNLOAD_TRACK_SUCCESS" 
        })

        dispatch(getCurrencies(true))  

    }

    const currentPlayerState = store.getState().playerReducer;
    //
    
    //refresh track data if the current active track is being downloaded
    if(currentPlayerState.activeTrack && currentPlayerState.activeTrack._id && (tracksArray[0] == currentPlayerState.activeTrack._id)){
       
    } else {
        return false;
    }

    const trackUrl = rootApiUrl + urls.tracks.getTrackById(tracksArray[0]);

    axios({
        url: trackUrl,
        withCredentials: true
    }).then(response => {
        if(response.data && response.data.Track){
            dispatch({
                type: "RELOAD_ACTIVE_TRACK_DATA",
                isPurchased: response.data.Track.isPurchased,
                isInDownloadQueue: response.data.Track.isinDownloadQueue,
            })
        }
    }).catch(error => {
        console.error(error);
    })
    

};

export const addToDownloadQueue = (currencyId, trackId) => async dispatch => {
    const theUrl = rootApiUrl + urls.downloadQueue.add(currencyId);

    const payload = {
        track: trackId,
    }

    dispatch({
        type: "ADD_TO_DOWNLOAD_QUEUE_BEGIN"
    })

    axios({
        url: theUrl,
        method: "POST",
        withCredentials: true,
        data: payload
    }).then(response => {
        console.log(response)
        // this.getTracks();
        dispatch({
            type: "ADD_TO_DOWNLOAD_QUEUE_SUCCESS"
        })

        
        //refresh track data if the current active track is being downloaded
        const currentPlayerState = store.getState().playerReducer;
        //
        
        if(currentPlayerState.activeTrack && currentPlayerState.activeTrack._id && (trackId == currentPlayerState.activeTrack._id)){
        
        } else {
            return false;
        }

        const trackUrl = rootApiUrl + urls.tracks.getTrackById(trackId);

        axios({
            url: trackUrl,
            withCredentials: true
        }).then(response => {
            console.log(response);
            if(response.data && response.data.Track){
                dispatch({
                    type: "RELOAD_ACTIVE_TRACK_DATA",
                    isPurchased: response.data.Track.isPurchased ,
                    isInDownloadQueue: response.data.Track.isInDownloadQueue ? true : false ,
                })
            }
        }).catch(error => {
            console.error(error);
        })


    })


}

export const removeFromDownloadQueue = (currencyId, trackId) => async dispatch => {
    const theUrl = rootApiUrl + urls.downloadQueue.remove(currencyId);

    const payload = {
        track: trackId,
    }

    dispatch({
        type: "REMOVE_FROM_DOWNLOAD_QUEUE_BEGIN"
    })

    axios({
        url: theUrl,
        method: "POST",
        withCredentials: true,
        data: payload
    }).then(response => {
        console.log(response)
        // this.getTracks();
        dispatch({
            type: "REMOVE_FROM_DOWNLOAD_QUEUE_SUCCESS"
        })

        
        //refresh track data if the current active track is being downloaded
        const currentPlayerState = store.getState().playerReducer;
        //
        
        if(currentPlayerState.activeTrack && currentPlayerState.activeTrack._id && (trackId == currentPlayerState.activeTrack._id)){
        
        } else {
            return false;
        }

        const trackUrl = rootApiUrl + urls.tracks.getTrackById(trackId);

        axios({
            url: trackUrl,
            withCredentials: true
        }).then(response => {
            console.log(response);
            if(response.data && response.data.Track){
                dispatch({
                    type: "RELOAD_ACTIVE_TRACK_DATA",
                    isPurchased: response.data.Track.isPurchased ,
                    isInDownloadQueue: response.data.Track.isInDownloadQueue ? true : false ,
                })
            }
        }).catch(error => {
            console.error(error);
        })


    })

}

export const setCurrency  = (currencyData) => dispatch => {
    dispatch({
        type: "SET_CURRENCY",
        payload: currencyData,
    })
}