const initialState = {
    genres: [],
    tags: [],
    keys: [],
    editors: [],
    crates: [],
    adData: [],
    modifiedEditors: [],
    areFiltersLoading: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_KEYS_BEGIN':
            return {
            ...state,
            areFiltersLoading: true
            }
        case "GET_ALL_KEYS_SUCCESS":
            return {
                ...state,
                areFiltersLoading: false,
                keys: action.payload
            }
        case 'GET_ALL_TAGS_BEGIN':
            return {
            ...state,
            areFiltersLoading: true
            }
        case "GET_ALL_TAGS_SUCCESS":
            return {
                ...state,
                areFiltersLoading: false,
                tags: action.payload
            }
        case 'GET_ALL_GENRES_BEGIN':
            return {
                ...state,
                areFiltersLoading: true
            }
        case "GET_ALL_GENRES_SUCCESS":
            return {
                ...state,
                areFiltersLoading: false,
                genres: action.payload
            }
        case "GET_ALL_EDITORS_BEGIN":
            return {
                ...state,
                areFiltersLoading: true,
            }
        case "GET_ALL_EDITORS_SUCCESS":
           
            return {
                ...state,
                areFiltersLoading: false,
                editors: action.payload,
                modifiedEditors: action.modifiedEditors,
            }
        case "GET_ALL_CRATES_BEGIN":
            return {
                ...state,
                cratesLoading: true,
            }
        case "GET_ALL_CRATES_SUCCESS":
            return {
                ...state,
                cratesLoading: false,
                crates: action.payload,
            }
        case "SET_SELECTED_CRATE_BEGIN":
            return {
                ...state,
            }
        case "SET_SELECTED_CRATE_SUCCESS":
            return {
                ...state,
                selectedCrate: action.payload
            }
        case "DESELECT_CRATE":
            return {
                ...state,
                selectedCrate: {}
            }
        case "CREATE_CRATE_BEGIN":
            return {
                ...state,
                creatingCrate: true,
            }
        case "GET_ALL_ADS_BEGIN":
            return {
                ...state,
                adsLoading: true,
            }
        case "CREATE_CRATE_SUCCESS":
            return {
                ...state,
                creatingCrate: false,
            }
        case "USE_AD_DATA":
            return {
                ...state,
                adData: action.payload,
                adIsLoaded: true,
            }
        case "RESET_AD_DATA":
            return {
                ...state,
                adData: [],
                adIsLoaded: false,
            }
        case "LOGOUT_SUCCESS":
            return {}
        case "CHECK_ACCOUNT_BEGIN":
            return {}
     default:
        return state
    }
   }