import React from 'react'

function SingleOption({options, chooseOption, selectedOption}) {
    return (
        <div className="multiple-options--wrapper">
            {options.map((option, index) => {
                const selectedClass = option._id === selectedOption ? "selected"  : "" ;
                return <div className={"multiple-options--single-option " + selectedClass} onClick={() => chooseOption(option._id)} key={index} id={option._id}>{option.name}</div>
            })}
        </div>
    )
}

export default SingleOption
