import React, {Component, useEffect} from 'react';

import txt from './version.txt';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';


export default class Version extends Component{

    constructor(props){
        super(props);
        this.state = {
            version: 'x',
            versionDate: "10 January 1970" 
        }
    }

    fetchFile = function(){
        fetch(txt)
        .then(response => response.text())
        .then(text => {

            const splitted = text.split(",");
            console.log('splited', {splitted})
            // return false
            const theDate= new Date(parseInt(splitted[1]));
            this.setState({
                version: splitted[0],
                versionDate:  theDate.toString()
            })
        })
    }

    componentDidMount(){
        this.fetchFile();
    }

    render(){
        return (
            <div className="app--center-wrapper">
                Hi. The build version is {this.state.version}, {this.state.versionDate}
                </div>
        )
    }

}