import React, { Component } from 'react';

import {Link, withRouter} from 'react-router-dom';

import {Form, Field} from 'react-final-form';

import Loader from 'react-loader-spinner';

import axios from 'axios';

import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';
// import console = require('console');

import { Button} from "react-bootstrap";


import {NotificationManager} from 'react-notifications';


class VerifyCustomerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isFormSubmitted: false,
            isLoading: false,
            responseMessage: 'fail',
            validationError: false,
            registrationComplete: false,
            countries: [
                'Afghanistan',
                'Albania',
                'Algeria',
                'Andorra',
                'Angola',
                'Antigua and Barbuda',
                'Argentina',
                'Armenia',
                'Aruba',
                'Australia',
                'Austria',
                'Azerbaijan',
                'Bahamas, The',
                'Bahrain',
                'Bangladesh',
                'Barbados',
                'Belarus',
                'Belgium',
                'Belize',
                'Benin',
                'Bhutan',
                'Bolivia',
                'Bosnia and Herzegovina',
                'Botswana',
                'Brazil',
                'Brunei',
                'Bulgaria',
                'Burkina Faso',
                'Burma',
                'Burundi',
                'Cambodia',
                'Cameroon',
                'Canada',
                'Cabo Verde',
                'Central African Republic',
                'Chad',
                'Chile',
                'China',
                'Colombia',
                'Comoros',
                'Congo, Democratic Republic of the',
                'Congo, Republic of the',
                'Costa Rica',
                "Cote d'Ivoire",
                'Croatia',
                'Cuba',
                'Curacao',
                'Cyprus',
                'Czechia',
                'Denmark',
                'Djibouti',
                'Dominica',
                'Dominican Republic',
                'East Timor (see Timor-Leste)',
                'Ecuador',
                'Egypt',
                'El Salvador',
                'Equatorial Guinea',
                'Eritrea',
                'Estonia',
                'Ethiopia',
                'Fiji',
                'Finland',
                'France',
                'Gabon',
                'Gambia, The',
                'Georgia',
                'Germany',
                'Ghana',
                'Greece',
                'Grenada',
                'Guatemala',
                'Guinea',
                'Guinea-Bissau',
                'Guyana',
                'Haiti',
                'Holy See',
                'Honduras',
                'Hong Kong',
                'Hungary',
                'Iceland',
                'India',
                'Indonesia',
                'Iran',
                'Iraq',
                'Ireland',
                'Israel',
                'Italy',
                'Jamaica',
                'Japan',
                'Jordan',
                'Kazakhstan',
                'Kenya',
                'Kiribati',
                'Korea, North',
                'Korea, South',
                'Kosovo',
                'Kuwait',
                'Kyrgyzstan',
                'Laos',
                'Latvia',
                'Lebanon',
                'Lesotho',
                'Liberia',
                'Libya',
                'Liechtenstein',
                'Lithuania',
                'Luxembourg',
                'Macau',
                'Macedonia',
                'Madagascar',
                'Malawi',
                'Malaysia',
                'Maldives',
                'Mali',
                'Malta',
                'Marshall Islands',
                'Mauritania',
                'Mauritius',
                'Mexico',
                'Micronesia',
                'Moldova',
                'Monaco',
                'Mongolia',
                'Montenegro',
                'Morocco',
                'Mozambique',
                'Namibia',
                'Nauru',
                'Nepal',
                'Netherlands',
                'New Zealand',
                'Nicaragua',
                'Niger',
                'Nigeria',
                'North Korea',
                'Norway',
                'Oman',
                'Pakistan',
                'Palau',
                'Palestinian Territories',
                'Panama',
                'Papua New Guinea',
                'Paraguay',
                'Peru',
                'Philippines',
                'Poland',
                'Portugal',
                'Qatar',
                'Romania',
                'Russia',
                'Rwanda',
                'Saint Kitts and Nevis',
                'Saint Lucia',
                'Saint Vincent and the Grenadines',
                'Samoa',
                'San Marino',
                'Sao Tome and Principe',
                'Saudi Arabia',
                'Senegal',
                'Serbia',
                'Seychelles',
                'Sierra Leone',
                'Singapore',
                'Sint Maarten',
                'Slovakia',
                'Slovenia',
                'Solomon Islands',
                'Somalia',
                'South Africa',
                'South Korea',
                'South Sudan',
                'Spain',
                'Sri Lanka',
                'Sudan',
                'Suriname',
                'Swaziland',
                'Sweden',
                'Switzerland',
                'Syria',
                'Taiwan',
                'Tajikistan',
                'Tanzania',
                'Thailand',
                'Timor-Leste',
                'Togo',
                'Tonga',
                'Trinidad and Tobago',
                'Tunisia',
                'Turkey',
                'Turkmenistan',
                'Tuvalu',
                'Uganda',
                'Ukraine',
                'United Arab Emirates',
                'United Kingdom',
                'United States',
                'Uruguay',
                'Uzbekistan',
                'Vanuatu',
                'Venezuela',
                'Vietnam',
                'Yemen',
                'Zambia',
                'Zimbabwe'
            ],
            formDetails: {
                firstName: '',
                lastName: '',
                email: '',
            }
         }
    }


    submitForm(formData){

        console.log('submiting verify form');

        this.setState({
            isLoading: true,
        })

        let postData = {
            addressText: formData.address,
            cid: formData.cid,
            uid: formData.uid,
            country: formData.country,
            jobTitle: formData.job,
            phoneNumber: formData.phone,
            stageName: formData.stageName,
            passwords: {
                password: formData.password,
                repeatPassword: formData.confirmPassword,
            }
        }

        axios({
            method: "POST",
            withCredentials: true,
            data: postData,
            url: rootApiUrl + urls.activate,
        }).then(response => {
            if(response.data.error){
                NotificationManager.error(response.data.error);

            } else {
                NotificationManager.success("Account registered successfuly");
                this.setState({
                    registrationComplete: true
                })
                
            }
            
            this.setState({
                isLoadding: false,
                isFormSubmitted: true,

            })
        }).catch(error => {
           
            NotificationManager.error("An error occurred");

            this.setState({
                isLoading: false,
                isFormSubmitted: false,
            })
        })

    }

    validate(formData){
        console.log('verifiy form data', formData)
        this.setState({
            validationError: false,
        })

        if(this.state.isFormSubmitted){
            return false;
        }

        let isEmailValid = true;

        if(typeof formData.stageName == 'undefined' || formData.stageName == ""){
            this.setState({
                validationError: 'Please complete the name',
            })
            return false;
        } else {
            isEmailValid = true;
        }

        if(typeof formData.password == 'undefined' || typeof formData.confirmPassword == 'undefined' || formData.password !== formData.confirmPassword || formData.password.length < 7){
            this.setState({
                validationError: 'Please check the password field(s)',
            })
            return false;
        }



        //get params from url
        let searchParams = new URLSearchParams(this.props.location.search);
        let cid = searchParams.get('cid');
        let uid = searchParams.get('uid')

        if(cid == null || uid == null){
            this.setState({
                validationError: "URL parameters are not present. Report to an administrator",
            })
        }

        formData.cid = cid;
        formData.uid = uid;


        setTimeout(() => {
            if(!this.state.validationError){
                this.submitForm(formData);
            }
        })

     
    

    }

    render(match, location) { 

        let searchParams = new URLSearchParams(this.props.location.search);
        let emailAddress = searchParams.get('email');
     
        // alert(this.props);

        const {isFormSubmitted, isLoading, responseMessage, validationError, registrationComplete} = this.state;

        return ( 
        <section className="new-customer--wrapper app--center-wrapper">
          
            <div className="new-customer-title">
                <div className="new-customer--top-info">
               
                    <div className="new-customer--email">Your confirmed email address is: <span>{emailAddress ? emailAddress : 'unknown'}</span></div>
                    <div className="new-customer--tips">When logging in or contacting us, always do so from this email address for a fast, quality service experience.</div>
                </div>

                <h2 className="new-customer--step"><span>Step 2</span>: Continue registration process</h2>

                {registrationComplete ? <Link to="/login"> <Button block bsSize="large">Login</Button></Link> : isLoading ? <Loader /> : <Form
                //  validate={this.validate.bind(this)}
                onSubmit={(formData) => {this.validate(formData)}}
                render={({handleSubmit, pristine, invalid}) => (
                    <form className="new-customer--form" onSubmit={handleSubmit}>
                       <label>Password: (Enter twice, Must Be At Least 8 Characters Long)</label>
                        <div className="register--line">
                            <div className="register--form-group">
                              
                                <Field type="password" name="password" placeholder="password" component="input" autocomplete="off" />
                            </div>
                            <div className="register--form-group">
                           
                                <Field type="password" name="confirmPassword" placeholder="confirm-password" component="input" autocomplete="off"/>
                            </div>
                        </div>
                     
                        <label>Stage Name: (When you perform what name do you use?)</label>
                        <div className="register--line">
                            <div className="register--form-group">
                                <Field name="stageName" component="input" placeholder="Stage name" autoComplete="off" />
                            </div>
                           
                        </div>
                        
                        <label>Address</label>
                        <div className="register--line">
                            <div className="register--form-group">
                                    <Field name="address" placeholder="Address " component="input" />
                            </div>
                        </div>

                        <label>Country</label>
                        <div className="register--line">
                            <div className="register--form-group">
                                    <Field name="country" placeholder="Country" component="select" defaultValue="United States" >
                                        {this.state.countries.map(country => {
                                            return <option>{country}</option>
                                        })}
                                    </Field>
                            </div>
                        </div>

                        <label>Job</label>
                        <div className="register--line">
                                <div className="register--form-group">
                                    <Field name="job" placeholder="job" component="input" />
                                </div>
                        </div>

                        <label>Phone</label>
                        <div className="register--line">
                        <div className="register--form-group">
                                    <Field name="phone" placeholder="phone" component="input" />
                                </div>
                        </div>
                        {!isFormSubmitted && <Button
                        block
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        >Submit</Button>}

                    </form>
                )}
                >
                   
                </Form>}
                {validationError && <div>{validationError}</div>}
            </div>
          
        </section>
         );
    }
}
 
export default withRouter(VerifyCustomerContainer);