import axios from "axios";

import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

export const getAllKeys = () => dispatch =>  {

    dispatch({
        type: "GET_ALL_KEYS_BEGIN"
    })

    const theUrl = rootApiUrl + urls.getAllKeys;

    axios({
        withCredentials: true,
        url: theUrl,
    }).then(response => {
        if(response.data && response.data.Keys){
            dispatch({
                type: "GET_ALL_KEYS_SUCCESS",
                payload: response.data.Keys
            })
        }
    })
}


export const getAllTags = () => dispatch =>  {
    const theUrl = rootApiUrl + urls.getAllTags;

    dispatch({
        type: "GET_ALL_TAGS_BEGIN"
    })

    axios({
        withCredentials: true,
        url: theUrl,
    }).then(response => {
        if(response.data && response.data.Tags){
            dispatch({
                type: "GET_ALL_TAGS_SUCCESS",
                payload: response.data.Tags,
            })
        }
    })
}


export const getAllGenres = () => dispatch =>  {
    dispatch({
        type: "GET_ALL_GENRES_BEGIN"
    })

    const theUrl = rootApiUrl + urls.getAllGenres;

    axios({
        withCredentials: true,
        url: theUrl,
    }).then(response => {
        if(response.data && response.data.Genres){
            dispatch({
                type: "GET_ALL_GENRES_SUCCESS",
                payload: response.data.Genres
            })
        }
    })
}

export const getAllEditors = (currencyId) => dispatch => {
    dispatch({
        type: "GET_ALL_EDITORS_BEGIN",
    })

    const theUrl = rootApiUrl + urls.editors.getEditorsByCurrency(currencyId);

    axios({
        withCredentials: true,
        url: theUrl,
    }).then(async (response) => {
        if(response.data && response.data.CurrencyEditors){

            let modifiedEditors = {};

            await response.data.CurrencyEditors.map(editor => {
                modifiedEditors[editor._id] = editor;
            })


            dispatch({
                type: "GET_ALL_EDITORS_SUCCESS",
                payload: response.data.CurrencyEditors,
                modifiedEditors: modifiedEditors,
            })
        }
    })
}

export const getCrates = (currencyId) => dispatch => {
    
    dispatch({
        type: "GET_ALL_CRATES_BEGIN"
    })

    const theUrl = rootApiUrl + urls.crates.getCrates(currencyId);

    axios({
        withCredentials: true,
        url: theUrl
    }).then(response => {
        if(response.data && response.data.Crates){
            dispatch({
                type: "GET_ALL_CRATES_SUCCESS",
                payload: response.data.Crates
            })
        }
    })
}

export const setSelectedCrate = (currencyId, crateId) => dispatch => {
    dispatch({
        type: "SET_SELECTED_CRATE_BEGIN"
    })

    axios({
        withCredentials: true,
        url: rootApiUrl + urls.crates.getSingleCrate(currencyId, crateId)
    }).then(response => {
        if(response && response.data.Crate){
            dispatch({
                type: "SET_SELECTED_CRATE_SUCCESS",
                payload: response.data.Crate,
            })
        }
    })
}

export const deselectCrate = () => dispatch => {
    dispatch({
        type: "DESELECT_CRATE"
    })
}

export const createCrate = (currencyId, crateData) => dispatch => {
    dispatch({
        type: "CREATE_CRATE_BEGIN"
    })
}

export const getAllAds = (currencyId) => dispatch => {

    const params = {
        getAll: true,
        isValid: true,
        currency: currencyId,
        status: "approved",
    }

    axios({
        withCredentials: true,
        url: rootApiUrl + urls.getAllAds()
    }).then(response => {
        if(response.data){
            console.log(response.data);
        }
    })

}

export const useAdData = (adData) => dispatch => {

    dispatch({
        type: "USE_AD_DATA",
        payload: adData
    })

    const theTrack = adData.tracks[0];

    dispatch({
        type: "SET_ACTIVE_TRACK_SUCCESS",
        payload: theTrack,
    })

}

export const resetAdData = () => dispatch => {

    dispatch({
        type: "RESET_AD_DATA",
    })

}