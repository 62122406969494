import React, { Component } from "react";
import "./styles/App.scss";
import Modal from "react-modal";
import { connect } from "react-redux";
import * as actions from "./actions/index";
import BrowserDetection from "react-browser-detection";
import { BrowserRouter } from "react-router-dom";
import V4Logo from './images/v4.png';
import Main from "./containers/mainContainer";
import Header from "./containers/headerContainer";
import PlayerContainer from "./containers/playerContainer";
import Footer from "./containers/footerContainer";

import Loader from "react-loader-spinner";

import { NotificationContainer } from "react-notifications";

const log = (...msgs) => {
  if (process.env.NODE_ENV === "development") console.log(...msgs);
};

global.log = log;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }
  openModal() {
    console.log("Firefox browser detected!");
    this.setState({
      modalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    this.props.checkAccount();
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.selectedCurrency &&
      previousProps.selectedCurrency._id &&
      this.props.selectedCurrency &&
      this.props.selectedCurrency._id &&
      this.props.selectedCurrency._id !== previousProps.selectedCurrency._id
    ) {
    }
  }

  browserHandler = {
    firefox: () => {
      this.openModal();
      return null;
    },
  };

  render() {
    return (
      <BrowserRouter>
        <NotificationContainer />

        {this.props.isLoading ? (
          <div className="app--center-wrapper">
            <Loader />
          </div>
        ) : (
          <div className="App">
            <BrowserDetection>{this.browserHandler}</BrowserDetection>
            <Modal
              className="package-modal"
              isOpen={this.state.modalIsOpen}
              ariaHideApp={false}
            >
              <div className="package--inner-wrapper">
                We are aware of a bug that may create issues in audio/video
                streaming playback using some versions of Firefox. Please use a
                different browser while we work on a fix for this issue.
                <br />
                <br />
                <br />
                <div className="package-modal--close">
                  <button onClick={this.closeModal.bind(this)}>close</button>
                </div>
              </div>
            </Modal>
            <div className="non-player---wrapper">
              <Header />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a target="_blank" href="https://app.crooklynclan.net/auth/login">
                  <img src={V4Logo} alt="V4Logo" style={{maxWidth: "100%"}}  />
                </a>
              </div>
              <Main />
              <Footer />
            </div>
            <div className="player--wrapper">
              <PlayerContainer />
            </div>
            {/* <div className="debug-container">{JSON.stringify(this.props)}</div> */}
          </div>
        )}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.currencyReducer });

const mapDispatchToProps = (dispatch) => ({
  checkAccount: () => dispatch(actions.checkAccount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
