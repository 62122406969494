import React, {Component, useCallback, useMemo} from 'react';

import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';

import Loader from 'react-loader-spinner';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function StyledDropzone(props) {

    const {isUploading, selectedCurrency} = props;
    const isVideoVault = (selectedCurrency === '573acdea0424de743256b6e9');

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        console.log('dropped a file', {acceptedFiles, rejectedFiles})
        // Do something with the files
        acceptedFiles.forEach((file) => {
      
          props.startUpload(file);
           
        })

        rejectedFiles.forEach((file) => {
          props.rejectFileType({...file, reason: "Invalid file type"});
        })

      }, [])

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({accept: isVideoVault? 'video/*' : 'audio/*', onDrop});
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject
    ]);
  
    return (
      <div className="dropzone-container">
        {isUploading && <div className="dropzone-loader">
          <div className="dropzone-loader-spinner-wrapper">
            <Loader />
          </div>
          <div className="track-dropzone-progress">
            {props.uploadProgress}%
          </div>
        </div>}
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      </div>
    );
  }