import React, { Component } from 'react';
import {connect} from 'react-redux';


import Loader from 'react-loader-spinner';

export default class Banner extends Component {
    render(){
        return <div>Banner inner section</div>
    }
}