import React, { Component } from 'react';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';

import Loader from 'react-loader-spinner';

import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import {NotificationManager} from 'react-notifications';

import { Button } from "react-bootstrap";


function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }


  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  
   
  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => {
    console.log('rendering suggestion', suggestion)
    return (
        <div editorId={suggestion._id}>
        {suggestion.stageName}
        </div>
    );
  }

class EditorSuggest extends React.Component {
    constructor() {
      super();
   
      // Autosuggest is a controlled component.
      // This means that you need to provide an input value
      // and an onChange handler that updates this value (see below).
      // Suggestions also need to be provided to the Autosuggest,
      // and they are initially empty because the Autosuggest is closed.
      this.state = {
        value: '',
        suggestions: []
      };

      this.lastRequestId = null;
    }
   
    onChange = (event, { newValue }) => {
        // debugger;
        console.log('changin value', newValue)
        this.setState({
            value: newValue
        });

        // debugger;
       
    };

    getSuggestionValue = suggestion => {
        console.log('getting suggestion value');

        this.props.setValue(suggestion._id)
    
        return suggestion.stageName;
    
      };

    getSuggestions = value => {

        if (this.lastRequestId !== null) {
            clearTimeout(this.lastRequestId);
        }
    
        this.setState({
            isLoading: true
        });
    
    
        // debugger;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
    
        this.lastRequestId = setTimeout(async () => {

            console.log('setting new suggestions');

            let suggestions;
            suggestions = await this.props.getData(value); 

            console.log('setting new suggestions', suggestions);

            suggestions = suggestions.map(suggestion => suggestion)

            this.setState({
              isLoading: false,
              suggestions:suggestions
            });
          },0);
       
        
      };
       
   
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        console.log("onSuggestionsFetchRequested")
        this.getSuggestions(value);
    };
   
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
   
    render() {
      const { value, suggestions } = this.state;
   
      // Autosuggest will pass through all these props to the input.
      const inputProps = {
        placeholder: 'Type an author',
        value,
        onChange: this.onChange
      };
   
      // Finally, render it!
      return (
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      );
    }
  }

class Inventory extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            revisionCurrency: '',
            revisionEditor: '',
            editorResults: [],
            zombieCurrency: '',
            countedTracks: null,
        }
    }


    getEditorData(stageName){

        console.log('getting editor data', stageName);
        const url = rootApiUrl + urls.editors.searchEditors(stageName)

        return axios({
            withCredentials: true,
            url: url
        }).then(response => {
            console.log(' response.data is ', response.data);
            if(response.data && response.data.Editors){
                return response.data.Editors.results;
            } else {
                return [];
            }
            // return response.data;
        }).catch(error => {
            console.log('there was an error while searching for editors', error);
        })
    }

    setRevisionCurrency(value){
        this.setState({
            revisionCurrency: value,
        })
    }


    setZombieCurrency(value){
        this.setState({
            zombieCurrency: value,
        })

        this.countZombie(value)
    }

    setRevisionEditor(editor){
        const {revisionCurrency} = this.state;

        if(!revisionCurrency){
            alert('please select revision currency first');
            return this.setState({
                revisionEditor: '',
            })
        }

        this.setState({
            revisionEditor: editor,
        })

        if(editor){
            this.countTracks(editor)
        }
    }

    deleteTracks(){
        console.log('deleting tracks');

        const {revisionCurrency, revisionEditor} = this.state;

        if(!revisionCurrency || !revisionEditor){
            return alert('both currency and an editor must be selected')
        }



        axios({
            withCredentials: true,
            url: rootApiUrl + urls.editors.deleteTracksInRevisionMode(revisionCurrency, revisionEditor)
        }).then(response => {
            if(response.data){
                const {tracks, releases} = response.data.Count;
                NotificationManager.success(`successfully deleted ${tracks} tracks and ${releases} releases`)
            }
        }).catch(error => {
            if(error){
                alert(error);
            }
        })
    }

    countTracks(editor){
        this.setState({
            countedTracks: null,
        })

        const {revisionCurrency} = this.state;
        const revisionEditor = editor;

        console.log('counting tracks');

        if(!revisionCurrency){
            return alert("We cannot count the number of facts because you need to first choose a currency. Please choose a currency and then select the editor")
        }

        if(!revisionEditor){
            return false;
        }
        
        axios({
            withCredentials: true,
            url: rootApiUrl + urls.editors.countTracksInRevisionMode(revisionCurrency, editor)
        }).then(response => {
            if(response.data && response.data){
               
                this.setState({
                    countedTracks: response.data.Count
                })
            }
        }).catch(error => {
            if(error){
                return alert('there was an error counting the number of tracks in revision mode');
                
            }
        })


    }

    countZombie(zombie){

        if(!zombie){
            zombie = this.state.zombieCurrency
        }

        axios({
            withCredentials: true,
            url: rootApiUrl + urls.countZombieTracks(zombie)
        }).then(response => {
            if(response.data){

            }
        }).catch(error => {

        })
    }

    moveTracks(){
        const {zombieCurrency} = this.state;

        if(!zombieCurrency){
            return alert('please select a zombie currency');
        }

        axios({
            withCredentials: true,
            url: rootApiUrl + urls.moveZombieTracks(zombieCurrency),
            method: "POST"
        }).then(response => {
            console.log('moved zombie tracks')
        })
    }

    render(){
        const {currencies} = this.props;
        const { value, suggestions, countedTracks } = this.state;

        return (
            <div>
                <div>
                    
                    <div>
                        <h5>Delete tracks in revision mode</h5>
                    </div>

                    <div className="revision-mode--list">
                        <select defaultValue="#" onChange={(e) => this.setRevisionCurrency(e.target.value)}>
                            <option value="#" disabled>Select currency</option>
                            {currencies && currencies.Currencies && currencies.Currencies.map((currency,index) => {
                                return <option key={index} value={currency._id} >{currency.name}</option>
                            })}
                        </select>
                        <EditorSuggest
                            getData={(name) => this.getEditorData(name)}
                            setValue={(editor) => this.setRevisionEditor(editor)}
                        />
                        
                        <Button onClick={() => this.deleteTracks()}>Delete tracks </ Button>
                    </div>

                    {countedTracks != null && <div>
                        <p>{countedTracks} tracks in revision mode</p>
                    </div>}

                </div>
                <div>
                    <div className="admin-row">
                        <h5>Move zombie tracks to vault classics editor</h5>
                    </div>
                    <div className="admin-row">
                     
                        <select defaultValue="#" onChange={(e) => this.setZombieCurrency(e.target.value)}>
                            <option selected disabled>Select currency</option>
                            {currencies && currencies.Currencies && currencies.Currencies.map((currency,index) => {
                                return <option key={index} value={currency._id} >{currency.name}</option>
                            })}
                        </select>
                        <Button onClick={() => this.moveTracks()}>
                            Move tracks
                        </Button>

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({...state.currencyReducer})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);