

export const apiUrl = {
    localDev: "http://localhost:5000/api/v1",
    staging: "https://crooklyn-clan-staging.herokuapp.com/api/v1",
    production: "https://v2-beta.crooklynclan.net/api/v1",
};

const isDev = process.env.NODE_ENV == "development" ? true : false;
const isProd = process.env.REACT_APP_IS_PRODUCTION == "production" ? true : false;

// console.log('process env', process.env);
// 
// console.log('is Production env?', isProd);
export let rootApiUrl = isDev ? apiUrl.localDev : isProd ? apiUrl.production : apiUrl.staging 

export default {
    login: '/members/account/login',
    logout: '/members/account/logout',
    account: '/members/account/get',
    passwordReset: '/members/account/passwordResetRequest',
    submitNewPassword: '/members/account/resetPassword',
    updateAccount: '/members/account/update',
    myTransactions: '/members/account/transactions/list',
    getCurrencies: '/members/currency/list',
    getAllGenres: '/members/genres/list',
    getAllTags: '/members/tags/list',
    getAllKeys: '/members/keys/list',
    getPackages: '/members/currency/packages/list',
    register: '/members/account/create',
    activate: '/members/account/activate',
    getPoorTracks: '/members/track/getPoorTracks',
    moveZombieTracks: currencyId => `/members/account/admin/${currencyId}/moveZombieTracksToVaultClassicsAccount/`,
    countZombieTracks: currencyId => `/members/account/admin/${currencyId}/countTracksForLeftEditors`,
    payment: {
        purchasePackage: (packageId) => `/members/package/${packageId}/createPayment`,
        executePayment: (packageId) => `/members/package/${packageId}/executePayment`,
    },
    getAllAds: (pageNr = 1) => `/members/account/editorads/list/${pageNr}`, 
    crates: {
        getCrates: (currencyId) => `/members/currency/${currencyId}/crates/list`,
        getSingleCrate: (currencyId, crateId) => `/members/currency/${currencyId}/crates/${crateId}/get`,
        removeSingleCrate: (currencyId, crateId) => `/members/currency/${currencyId}/crates/${crateId}/remove`,
        createCreate: (currencyId) => `/members/currency/${currencyId}/crates/create`
    },
    genres: {
        getGenres: (currencyId) => `/members/currency/${currencyId}/getTopGenres`,
        getGenreByName : (genreName) => `/members/genres/${genreName}/getGenreByName`,
    },
    tracks: {
        getTracks: (currencyId, pageNumber) => '/members/currency/'+currencyId+'/tracks/list/'+pageNumber,
        purchaseTrack: (currencyId) => '/members/currency/'+currencyId+'/purchase',
        checkDownloadLimit: (currencyId, hours = 24) => `/members/currency/${currencyId}/checkDownloadLimit?hours=${hours}`,
        downloadTrack: () => '/members/download/get',
        getTrackById: (trackId) => `/members/track/${trackId}/get`,
        getHotBox: (currencyId, page=1) => `/members/currency/${currencyId}/hotboxes/list/${page}`,
 
    },
    editors: {
        getChart: (currencyId) => '/members/currency/'+currencyId+'/charts/list',
        getEditorByStageName: (stageName) => '/members/editors/'+stageName+'/getEditorByStageName',
        getEditorById: (id) => "/members/editors/"+id+"/get",
        getEditorsByCurrency: (currencyId) => `/members/currency/${currencyId}/editors/list`,
        contact: () => `/members/account/book`,
        searchEditors: (stageName, currentPage = 1, rows=3) => `/members/editors/searchEditors?${currentPage}=1&rows=${rows}&stageName=${stageName}`,
        countTracksInRevisionMode: (currencyId, editorId) => `/members/account/admin/countTracksInRevisonMode?currencyId=${currencyId}&editorId=${editorId}`,
        deleteTracksInRevisionMode: (currencyId, editorId) => `/members/account/admin/deleteTracksInRevisonMode?currencyId=${currencyId}&editorId=${editorId}` ,
        createPreview : (currencyId) => {
            const isVideoVault = (currencyId == '573acdea0424de743256b6e9');
            return isVideoVault ? `/members/track/createVideoPreview?currencyId=${currencyId}` : `/members/track/createPreview?currencyId=${currencyId}`
        },
        updatePreview: (trackId) => `/members/track/updatePreview`,
        viewDrafts:(currencyId, page=1) => `/members/track/list/drafts?currencyId=${currencyId}&page=${page}`,
        deleteTrack:(trackId) => `/members/track/${trackId}/remove`,
        createRelease:() => `/members/releases/create`,
        getRelease: (releaseId) => `/members/releases/${releaseId}/get`,
        updateTrack: (trackId) => `/members/track/${trackId}/update`,
        publishTracks: (track) => `/members/publish`,
        checkReleaseName: (releaseName) => `/members/releases/check`,
        deleteRelease: (releaseId) => `/members/releases/${releaseId}/remove`,
        getAllReleases: `/members/releases/list/draft`,
        getOriginalWorks: `/members/originalworks/list`,
        
    },
    collections: {
        listCollections: (currencyId, pageNumber = 1) => '/members/currency/'+currencyId+'/collections/list/'+pageNumber,
        getEditorPackByName : (packName) => '/members/editors/'+packName+'/getEditorPackByName',
        getPackById: (currencyId, id, pageNumber=1) => '/members/currency/'+currencyId+'/collections/'+id+'/get/'+pageNumber,
        purchasePack: (currencyId) => `/members/currency/${currencyId}/purchasePack`,
    },
    transactions:{
        getSingleTransaction: (transactionId) => `/members/transaction/${transactionId}/get`,
    },
    downloadQueue: {
        get: (currencyId, pageNumber) => '/members/account/downloadQueue/currency/'+currencyId + '/1',
        add: (currencyId) => {return '/members/account/downloadQueue/currency/'+currencyId+'/add'},
        remove: (currencyId) => '/members/account/downloadQueue/currency/'+currencyId+'/remove',
        move: (currencyId) => '/members/account/downloadQueue/currency/'+currencyId+'/move',
    },
    myLibrary: (currencyId, page = 1) => `/members/account/library/currency/${currencyId}/list/${page}`
}