import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {logger} from 'redux-logger';
import rootReducer from '../reducers/rootReducer';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['currencyReducer', 'selectedCurrency', 'playerReducer'],  
}



const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {
    userReducer: {
        isLoading: true,
    },
    currencyReducer: {
        isLoading: true,
        selectedCurrency: null,
    }, 
    trackReducer: {
        searchQuery: 'reducer-query',
    },
    globalReducer: {
        isLoading: true,
    }
}

export const store = createStore (
        persistedReducer,
        initialState,
        process.env.NODE_ENV == "development" ? compose(applyMiddleware(thunk, logger)) : compose(applyMiddleware(thunk))
    )

export const persistor = persistStore(store);