import React, { Component } from 'react';



class SideAdsContainer extends Component {
    state = {  }
    render() { 
        return ( 
            <section className="side-ads--wrapper">
                  <iframe src="https://www.crooklynclan.net/app-iframe-main" frameBorder="0" scrolling="no"></iframe>
            </section >
         );
    }
}
 


export default SideAdsContainer;