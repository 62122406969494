import React, { Component } from 'react';

import {connect} from 'react-redux';

import {PlayButton, Timer, Progress} from 'react-soundplayer/components';
import {withCustomAudio} from 'react-soundplayer/addons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle, faArrowCircleDown, faPlusCircle, faCartArrowDown, faCheckCircle, faVolumeUp, faInfoCircle, faList } from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-rangeslider';
import Loader from 'react-loader-spinner';

import classNames from 'classnames';

import * as actions from '../actions/index';

import PlayedTracks from '../components/general/playedTracks';

function VolumeSlider({volume, onChangeVolume}){
    return (
        <Slider
        min={0}
        max={100}
        value={volume}
        tooltip={false}
        orientation="vertical"
        onChange={onChangeVolume}
        />
    )
}


function Volume({volumeVisible, volume, onChangeVolume}){
    const isMuted = !volume;

    const volumeClass = classNames(
        'volume',
        {
         'volume-visible': volumeVisible   
        }
    )

    return (
        <div className={volumeClass}>    
            <h2 className="volume-number">100</h2>
            <VolumeSlider 
            volume={volume}
            onChangeVolume={onChangeVolume}
            />
        </div>
    )
}

class PlayerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            progress: 0,
            isWaveformChanged: false,
            isVolumeDisplayed: false,
            isPlayedTracksDisplayed: false,
            isDetailedView: false,
            playerHeight: 0,
        }

        this.audioElement = React.createRef();
        this.handleSeek = this.handleSeek.bind(this);
        this.handleCanPlay = this.handleCanPlay.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        
    }

    shouldComponentUpdate(nextProps, nextState){
        const {audioElement} = this;

        let isAudioSet = (audioElement && audioElement.addEventListener) ? true : false ;

        const {progressChanger} = nextProps;

        if(isAudioSet){

            const currentProgress = this.props.trackProgress;
            const nextProgress = nextProps.trackProgress;

            // console.log("currentProgress", currentProgress);
            // console.log("nextProgress", nextProgress);
            if(progressChanger === "waveform" && (currentProgress != nextProgress)){
                const nextTime =  nextProgress * (this.state.duration / 100) ;
                audioElement.currentTime = nextTime;
            }
            
        }

        if(progressChanger === "waveform"){
            return false;
        }


        return true;
    }


    componentDidUpdate(prevProps){
        // console.log('updating playerContainer');
        const {isPlaying, volume, isPlayerActive, activeTrack} = this.props;
        const {audioElement} = this;

        let isAudioSet = (audioElement && audioElement.addEventListener) ? true : false ;

        if(isAudioSet){
            audioElement.addEventListener('canplaythrough', this.handleCanPlay, false);   
            audioElement.addEventListener('ended', () => {
                console.log('ended');
                // alert('ended')
                // audioElement.pause();

                // this.props.togglePlay();
                // this.handlePlay.bind(this)
                // this.handlePause();
                this.handlePause();
            })
        }

        console.log('audioElement', audioElement);
        console.log('isAudioSet', isAudioSet);
        console.log('isPlaying', isPlaying);
        console.log('this.state.stopped', this.state.stopped);
        
        if(isAudioSet){
            // const trackAudio = document.querySelector("audio");
            // const trackUrl = activeTrack.status !== 'draft' ? activeTrack.publishedLowBitRateFile.url :  activeTrack.hiBitRateFile.url;

            // console.log('trackUrl', trackUrl);
            // console.log('trackAudio.getAttribute("src")', trackAudio.getAttribute("src"));

            // if (trackAudio.getAttribute("src") !== trackUrl) {
            //     console.log('111111');
            //     trackAudio.setAttribute("src", trackUrl);

            // }

            //  try {
            //     trackAudio.load();
            //     if(isPlaying && !this.state.stopped){
            //         console.log('AAAAA');
            //         trackAudio.play();
            //     } else {
            //         trackAudio.pause();
            //     }
            //  } catch (err) {
            //     console.log('PLAY ERROR', err);
            //  }

            if(isPlaying && !this.state.stopped){
                audioElement.play().catch(e => {
                    console.log('PLAY ERROR', e);
                }); 
            } else {
                audioElement.pause();
            }
        }

        if(prevProps.isPlayerActive != isPlayerActive){
      
        }

        if(prevProps.isPlaying === false && this.state.stopped === true && this.props.isPlaying === true){
            this.setState({
                stopped: false,
            })
        }
        
        if(isPlaying && isAudioSet){
            audioElement.addEventListener('timeupdate', this.handleSeek, false);
            audioElement.addEventListener('timeupdate', this.updateProgress, false);
            // audioElement.addEventListener('timeupdate', this.handleSeek, false);
        }

        audioElement.volume = volume/100
        audioElement.loop = false;


    }

    componentDidMount(){
        const {audioElement} = this;

        // console.log('mounting');
        // audioElement.play();
        // console.log('audio element', audioElement);
    }

    componentWillUnmount(){
        const {audioElement} = this;

        let isAudioSet = (audioElement && audioElement.addEventListener) ? true : false ;

        if(isAudioSet){
            audioElement.removeEventListener('canplaythrough', this.handleCanPlay, false);
            audioElement.removeEventListener('timeupdate', this.handleSeek, false);
            audioElement.removeEventListener('timeupdate', this.updateProgress, false);

            audioElement.removeEventListener('ended', () => {
                audioElement.pause();
            }, false)
        }

    }

    handleCanPlay(event){
        const duration = parseInt(event.target.duration);
        this.setState({
            duration: duration
        })
        this.props.trackIsLoaded();
    }

    handlePlay(){
        // alert('handle')
        this.setState({
            stopped: false,
        })
        const {isTrackLoading} = this.props;
        if(!isTrackLoading){
            this.props.togglePlay(); 
        }
    }


    handlePause(){
        // const {audioElement} = this;
        // console.error('handle pause');
        this.setState({
            stopped: true,
        })
        this.props.pausePlay();
    }

    togglePlay(){
        this.props.togglePlay()
    }

    handleSeek(event){
        let val;

        if (event.target.currentTime > 0) {
            val = parseInt(((100 / event.target.duration) * event.target.currentTime).toFixed(2));
        }

        if(this.props.trackProgress !== val){
            // console.log('new val', this.props.trackProgress, val+1);
            if(val !== null && val !== NaN){
               
                if(this.props.progressChanger === 'waveform'){
       
                } else {
                 
                }

                setTimeout(() => {
                    this.props.setTrackProgress(val, null);

                })

            }
        } 
    }

    onHandleVolume(volume){
        this.props.setVolume(volume);
    }

    updateProgress(event){
        const statusbar = document.getElementById('player-status-bar');
        if (!statusbar) return;
        let val = 0;
        if (event.target.currentTime > 0) {
          val = ((100 / event.target.duration) * event.target.currentTime).toFixed(2);
        }
        statusbar.style.width = val + "%";

        if (event.target.duration <= event.target.currentTime) {
        //   const iterate = this.props.isInRepeatMode ? 0 : 1;
        //   this.handleIteratedTrack(iterate);
        }
    }

    handleProgress(event){
        const {audioElement} = this;

        
        let val = 0;
        const songPercentage = event.clientX / window.innerWidth;
        const duration = audioElement.duration;

        if(typeof duration != 'undefined') audioElement.currentTime = duration * songPercentage;
        
        console.log('progress', songPercentage)
    }

    toggleVolumeSlider(){
        console.log('toggling volume')
        this.setState({
            isVolumeDisplayed: !this.state.isVolumeDisplayed
        })
        
        this.setState({
            isPlayedTracksDisplayed: false 
        })
        
    }

    togglePlayedTracks(){
        // console.log('played tracks');
        this.setState({
            isPlayedTracksDisplayed: !this.state.isPlayedTracksDisplayed,
            isVolumeDisplayed: false,
        })
    }

    closePlayedTracks(){
        this.setState({
            isPlayedTracksDisplayed: false,
           
        })
    }


    buyTrack(currency, trackId, trackData){
        this.props.buyTrack(currency, trackId, trackData);
    }

    handleDQ(currency, trackId){
        this.props.addToDownloadQueue(currency, trackId)
    }

    toggleDetailedView(){
        console.log('toggling')
        this.setState({
            isDetailedView: !this.state.isDetailedView
        })
    }


    render() { 
        const {isPlayerActive, volume, activeTrack, isPlaying, trackProgress, isTrackLoading, modifiedEditors} = this.props;
        const {isVolumeDisplayed, isDetailedView, isPlayedTracksDisplayed, height} = this.state;

        let format = '';

        if(typeof activeTrack != 'undefined' && activeTrack.status === 'draft'){
            format = typeof activeTrack != 'undefined' && activeTrack.hiBitRateFile.url.split('.').pop();
            console.log('format', format);
        } else if (typeof activeTrack != 'undefined' && activeTrack.fileType === 'snippet'){
            format = activeTrack.url.split('.').pop();
        } else if(typeof activeTrack != 'undefined') {
            format = typeof activeTrack != 'undefined' && activeTrack.publishedLowBitRateFile.url.split('.').pop();
            console.log('format', format);
        }

    
        const isMp4 = (format === 'mp4');

        const pHeight = typeof this.playerWrapper != 'undefined' ? this.playerWrapper.clientHeight : 0

        return ( 
            <div className="main-player--outer-wrapper" style={{height: pHeight  + "px"}}>


            <div className="played-tracks--outer-wrapper">
                <PlayedTracks 
                close={this.closePlayedTracks.bind(this)}
                isVisible={isPlayedTracksDisplayed}
                />
            </div>

            {isPlayerActive && <section className="main-player--wrapper" ref={(playerWrapper) => this.playerWrapper = playerWrapper}>
                <div className="player-status" onClick={this.handleProgress.bind(this)}>
                    <div id="player-status-bar" className="player-status-bar">
                        <span className="player-status-bar-dragger"></span>
                    </div>
                </div>

                <div className="main-player--non-status-content">
                    
                 
                    <div className="main-player--non-video-wrapper">
                        <div className={"main-player--inner-wrapper " + format}>
                            
                           
                            <div className="main-player--track-info">
                                <div className="  main-player--now-playing">Now playing:</div>
                                <div className="main-player--subtitle">{activeTrack.editorPrimaryName}</div>
                                <div className="main-player--title main-player--track-name">{activeTrack.name}</div>
                                <div className="main-player--subtitle">{activeTrack.version}</div> 
                                <div className="main-player--title">{activeTrack.artistPrimaryName}</div>
                            </div>

                            <div className="main-player--buttons-wrapper">

                                <div className="main-player--right-wrapper">
                                    <div className="single-track--btn" onClick={this.togglePlayedTracks.bind(this)}>
                                        <FontAwesomeIcon icon={faList} />
                                    </div>

                                    {false && <div className="single-track--btn" onClick={this.toggleDetailedView.bind(this)} >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </div>}

                                    <div className="main-player--toggle-volume-display" onClick={this.toggleVolumeSlider.bind(this)}><FontAwesomeIcon size="1x" icon={faVolumeUp} /> {volume}</div>
                                </div>

                                <div className="main-player--main-buttons">
                                    <button className="main-player--main-button" onClick={this.handlePlay.bind(this)}>
                                        {isTrackLoading ? <Loader type="Oval" color="#a5d0f8" height={20} width={20}/> : 
                                        isPlaying ? <FontAwesomeIcon icon={faPauseCircle} /> : <FontAwesomeIcon icon={faPlayCircle} />}
                                    </button>
                                    

                                    {!activeTrack.isPurchased && <div className="single-track--btn" onClick={() => {this.handleDQ(activeTrack.currency, activeTrack._id)}}><FontAwesomeIcon  icon={activeTrack.isInDownloadQueue ? faCheckCircle : faPlusCircle} /></div>}                    
                                
                                   
                                    <div className="main-player--buy-btn single-track--btn" onClick={() => {this.buyTrack(activeTrack.currency, activeTrack._id, activeTrack)}} ><FontAwesomeIcon  icon={activeTrack.isPurchased ? faArrowCircleDown : faCartArrowDown} /></div>
                                </div>
                            </div>
                            
                 
                            <Volume 
                            volumeVisible={isVolumeDisplayed}
                            onChangeVolume={this.onHandleVolume.bind(this)}
                            volume={volume}
                            />                           

                        </div>

                    {false &&  (isDetailedView || isMp4)&& <div className="main-player--advanced-data">
                        <div className="advanced-data--group advanced-data--genres">
                            <div>Genres:</div>
                            {typeof activeTrack.genres != 'undefined' && activeTrack.genres.length && activeTrack.genres.map(genre => {
                                return (
                                    <div>{genre.name}</div>
                                )
                            })}
                        </div>
                        <div className="advanced-data--group advanced-data--tags">
                            <div>Tags:</div>
                            <div className="advanced-data--tags-listing">
                                <div>{activeTrack.cleanDirty.name}</div>
                                <div>{activeTrack.versionType.name}</div>
                                <div>{activeTrack.introType.name}</div>
                                <div>{activeTrack.outroType.name}</div>
                            </div>
                        </div>
                        <div className="advanced-data--group advanced-data--original-works">
                            <div>Original works:</div>
                            {activeTrack.originalWorks.map(ow => <div>{ow.displayName}</div>)}
                        </div>
                    
                </div>}
                    
                {!isMp4 && <audio id="audio" ref={(audio) => {this.audioElement = audio; }} src={activeTrack.status !== 'draft' ? activeTrack.publishedLowBitRateFile.url :  activeTrack.hiBitRateFile.url} />}
                    </div>
                
                    {isMp4 && 
                    <div className="main-player--video-wrapper">
                        <video id="audio" ref={(audio) => {this.audioElement = audio; }} src={activeTrack.status !== 'draft' ? activeTrack.publishedLowBitRateFile.url :  activeTrack.hiBitRateFile.url} />
                    </div>
                    }
                    
                </div>
            </section> }
        </div>
        );
    }

}

const mapStateToProps = state => ({
    ...state.playerReducer,
})

const mapDispatchToProps = dispatch => ({
    setTrackProgress: (trackProgress, trackId) => dispatch(actions.setTrackProgress(trackProgress, trackId)),    
    togglePlay: () => dispatch(actions.togglePlay()),    
    pausePlay: () => dispatch(actions.pausePlayer()),    
    trackIsLoaded: () => dispatch(actions.trackIsLoaded()),
    setVolume: (volume) => dispatch(actions.setVolume(volume)),
    buyTrack: (currencyId, trackId, trackData) => dispatch(actions.buyTrack(currencyId, [trackId], trackData)),
    addToDownloadQueue: (currencyId, trackId) => dispatch(actions.addToDownloadQueue(currencyId, trackId))
})

export default connect(mapStateToProps,mapDispatchToProps)(PlayerContainer);