import React, { Component } from 'react';
import htmr from 'htmr';

import { connect } from 'react-redux';

import PaypalCheckout from '../components/general/paypalCheckout';

import axios from 'axios';
import urls from '../constants/async';
import { rootApiUrl } from '../constants/async';

import AVLogo from '../images/cc-av.png';
import VVLogo from '../images/cc-vv.png';
import i12logo from '../images/cc-i12.png';

import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faCoins, faTimes, faRedoAlt, faHourglass } from '@fortawesome/free-solid-svg-icons'

const logos = [AVLogo, VVLogo, i12logo];


class PackagesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            groupedPackages: [],
            selectedCurrency: '',
            modalIsOpen: false,
            selectedPackage: {},
        }
    }

    openModal(thePackage) {
        console.log('opening modal');
        this.setState({
            modalIsOpen: true,
            selectedPackage: thePackage
        })

    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }


    componentDidMount() {


        axios({
            withCredentials: true,
            url: rootApiUrl + urls.getPackages,
        }).then(response => {
            if (response.data && response.data.Packages) {
                let allPackages = [];

                response.data.Packages.map((thePackage, index) => {
                    const currencyId = thePackage.packageItems[0].currency;
                    if (allPackages.indexOf(currencyId) === -1) {
                        allPackages.push(currencyId);
                    }
                })



                console.log('the packages', allPackages);

                const groupedPackages = {};
                allPackages.map((thePackage, index) => {
                    groupedPackages[thePackage] = {};
                    groupedPackages[thePackage]['logo'] = logos[index];
                    groupedPackages[thePackage]['list'] = response.data.Packages.filter(lePackage => lePackage.packageItems[0].currency === thePackage);

                })

                this.setState({
                    isLoading: false,
                    groupedPackages: groupedPackages
                })

            }
        })

    }

    createOrder() {
        console.log('creating order');
    }


    render() {
        const { groupedPackages, modalIsOpen, selectedPackage, isLoading } = this.state;
        const { selectedCurrency } = this.props;

        return (
            <section className="app--center-wrapper packages--page">
                {typeof selectedCurrency != 'undefined' && <div className="packages--selected-currency--logo">
                    <img src={selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo} />
                </div>}
                <div className="packages-page--title">The packages</div>
                <div className="packages-page--description">Listed below are the current credit packages and subscriptions available. Credit packages have expiration dates and subscription packages are our recurring payment options. Please check the details of the credit package or recurring subscription of your choice before making a payment. Check back to this section of our site often for sales, new promotional membership prices and more!</div>
                {Object.keys(groupedPackages).map(currency => {
                    if (currency === selectedCurrency._id) return <div key={currency} className="packages--list-wrapper">
                        <div className="packages--currency-logo">
                            {currency === ''}
                        </div>
                        <div className="packages--list">
                            {groupedPackages[currency].list.map((thePackage, index) => {
                                const isRecurring = (thePackage.basis == "recurring")

                                return <div key={index} className="single-package--wrapper">
                                    <div className="single-package--name">
                                        <FontAwesomeIcon className="single-pack--icon" icon={isRecurring ? faRedoAlt : faCoins} />
                                        {thePackage.name}
                                    </div>
                                    <div className="single-package--expires">
                                        <FontAwesomeIcon icon={faHourglass} className="single-package--hourglass" />
                                        {isRecurring ? "Recurring:" : "Expires:"} {thePackage.creditExpirationTerm} days
                                    </div>
                                    <div className="single-package--cost">Cost: ${thePackage.price.toFixed(2)}</div>
                                    <div className="single-package--buy">
                                        <button onClick={() => { this.openModal(thePackage) }} >Details </button>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })

                }

                {isLoading && <Loader />}
                <Modal
                    className="package-modal"
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                >
                    <div className="package--inner-wrapper">
                        {typeof selectedCurrency != 'undefined' && <div className="packages--selected-currency--logo modal-package-logo">
                            <img src={selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo} />
                        </div>}
                        <div className="modal--package-info">

                            <div><FontAwesomeIcon className="single-pack--icon" icon={selectedPackage.basis === "recurring" ? faRedoAlt : faCoins} />{selectedPackage.name}</div>
                            <div>{selectedPackage.description}</div>
                            <div>Expires: {selectedPackage.creditExpirationTerm} days</div>
                            <div>Cost: ${selectedPackage.price}</div>
                            <div>{selectedPackage && selectedPackage.termsAndConditions && htmr(selectedPackage.termsAndConditions)}</div>
                        </div>
                        <div className="selected--paypal-wrapper">
                            <PaypalCheckout packageId={selectedPackage._id}
                                closeModal={this.closeModal.bind(this)} />
                        </div>
                        <div className="package-modal--close">
                            <button onClick={this.closeModal.bind(this)}>close</button>
                        </div>
                    </div>

                </Modal>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    ...state.currencyReducer
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(PackagesContainer);
