import React, { Component } from 'react';
import {connect} from 'react-redux';
import MultiSelect from "@khanacademy/react-multi-select";

import Track from './track';

import ReactPaginate from 'react-paginate';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMusic, faCoins, faTimes, redoAlt, faInfinity} from '@fortawesome/free-solid-svg-icons'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import AVLogo from '../../images/cc-av.png';
import VVLogo from '../../images/cc-vv.png';
import i12logo from '../../images/cc-i12.png';

import AnimateHeight from 'react-animate-height';

import {Link} from 'react-router-dom';

import SmallAdsContainer from '../../containers/top-ads/SmallAdsContainer';
import { isThisHour } from 'date-fns/esm';


class Tracklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
        }
    }

    setSort(event){
        const value = event.target.value;
        // console.log('sorting value', value);
        this.props.setSort(value, -1)
    }


    setSource(event){
        const value = event.target.value;
        this.props.setSource(value);
    }

    componentDidUpdate(){
        // console.log('updating tracklist component');
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log('should trackList update', nextProps);
        if(this.props.searchQuery !== nextProps.searchQuery){
            return false;
        }
        if(this.props.pageSize !== nextProps.pageSize){
            return false;
        }
        
        return true;
      
    }

    keyPress(e){
        if(e.keyCode === 13){
           this.props.onSubmitSearch();
        }
    }

    handlePageChange(change){
        // console.log('page change', change);

        this.props.getTracks({
            page: change.selected + 1
        })
    } 

    componentDidMount(){
        this.props.getTracks({
            page: 1
        });
    }

    handleClick(e){
        const isAnyCrateSelected = Object.keys(this.props.selectedCrate).length > 0;
        // console.log('click', e.target.tagName);
        const eventTargetType = e.target.tagName;

        if(isAnyCrateSelected && (eventTargetType === "INPUT" || eventTargetType === "SPAN")) {
            alert("Inputs are disabled when you have a create selected")
        }

    }

    render() { 
        const {tracks, isLoading, areFiltersLoading, totalPages, sortBy, searchQuery, activeTrack, source, sourceEnabled, selectedCurrency, hasAdvancedSearch, tags, genres, keys, editors, selectedKeys, selectedGenres, selectedTags, advancedToggled, selectedEditors, minBpm, maxBpm, minYear, maxYear, setStartDate, startDate, endDate, setEndDate, hidePurchases, toggleHidePurchases, selectedCrate, saveCrate, sortByEnabled, pageSize, page, toggleDirty, toggleClean, isDirty, isClean, editorsDisabled, genresDisabled, isCriteriaUpdated} = this.props;

        let keyList, genreList, tagList, editorList = [];
        const isAnyCrateSelected = Object.keys(selectedCrate).length;

        if(!areFiltersLoading){

            if(keys && keys.length) keyList = keys.map(key => ({
                value: key._id,
                label: key.camelotKey + "/" + key.musicKey 
            }))
    
            if(genres && genres.length) genreList = genres.map(genre => ({
                value: genre._id,
                label: genre.name,
            }))
    
            if(tags && tags.length) tagList = tags.filter(tag => tag.name !== 'Clean' && tag.name !== 'Dirty').map(tag => {

                
                    return ({
                        value: tag._id,
                        label: tag.name,
                    })
             
            })

            // console.log(tagList);

            if(editors && editors.length) editorList = editors.map(editor => ({
                value: editor._id,
                label: editor.stageName,
            }))
        }

        return (
        typeof this.props.selectedCurrency != 'undefined' ? <section className="tracklist--main-wrapper">
            <section className="tracks--search-wrapper"  style={{backgroundColor: this.props.selectedCurrency.color}} >
                {selectedCurrency && <div className="search--selected-currency"><div className="search--curency-name">{
                    <img src={ selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo } />
                }</div> </div>}

                <div className="search-group">         
                    <input onKeyDown={this.keyPress.bind(this)} type="text" placeholder="Search artists, titles, originals..."  onChange={(event) => {this.props.onUpdateSearch(event.target.value)}} />
                    {!advancedToggled &&  <button onClick={this.props.onSubmitSearch} className="advanced-search--btn">Search</button>}
                    {hasAdvancedSearch && <button className="tracklist--advanced-btn advanced-search--btn" onClick={this.props.toggleAdvanced}>{advancedToggled ? "Close" : "Advanced"}</button>}
                </div>

                {typeof selectedCurrency != 'undefined' && <Link to="/packages"><div className="tracklist--currency-status"><FontAwesomeIcon className="single-pack--icon" icon={faCoins} />  <span className="credits-nr">{selectedCurrency.creditBalance === -1 ? <FontAwesomeIcon className="single-pack--icon" icon={faInfinity} /> : selectedCurrency.creditBalance}</span></div></Link>}
        </section>

            <div className="tracks--header"  style={{backgroundColor: this.props.selectedCurrency.color}} >

                {(!areFiltersLoading ) && (
                
                <AnimateHeight
                duration={300}
                height={advancedToggled ? "auto" : 0}
                >
                {<div  style={{backgroundColor: this.props.selectedCurrency.color}}  className={(advancedToggled && !areFiltersLoading ) ? "tracks--advanced-search-wrapper " : "tracks--advanced-search-wrapper is-hidden"} onClick={this.handleClick.bind(this)}>
                    
                    <div className="advanced-search--multi-line">

                        <div className="multiline-group">
                            <label>Select keys:</label>
                            {typeof keyList != 'undefined' && <MultiSelect 
                            disabled={isAnyCrateSelected}
                            options={keyList}
                            selected={selectedKeys}
                            selectAllLabel={'Select all keys'}
                            onSelectedChanged={selected => this.props.setKeys(selected)}
                            overrideStrings={{
                                selectSomeItems: "select keys"
                            }}
                            />}
                            {typeof keyList != 'undefined' && keyList.filter(key => selectedKeys.indexOf(key.value) !== -1 ).map((key, index) => <div key={index} className="single-selected-element" onClick={() => this.props.removeSelectedKeys(key.value)}>{key.label} <span><FontAwesomeIcon icon={faTimes} /></span></div>)}
                        </div>


                        <div className="multiline-group">
                        <label>Select tags:</label>
                        {typeof tagList != 'undefined' && <MultiSelect 
                        disabled={isAnyCrateSelected}
                        options={tagList}
                        selected={selectedTags}
                        selectAllLabel={'Select all tags'}
                        onSelectedChanged={selected => this.props.setTags(selected)}
                        overrideStrings={{
                            selectSomeItems: "select tags"
                        }}
                        />}
                        {typeof tagList != 'undefined' && tagList.filter(tag => selectedTags.indexOf(tag.value) !== -1 ).map((tag,index) => <div key={index} className="single-selected-element" onClick={() => this.props.removeSelectedTags(tag.value)}>{tag.label} <span><FontAwesomeIcon icon={faTimes} /></span></div>)}
                        </div>
                        

                        <div className="multiline-group">
                            <label>Select genres:</label>
                            {typeof genreList != 'undefined' &&  <MultiSelect 
                            disabled={isAnyCrateSelected || genresDisabled}
                            options={genreList}
                            selected={selectedGenres}
                            selectAllLabel={'Select all genres'}
                            onSelectedChanged={selected => this.props.setGenres(selected)}
                            overrideStrings={{
                                selectSomeItems: "select genres"
                            }}
                            />}
                           {typeof genreList != 'undefined' && genreList.filter(genre => selectedGenres.indexOf(genre.value) !== -1 ).map((genre,index) => <div key={index} className="single-selected-element" onClick={() => this.props.removeSelectedGenres(genre.value)}>{genre.label} <span><FontAwesomeIcon icon={faTimes} /></span></div>)}
                        </div>

                        <div className="multiline-group">
                            <label htmlFor="">Select editors:</label>
                            {typeof editorList != 'undefined' &&  <MultiSelect
                            disabled={isAnyCrateSelected || editorsDisabled}
                            options={editorList}
                            selected={selectedEditors}
                            selectAllLabel={'Select all editors'}
                            onSelectedChanged={selected => this.props.setEditors(selected)}
                            overrideStrings={{
                                selectSomeItems: "select editors"
                            }}
                            />}
                             {typeof editorList != 'undefined' && editorList.filter(editor => selectedEditors.indexOf(editor.value) !== -1 ).map((editor,index) => <div key={index} className="single-selected-element" onClick={() => this.props.removeSelectedEditors(editor.value)}>{editor.label} <span><FontAwesomeIcon icon={faTimes} /></span></div>)}
                        </div>
                    </div>
                    
                    <div className="advanced-search--nr-line">
                        <div className="advanced-search--nr-group advanced-search--bpm-wrapper">
                            <div className="advanced-search--bpm-line">
                                <label>BPM FROM</label> <input type="number" 
                                disabled={isAnyCrateSelected}                                
                                onChange={this.props.setMinBpm} 
                              
                                value={minBpm || 0} />
                            </div>
                            <div className="advanced-search--bpm-line">
                                <label htmlFor="">BPM TO</label> <input type="number" 
                                disabled={isAnyCrateSelected}                        
                                onChange={this.props.setMaxBpm}
                                value={maxBpm || 250}
                             
                                />
                            </div>
                            {((typeof minBpm != 'undefined' && minBpm > 0) || (maxBpm != null)) && <div  className="single-selected-element" onClick={() => this.props.resetBpmFilter()}>{minBpm} - {maxBpm} <span><FontAwesomeIcon icon={faTimes} /></span></div>}
                        </div>

                        <div className="advanced-search--nr-group advanced-search--year-wrapper">
                            <div className="advanced-search--year-line">
                                <label htmlFor="">YEAR FROM</label> 
                                <input type="number" value={minYear || 1950}
                                disabled={isAnyCrateSelected}                        
                                onChange={this.props.setMinYear} 
                               
                                />
                            </div>
                            <div className="advanced-search--year-line">
                                <label>YEAR TO</label> <input type="number" 
                                disabled={isAnyCrateSelected}                        
                                onChange={this.props.setMaxYear}  
                                value={maxYear || new Date().getFullYear()}
                             
                                />
                            </div>

                            { minYear != null && minYear != null && <div  className="single-selected-element" onClick={() => this.props.resetYearFilter()}>{minYear} - {maxYear} <span><FontAwesomeIcon icon={faTimes} /></span></div>}
                        </div>

                        <div className="advanced-search--nr-group advanced-search--date-wrapper">
                            <div className="advanced-search--start-date">
                            <div className="advanced-search--row">
                                <div className="title label">RELEASE ON OR AFTER</div>
                                <DatePicker 
                                disabled={isAnyCrateSelected}                                
                                selected={endDate} 
                                onChange={setEndDate} />

                                {typeof endDate != 'undefined' && endDate != null && <div  className="single-selected-element" onClick={() => this.props.resetEndDate()}>Reset End Date <span><FontAwesomeIcon icon={faTimes} /></span></div>}

                            </div>
                            {false && <div className="advanced-search--row" style={{color: 'red'}}>
                               <div className="title label"> DATE TO</div>
                                <DatePicker disabled selected={startDate} onChange={setStartDate} />
                            </div>}
                       
                            
                            </div>
                        </div>

                        <div className="advanced-search--nr-group advanced-search--release-date">
                        </div>

                        <div className="advanced-search--btn-row">
                            {!isAnyCrateSelected && <div className="advanced-search--add-new-crate">
                                <button onClick={saveCrate} className="advanced-search--btn">Save as crate</button>
                            </div>}
                            <button onClick={this.props.onSubmitSearch} className="advanced-search--btn">{isCriteriaUpdated ? "Update" : "Search"}</button>
                        </div>
                    </div>

                    <div className="advanced-search--third-line">
                        
                    </div>

              
                </div>}
                </AnimateHeight>
                )}

            </div>


            <div className="tracks--simple-search-wrapper">

                {sourceEnabled && <div className="tracks--source-wrapper">
                    <div className="label" >Now viewing:</div>
                    <div className="tracks--source-list" >
                        {[{name:"All tracks", value: "alltracks"}, {name:"Download Queue", value:"download-queue"}, {value: "hotbox", name: "Editor Hotbox"}, {value: "my-library", name: "My Library"}, {value: "site-picks", name: "Site Picks"}, {value: "vault-classics", name: "Vault Classics"}].map((element, index) => {
                           
                            let isVaultClassics = false;

                            if(selectedEditors.length && selectedEditors.indexOf("59e26b60b17bac0013db40d6") != -1  ){
                                isVaultClassics = true;
                            } else {
                                isVaultClassics = false;
                            }

                            let isSelected = (element.value === source) || (source === "" && element.value === "alltracks") || (element.value === 'vault-classics' && isVaultClassics);

                  

                            // console.log('source', source, isSelected, selectedEditors)
                            return <div key={index} onClick={() => {this.setSource({target:{value:element.value}})}} className={isSelected ? "element is-selected" :"element"} >{element.name}</div>
                         })}
                    </div>

                </div>}
            
                {sourceEnabled && <div className="tracks--select-wrapper tracks--source-dropdown">   
                    <label>Now viewing</label>
                    <select defaultValue={source} onChange={this.setSource.bind(this)}>
                        <option value={"alltracks"} >All Tracks</option>
                        <option value={'download-queue'} >Download Queue</option>
                        <option value={"hotbox"}>Editor Hotbox</option>
                        <option value={'my-library'} >My Library</option>
                        <option value={"site-picks"}>Site Picks</option>
                        <option value={'vault-classics'}>Vault Classics</option>
                    </select>
                </div>}

                {sortByEnabled &&<div className="tracks--select-wrapper">
                    <label>Sorted by</label>

                     <select value={sortBy} onChange={this.setSort.bind(this)}>
                        <option value={"publishDate"} >New Releases</option>
                        <option value={'allTimeDownloadCount'} >Top Movers - All-Time</option>
                        <option value={'last90DaysDownloadCount'} >Top movers - Last 90 Days</option>
                        <option value={'last30DaysDownloadCount'} >Top movers - Last 30 Days</option>
                        <option value={'last7DaysDownloadCount'} >Top movers - Last 7 Days</option>
                        <option value={'last2DaysDownloadCount'} >Top movers - Last 2 Days</option>
                        <option disabled={source !== 'my-library'} value={"purchasedAt"} >Date purchased</option>

                    </select>
                </div>}

                <div className="tracks--select-wrapper">
                    <label>Page size</label>
                    <select defaultValue={pageSize} onChange={this.props.changePageSize.bind(this)}>
                        <option>10</option>
                        <option>15</option>
                        <option>25</option>
                        <option>50</option>

                    </select>
                </div>

                {source != 'download-queue' && <div className="tracks--is-purchased tracks--checkbox">
                    <input
                    disabled={isAnyCrateSelected}                            
                    type="checkbox" 
                   checked={hidePurchases}
                    onChange={() => {toggleHidePurchases()}} 
                    />
                    Hide purchases
                </div>}

                <div className="tracks--checkbox" >
                    <input
                    type="checkbox"
                    checked={isDirty != false}
                    onChange={toggleDirty}
                    />
                    Dirty
                </div>

                <div className="tracks--checkbox" >
                    <input
                    type="checkbox"
                    checked={isClean != false}
                    onChange={toggleClean}
                    />
                    Clean
                </div>

                {selectedCrate && Object.keys(selectedCrate).length > 0 && <div className="advanced-search--selected-crate">
                    <div className="text">Selected crate: {selectedCrate.name}</div>
                    <div className="remove-selected-crate-btn" onClick={this.props.deselectCrate}><FontAwesomeIcon icon={faTimes} /></div>
                </div> }

                {source === 'download-queue' && !isLoading && tracks.length > 0 && <div className="tracks--buy-all-wrapper"><button className="buy-all-btn" onClick={this.props.attemptBuyAllDQ}>Buy all </button>Cost: <FontAwesomeIcon className="single-pack--icon" icon={faCoins} /> {tracks.length} </div>}

            </div>

            {this.props.smallAdsEnabled &&  <SmallAdsContainer /> }

            <section className="tracklist--list">
                {this.props.isLoading && <div className="tracklist--is-loading">
                    <Loader />
                </div>}
                
                {tracks && tracks.length > 0 ? tracks.map((track, index) => {
                    return <Track 
                    nr={((page - 1) * pageSize) + index + 1}
                    key={index}
                    trackData={{...track}} 
                    activeTrack={this.props.activeTrack}
                    isLoading={this.props.isLoading}
                    areTracksLoading={this.props.areTracksLoading}
                    testImage={this.props.waveformImageUrl}
                    source={source}
                    />
                }) : <div>Nothing found</div> }

                {this.props.isPurchasing && <div className="tracklist--overlay-loading"><Loader /></div>}
            </section>

            <ReactPaginate 
            pageCount={this.props.totalPages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            initialPage={0}
            forcePage={this.props.forcePage}
            breakClassName={'break-me'}
            onPageChange={this.handlePageChange.bind(this)}
            containerClassName={"tracklist--pagination"}
            
            />

        </section> : <section></section>);
    }
}

Tracklist.defaultProps = {
    smallAdsEnabled: true,
    hasAdvancedSearch: true,
    areFiltersLoading: true,
}

export default Tracklist;