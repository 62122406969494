import React, { Component } from 'react';
import {connect} from 'react-redux';

import * as actions from '../../actions/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';

import { rootApiUrl } from '../../constants/async';
import urls from '../../constants/async';

class SidebarCrates extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShown: true,
        }
    }

    componentDidMount(){
        const {selectedCurrency} = this.props;


        if(typeof selectedCurrency === 'undefined'){
            return false;
        }
        
        this.props.getAllCrates(selectedCurrency._id); 
    }

    

    setSelectedCrate(crateId){
        const {selectedCurrency} = this.props;

        if(typeof selectedCurrency === 'undefined'){
            return false;
        }

        this.props.setSelectedCrate(selectedCurrency._id, crateId);
    }

    deleteCrate(crateId){
        const {selectedCurrency} = this.props;

        this.props.deselectCrate();

        Axios({
            url: rootApiUrl + urls.crates.removeSingleCrate(selectedCurrency._id, crateId),
            withCredentials: true,
        }).then(response => {
            this.props.getAllCrates(selectedCurrency._id);
        })

    }

    toggleShow(){
        this.setState({
            isShown: !this.state.isShown,
        })
    }

    render() {
        const {crates, selectedCrate} = this.props;
        const {isShown} = this.state;

        return ( 
        typeof this.props.selectedCurrency != 'undefined' ? <section className="sidebar-crates--main-wrapper">
            <div  style={{backgroundColor: this.props.selectedCurrency.color}}  className="sidebar-crates--title sidebar--title">
            Crates
            <span onClick={this.toggleShow.bind(this)}>toggle</span>                                
            </div>
        {typeof crates != 'undefined' && isShown && crates.map((crate, index) => {
            const isSelected = typeof selectedCrate != 'undefined' && (selectedCrate._id === crate._id);            
            return (
            <div className="sidebar--single-crate-wrapper" key={index}>
                <div className={isSelected ? "sidebar--single-crate is-selected" : "sidebar--single-crate "} key={index} onClick={() => {this.setSelectedCrate(crate._id)}}>{crate.name}</div>
                {isSelected && <div className="remove-selected-crate-btn" onClick={this.props.deselectCrate}><FontAwesomeIcon icon={faTimes} /></div>}
                <div className="sidebar--remove-crate"><FontAwesomeIcon icon={faTrashAlt} onClick={() => { this.deleteCrate(crate._id) }}/></div>
             </div>) 
             
            })}
        </section> : <section></section>);
    }
}

const mapStateToProps = state => ({
    ...state.currencyReducer, ...state.globalReducer,
});

const mapDispatchToProps = dispatch => ({
    getAllCrates: (currencyId) => dispatch(actions.getCrates(currencyId)),
    setSelectedCrate: (currencyId, crateId) => dispatch(actions.setSelectedCrate(currencyId, crateId)),
    deselectCrate: () => dispatch(actions.deselectCrate())
})
 
export default connect(mapStateToProps, mapDispatchToProps)(SidebarCrates);

