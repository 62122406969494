import React, { Component } from 'react';

import axios from 'axios';

import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import {connect} from 'react-redux';

import {Fade} from 'react-slideshow-image';
import Loader from 'react-loader-spinner';

import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import * as actions from '../../actions/index';


class SecondAdsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            ads: [],
            adGroups: [],
         }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    componentDidMount(){
        this.getAds();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedCurrency && prevProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== prevProps.selectedCurrency._id){
            console.log('currency has changed');
            this.getAds();
        }
    }

    getAds(){
        const {selectedCurrency} = this.props;

        if(typeof selectedCurrency == 'undefined'){
            return;
        }

        const params = {
            getAll: true,
            isValid: true,
            currency: selectedCurrency._id,
            status: "approved",
        }

        this.setState({
            isLoading: true,
        })
    
        axios({
            withCredentials: true,
            url: rootApiUrl + urls.getAllAds(),
            params: params
        }).then(response => {
            if(response.data && response.data.EditorAds){
            
                this.setState({
                    isLoading: false,
                    ads: response.data.EditorAds.results 
                })

                let adGroups = [];
                let halfSizeAds = [];

                const ads = response.data.EditorAds.results
        
                if(ads && ads.length > 0)
                { 
                    ads.map((ad,index) => 
                        {
                            ad['gindex'] = index;
                            const isHalfHeight = ad.adType == 'Track' || ad.adType == 'TrackPlaceholder';
                            if(isHalfHeight){
                                adGroups.push([ad]);
                            } else {
                                // adGroups.push([ad])
                            }
                        }
                    )
                }

        
                this.setState({
                    adGroups: adGroups,
                })

            }
    
        })
    }

    clickAd(index){
        const {ads} = this.state;
        const theAd = ads[index];

        this.props.useAdData(theAd);
    }

    render() { 
        const {ads, adGroups, isLoading} = this.state;

        return ( 
        isLoading ? <Loader /> : <section className="small-ads--container">

            {typeof adGroups != 'undefined' && adGroups && adGroups.length > 0 && (
            <Carousel
            autoPlay={true}
            showThumbs={false}
            interval={3000}
            infiniteLoop={true}
            >           
            { adGroups.map((adGroup, index) => {
                return <div key={index} className={adGroup.length == 2 ? 'ad-group double' : "ad-group single"}   >
                
                    {adGroup.length && adGroup.map((ad, index) => {
                        return <div key={index} onClick={() => {this.clickAd(ad.gindex)}}><img src={ad.adMainImageFullUrl} /></div>
                    })}
                </div>

                })}

            </Carousel>)}
        </section>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    useAdData: (adData) => dispatch(actions.useAdData(adData))
})

 
export default connect((state) => ({...state.currencyReducer}), mapDispatchToProps)(SecondAdsContainer);