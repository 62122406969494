export default (state = {}, action) => {
    switch (action.type) {
      case 'CHECK_ACCOUNT_BEGIN':
        return {
          ...state,
          isLoading: true,
        }
      case 'LOGIN_REQUEST':
        return {
          logginIn: true,
          loginRequested: true,
          isLoading: true,
        }
      case 'LOGIN_ATTEMPT_SUCCESS':
        return {
          ...state,
          logginIn: false,
          loggedIn: true,
          loginResponse: action.success,
          userData: action.payload,
          isLoading: false,
        }
      case 'LOGIN_ATTEMPT_FAILURE':
        return {
          ...state,
          logginIn: false,
          loggedIn: false,
          isLoading: false,
          loginResponse: action.payload
        }
      case 'LOGOUT_ATTEMPT':
        return {
          logginIn: false,
          loggedIn: false,
          logginOut: true,
        }
      case 'LOGOUT_SUCCESS':
        return {
          logginIn: false,
          loggedIn: false,
          logginOut: true,
          loginRequested: false,
          isLoading: false,
        }
     default:
        return state
    }
   }