import React, {useState} from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'


export default function createReleaseModal({isOpen, closeModal, createRelease, newReleaseName, changeReleaseName, releaseNameChecked, releaseNameValidity}){
    return (
    <Modal 
        className="create-release-modal"
        isOpen={isOpen}
    >
        <div className="create-release-modal--inner-wrapper">
            <h3>Create new release</h3>
            <p>Type a new for your new release, check if it's available and then submit</p>
            <input type={"text"} onChange={changeReleaseName} value={newReleaseName} />
            <button onClick={createRelease} className="uploader-btn create-release-btn" >Submit</button>
            {releaseNameChecked && <div>
                {releaseNameValidity ? "The name is free to use" : "The release name is already used"}
            </div>}

            <div className="edit-track-modal--close" onClick={closeModal}>
                <FontAwesomeIcon size="lg" icon={faTimesCircle} />
            </div>
        </div>

        
    </Modal>)
}