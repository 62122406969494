import React, { Component } from 'react';

import TracksContainer from './tracksContainer';
import SidebarContainer from './sidebarContainer';
import TopAdsContainer from './top-ads/TopAdsContainer';
import SecondAdsContainer from './top-ads/SecondAdsContainer';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        
        return ( 
        <section className="home--main-wrapper app--center-wrapper">
            <div className="home--ads-wrapper">
                <TopAdsContainer />
                <SecondAdsContainer />
            </div>
            <section className="main-content">
                <SidebarContainer/>
                <TracksContainer />
            </section>
        </section> );
    }
}
 
export default Home;