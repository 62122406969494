export default (state = {}, action) => {
    switch (action.type) {
      case 'UPDATE_TRACK_SEARCH_QUERY':
        return {
          ...state,
          searchQuery: action.payload
        }
     default:
        return state
    }
   }