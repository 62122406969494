import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-modal';


import InputGroup from './editTrackInputGroup';
import MultipleOption from './editTrackMultipleOption';
import SingleOption from './editTrackSingleOption';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlayCircle, faTrashAlt, faTimesCircle, faSave, faPlus} from '@fortawesome/free-solid-svg-icons'

import {connect} from 'react-redux';

import Dropzone from '../general/trackDropzone';

function EditTrackModal({startSampleUpload, isSampleUploading, rejectSampleFileType, closeModal, modalIsOpen, trackData, changeTrackValue, submitModifications, globals, functions, playSnippet, deleteSnippet, removeOriginalWork, addOriginalWork, toggleIsOriginal, getOriginalWorksSuggestions, originalWorksSuggestions, showOriginalWorksSuggestions, searchTracksCurrentSession, searchResultsFromCurrentSession, copyFromDataFromSomeOtherTrack, showResultsFromCurrentState, closeResultsFromCurrentState, currencyDetails, selectedCurrency}){
    
    const [workTitle, setWorkTitle] = useState(''); 
    const [workVersion, setWorkVersion] = useState(''); 
    const [workArtists, setWorkArtists] = useState(''); 
    const [workFeaturedArtists, setWorkFeaturedArtists] = useState(''); 
    const [error, setError] = useState('');
    const [lastOriginalWorkFieldUsed, setLastOriginalWorkFieldUsed] = useState('workField');
    const [count, setCount] = useState(0);

    const Tag1s = globals.tags ? globals.tags.filter(tag => tag.tagField === 'Tag 1') : [];
    const Tag2s = globals.tags ? globals.tags.filter(tag => tag.tagField === 'Tag 2') : [];
    const Tag3s = globals.tags ? globals.tags.filter(tag => tag.tagField === 'Tag 3') : [];
    const Tag4s = globals.tags ? globals.tags.filter(tag => tag.tagField === 'Tag 4') : [];
    // const Tag3s = globals.Tags.filter(tag => tag.tagField === 'Tag 3');
    // const Tag4s = globals.Tags.filter(tag => tag.tagField === 'Tag 4');

    
    const resetInputs = function(){
        setWorkTitle('');
        setWorkVersion('');
        setWorkArtists('');
        setWorkFeaturedArtists('');
    }

    const submitWorkTitle = function(value){
        setWorkTitle(value);
    }

    const submitWorkVersion = function(value){
        setWorkVersion(value);
    }
    
    const submitWorkArtists = function(value){
        setWorkArtists(value);
    }
    
    const submitWorkFeaturedArtists = function(value){
        setWorkFeaturedArtists(value);
    }

    const addSuggestedOriginalWork = function(originalWork){
        //add suggested original work
        // console.log('adding suggested original work', originalWork)

        addOriginalWork(originalWork);
        resetInputs();
        setCount(0);
    }

    const submitOriginalWork = function(){

        setError('');

        if(workTitle.length && workVersion.length && workArtists.length && workFeaturedArtists.length){
            addOriginalWork({
                name: workTitle,
                version: workVersion,
                artists: workArtists,
                artistsDisplayName: workArtists,
                artistsFeatured: workFeaturedArtists,
                artistsFeaturedDisplayName: workFeaturedArtists,
            });
            resetInputs();
            
        } else {
            setError('please complete all the fields');
        }
    }

    const useOutsideClicker = function(ref){
        useEffect(
            () => {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        closeResultsFromCurrentState();
                    }
                }

                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
        
            }
        )
    }

    useEffect(() => {
        if(count > 0){
            const searchParams = {
                name: workTitle,
                version: workVersion,
                artists: workArtists,
                artistsFeatured: workFeaturedArtists,
            };


            getOriginalWorksSuggestions(searchParams);
        }
        setCount(count+1);
    }, [workTitle, workVersion, workArtists, workFeaturedArtists]);
    

    const wrapperRef = useRef(null);
    useOutsideClicker(wrapperRef);

    return (
        <Modal className="edit-track-modal" isOpen={modalIsOpen} >
            <div className="edit-track-modal--inner-wrapper">
                <div className="edit-track-modal--header">
                    <h4>Edit track data for:</h4>
                    <h3 className="edit-track-modal--title">
                        {trackData.originalFileName}
                    </h3>
                </div>

                <div className="edit-track-modal--form-wrapper">
                    <div className="edit-track-modal--form-subgroup">
                        <div className="edit-track-modal--subgroup-title">Copy from Publish Tracks & ZIPs</div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup type="text" onChange={value => searchTracksCurrentSession(value)} />
                        </div>
                        {showResultsFromCurrentState && <div className="current-session-search-results" ref={wrapperRef}>
                            {searchResultsFromCurrentSession.map((result,index) => {
                                return (<div className="single-search-result-current-session" onClick={() => copyFromDataFromSomeOtherTrack(result)}>
                                    {result.name}
                                </div>)
                            })}
                        </div>}
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className="edit-track-modal--subgroup-title">Basic information</div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup label="Track Name" placeholder="Track name placeholder" value={trackData['name']} onChange={value => changeTrackValue('name', value) } />
                        </div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup label="Track version" value={trackData['version']} onChange={value => changeTrackValue('version', value) } />
                        </div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup label="Track Artist" value={trackData['artistText']} onChange={value => changeTrackValue('artistText', value) } />
                        </div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup label="Track Featured Artists" value={trackData['artistsFeaturedText']} onChange={value => changeTrackValue('artistsFeaturedText', value) } />
                        </div>
                        <div className="edit-track-modal--input-row thrids">
                            <InputGroup label="BPM Start" value={trackData['startBpm']} onChange={value => changeTrackValue('startBpm', value) } />
                            <InputGroup label="BPM End" value={trackData['endBpm']} onChange={value => changeTrackValue('endBpm', value) } />
                            <InputGroup label="Release Year" value={trackData['releaseYear']} onChange={value => changeTrackValue('releaseYear', value) } />
                        </div>
                        <div className="edit-track-modal--input-row">
                            <InputGroup label="Track Description" type="long-text" value={trackData['description']} onChange={value => changeTrackValue('description', value) } />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className="edit-track-modal--subgroup-title">Custom Snippet</div>
                        <p>We will automatically generate a low-quality preview snippet for you at 64kbps, 3:30 in length starting from the beginning of the track. If you would like to upload a custom snippet, drop it here.</p>
                        {
                            trackData.customDraftSnippetFile && trackData.customDraftSnippetFile !== 'remove' && <div>
                                <div>Snippet preview: {trackData.customDraftSnippetFile.originalname}</div>
                                <div className="editor-dash--buttons">
                                    <FontAwesomeIcon icon={faPlayCircle} onClick={() => {playSnippet(trackData.customDraftSnippetFile)}} />
                                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => {deleteSnippet()}} />
                                </div>
                                {trackData.customDraftSnippetFileStates === 'processed' && <div>Loading</div>}
                            </div>
                        }
                        <Dropzone
                            startUpload={startSampleUpload}
                            isUploading={isSampleUploading}
                            rejectFileType={rejectSampleFileType}
                            selectedCurrency={selectedCurrency}
                        />
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.genres && trackData.genres.length > 2 ? "valid" : "invalid")} >Select genres (up to 3)</div>
                        <div className="edit-track-modal--input-row">
                           <MultipleOption selectedOptions={trackData.genres} options={globals.genres} chooseOption={functions.setGenres} />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.versionType  ? "valid" : "invalid")}>Select Version</div>
                        <div className="edit-track-modal--input-row">
                           <SingleOption selectedOption={trackData.versionType} options={Tag2s} chooseOption={functions.setVersion} />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.introType  ? "valid" : "invalid")}>Select Intro</div>
                        <div className="edit-track-modal--input-row">
                           <SingleOption selectedOption={trackData.introType} options={Tag3s} chooseOption={functions.setIntro} />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.outroType  ? "valid" : "invalid")}>Select Outro</div>
                        <div className="edit-track-modal--input-row">
                           <SingleOption selectedOption={trackData.outroType} options={Tag4s} chooseOption={functions.setOutro} />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.cleanDirty  ? "valid" : "invalid")}>Select Explicit Rating</div>
                        <div className="edit-track-modal--input-row">
                           <SingleOption selectedOption={trackData.cleanDirty} options={Tag1s} chooseOption={functions.setExplicitRating} />
                        </div>
                    </div>
                    <div className="edit-track-modal--form-subgroup">
                        <div className={"edit-track-modal--subgroup-title " + (trackData.isOriginal || (trackData.originalWorks && trackData.originalWorks.length)  ? "valid" : "invalid")}>Original works</div>
                          
                        {currencyDetails.uploadsCanBeOriginalWorks && <div className="original-works--checkmark">
                            <input type="checkbox" className="edit-track-modal--checkbox" checked={trackData.isOriginal ? true : false} onChange={toggleIsOriginal} />
                            This is an original work
                        </div>}
                      
                        <div className="original-work--form-wrapper">
                            {showOriginalWorksSuggestions && <div className="original-works--suggestions">
                                <p>Original works suggestions</p>
                                {originalWorksSuggestions.map((originalWork, index) => {
                                    return (
                                        <div className="single-original-work-suggestion" key={index} onClick={() => addSuggestedOriginalWork(originalWork)}>
                                           {originalWork.displayName}
                                        </div>
                                    )
                                })}
                            </div>}
                            <div className="original-work--form-group edit-track-modal--input-group">
                                <label>Work title</label>
                                <input className="edit-track-modal--input-field" disabled={trackData.isOriginal} type="text" value={workTitle} onChange={e => submitWorkTitle(e.target.value)}></input>
                            </div>
                            <div className="original-work--form-group edit-track-modal--input-group">
                                <label>Work Version</label>
                                <input className="edit-track-modal--input-field" disabled={trackData.isOriginal} type="text" value={workVersion} onChange={e => submitWorkVersion(e.target.value)}></input>
                            </div>
                            <div className="original-work--form-group edit-track-modal--input-group">
                                <label>Work Artists</label>
                                <input className="edit-track-modal--input-field" disabled={trackData.isOriginal} type="text" value={workArtists} onChange={e => submitWorkArtists(e.target.value)}></input>
                            </div>
                            <div className="original-work--form-group edit-track-modal--input-group">
                                <label>Work Featured Artists</label>
                                <input className="edit-track-modal--input-field" disabled={trackData.isOriginal} type="text" value={workFeaturedArtists} onChange={e => submitWorkFeaturedArtists(e.target.value)}></input>
                            </div>
                            <div className="original-work--form-group">
                                <div className="original-workform-validation-error">{error}</div>
                            </div>
                            <div className="original-work--form-group">
                                <button className="original-work--add-btn edit-track-modal--btn"  disabled={trackData.isOriginal}  onClick={value => submitOriginalWork(value)}>
                                    <div className="icon"><FontAwesomeIcon size="md" icon={faPlus} /></div>
                                    
                                    Add original work</button>
                            </div>
                        </div>
                        <div className="original-works--list">
                            <div className="original-works--header original-works--row">
                                <div className="original-works--header-element">Title</div>
                                <div className="original-works--header-element">Version</div>
                                <div className="original-works--header-element">Artists</div>
                                <div className="original-works--header-element">Featured Artists</div>
                                <div className="original-works--header-element">Remove</div>
                            </div>
                            {trackData.originalWorks && trackData.originalWorks.length > 0 && trackData.originalWorks.map((originalWork, index) => {
                                return <div className="single--original-work original-works--row" key={index}>
                                    <div>{originalWork.name}</div>
                                    <div>{originalWork.version}</div>
                                    <div>{originalWork.artistsDisplayName}</div>
                                    <div>{originalWork.artistsFeaturedDisplayName}</div>
                                    <div className="original-work--delete">
                                        <div className="original-work--delete-inner">
                                            <span className="delete-text">
                                                Delete

                                            </span>
                                            <FontAwesomeIcon size="sm" icon={faTrashAlt} onClick={() => {removeOriginalWork(index)}} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                   
                </div>

                <button onClick={submitModifications} className="edit-track-modal--btn submit-modifications-btn" >
                    <div className="icon"><FontAwesomeIcon size="md" icon={faSave} /></div>
                    Submit modifications</button>
                <div className="edit-track-modal--close" onClick={closeModal}>
                    <FontAwesomeIcon size="lg" icon={faTimesCircle} />
                </div>
            </div>

        </Modal>
    )
}

const mapStateToProps = state => ({
    globals: state.globalReducer,
    currencies: state.currencyReducer.currencies && state.currencyReducer.currencies.Currencies,

})

export default connect(mapStateToProps)(EditTrackModal)

