import React, { Component } from 'react';

import {Form, Field} from 'react-final-form';

import Loader from 'react-loader-spinner';

import axios from 'axios';

import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

// import Loader from 'react-loader-spinner';


class EditorContact extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isShown: true,
            isFormSubmitted: false,
            isLoading: false,
            responseMessage: 'fail',
        
         }
    }

    submitForm(formData){
        console.log('submit form', formData);


        this.setState({
            isLoading: true,
        })

        this.setState({
            isFormSubmitted: true
        })

        const {editorData} = this.props;
        
        // debugger;
        let body = formData;

        body['editorEmail'] = this.props.editorData.email;
        body['editorStageName'] = this.props.editorData.stageName; 


        axios({
            url: rootApiUrl + urls.editors.contact(),
            data: body,
            method: "POST",
            withCredentials: true,
        }).then(response => {

            const finalAnswer = response.data.status == 'success' ? "Message sent successfuly" : "An error occured while sending your message"
       
            this.setState({
                isLoading: false,
                responseMessage: finalAnswer
            })
        })
    
    }


    toggleShow(){
        this.setState({
            isShown: !this.state.isShown,
        })
    }

    handleChange(target,value){
        let currentFormState = this.state.formDetails;

        let newFormState = Object.assign({}, currentFormState);
        newFormState[target] = value;


        debugger;

        this.setState({
            formDetails: newFormState,
        })
    }

    render() { 
        const {isShown, isFormSubmitted, isLoading, responseMessage} = this.state;

        return ( 
        <div className="editor-contact--wrapper">
             <div className="sidebar-editors--title sidebar--title">
                Book {typeof this.props.editorData != 'undefined' && this.props.editorData.stageName}
                <span onClick={this.toggleShow.bind(this)}>toggle</span>
                </div>

                {isLoading ? <Loader /> : isShown && <div className="contact-me--inner-wrapper">
                    
                    {isFormSubmitted ? <div>{responseMessage}</div> : <Form 
                    onSubmit={this.submitForm.bind(this)}
                    render={({handleSubmit, pristine, invalid}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="contact-me--form-group">
                                <label>Your name</label>
                                <Field name="username" component="input" placeholder="Your name"  />
                            </div>
                            <div className="contact-me--form-group">
                                <label>Your email</label>
                                <Field name="userEmail" component="input" placeholder="Your email" />
                            </div>
                            <div className="">
                               
                                <Field name="emailBody" render={({input,meta}) => (
                                    <div className="contact-me--form-group">
                                        <label>Your message</label>
                                        <textarea {...input} placeholder="Your message" />
                                        {meta.touched && meta.error && <span>{meta.error}</span>}
                                    </div>
                                   
                                )} />
                               
                            </div>
                            <button type="submit" disabled={pristine || invalid}>
                                Submit
                            </button>
                        </form>
                        
                    )}
                    />}

                </div>}
        </div> );
    }
}
 
export default EditorContact;