import React, { Component } from 'react';

import {connect} from 'react-redux';

import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';
import axios from 'axios';

import SimpleTrackList from '../components/track/simpleTracklist'
import Modal from 'react-modal';

import * as actions from '../actions/index'

import Loader from 'react-loader-spinner';

import AVLogo from '../images/cc-av.png';
import VVLogo from '../images/cc-vv.png';
import i12logo from '../images/cc-i12.png';

function isJsonWaveform(waveformUrl) {
    return waveformUrl.indexOf('.json') !== -1;
  }

class Tracks extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: false,
            sortBy: "publishDate",
            sortOrder: -1,
            tracks: [],
            previousConfig: {},
            searchQuery: '',
            downloadQueueTracks: [],
            source: "",
            advancedToggled: false,
            minBpm: null,
            maxBpm: null,
            minYear: null,
            maxYear: null,
            selectedKeys: [],
            selectedGenres: [],
            selectedTags: [],
            selectedEditors: [],
            isModalOpen: false,
            hidePurchases: false,
            newCrateName: '',
            isSavingCrate: false,
            newCrateSaved: false,
            isDirty: "570682468c212a234356474b",
            isClean: "573508e8c21f49421626ec5e",
            isModalDQOpen: false,
            isModalLibraryOpen: false, 
            limit: null,
            sitePicks: false,
            pageSize: 15,
            isCriteriaUpadted: false,
            forcePage: null,
            isFirstTime: true,   
        }

        this.myRef = React.createRef() 
    }

    removeSelectedGenres(value){

        if(this.props.genresDisabled){
            return false;
        }

        let {selectedGenres} = this.state;
        for(var i = 0; i < selectedGenres.length; i++){
            if(selectedGenres[i] === value){
                selectedGenres.splice(i, 1);
            }
        }
        this.setState({
            isCriteriaUpdated: true,
            selectedGenres})
    }


    removeSelectedTags(value){
        let {selectedTags} = this.state;
        for(var i = 0; i < selectedTags.length; i++){
            if(selectedTags[i] === value){
                selectedTags.splice(i, 1);
            }
        }
        this.setState({
            isCriteriaUpdated: true,
            selectedTags})
    }

    resetAdData(){
        this.props.resetAdData();
    }

    removeSelectedEditors(value){
        this.props.resetAdData();

        if(this.props.editorsDisabled){
            return false;
        }

        let {selectedEditors} = this.state;
        for(var i = 0; i < selectedEditors.length; i++){
            if(selectedEditors[i] === value){
                selectedEditors.splice(i, 1);
            }
        }
        this.setState({
            isCriteriaUpdated: true,
            selectedEditors})
    }

    removeSelectedKeys(value){
        let {selectedKeys} = this.state;
        for(var i = 0; i < selectedKeys.length; i++){
            if(selectedKeys[i] === value){
                selectedKeys.splice(i, 1);
            }
        }
        this.setState({
            isCriteriaUpdated: true,
            selectedKeys})
    }

    toggleDirty(){
        const tagId = "570682468c212a234356474b";
        if(this.state.isDirty){
            this.setState({
                isDirty: false,
            }, () => {
                this.getTracks();
            })
        } else {
            this.setState({
                isDirty: tagId,
            }, () => {
                this.getTracks();
            })
        }
    }


    toggleClean(){
        const tagId = "573508e8c21f49421626ec5e";

        if(this.state.isClean){
            this.setState({
                isClean: false,
            }, () => {
                this.getTracks();
            })
        } else {
            this.setState({
                isClean: tagId,
            }, () => {this.getTracks();})
        }

    }

    changeCrateName(e){
        this.setState({
            newCrateName: e.target.value
        })
    }

    changePageSize(e){
     
        this.setState({
            pageSize: e.target.value
        }, () => {
            this.getTracks();
        })
    }

    setStartDate(date){
        this.props.deselectCrate();
        console.log('start date', date.getTime());
        this.setState({
            startDate: date
        })
    }
   
    setEndDate(date){
        this.props.deselectCrate();
        console.log('end date', date.getTime());
        this.setState({
            endDate: date
        })
    }

    setSort(sortBy, sortOrder){

        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        }, () => {
            let newConfig = this.state.previousConfig;
            this.getTracks(newConfig);
        })

    }

    toggleHidePurchases(){
        this.setState({
            hidePurchases: !this.state.hidePurchases
        }, () => {this.getTracks();})
    }

    resetFilters(){
        this.setEditors([]);
        this.setGenres([]);
        this.setTags([]);
        this.setKeys([]);
    }

    setSource(source){

       this.resetFilters();

       let previousConfig = Object.assign({},  this.state.previousConfig)
        previousConfig.page = 1;

        this.setState({
            forcePage: 0,
        })

        this.setState({
            previousConfig: previousConfig
        })
      
        if(source === 'download-queue'){
            this.setState({
                hidePurchases: true,
            })
        } else {
            this.setState({
                hidePurchases: false,
            })
        }


        console.log('set source', source)
        if(source == 'vault-classics'){

            // debugger;

            let x = this.state.selectedEditors.indexOf('59e26b60b17bac0013db40d6');
            if(x != -1){
                // debugger;
                this.setSource('alltracks');
                return false;
            }

            this.props.resetAdData();
            this.setState({
                source: 'alltracks',
                advancedToggled: true,
                selectedEditors: ['59e26b60b17bac0013db40d6']
            }, () => {
                this.getTracks();
            })
        } else if(source === "hotbox"){
            this.setState({
                source: source,
                sortBy: "allTimeDownloadCount",
                sortOrder: -1
            }, () => {
                this.getTracks();
            })
        } else if(source === 'my-library'){
            this.setState({
                source: source,
                sortBy: 'purchasedAt',
                limit: null,
                sitePicks: false,
            }, () => {
                this.getTracks();
                setTimeout(() => {
                    // this.setState({
                    //     sortBy: 'purchasedAt'
                    // })
                }, 500)
            })
        } else if (source == "site-picks"){
            this.setState({
                source: source,
                limit: 100,
                sitePicks: true,
            }, () => {
                this.getTracks();
            })
        } else {
            this.setState({
                source: source,
                sortBy: 'publishDate',

                limit: null,
                sitePicks: false,
            }, () => {
                this.getTracks();
            })
        }


    }

    setKeys(keys){
        console.log('setting keys', keys);
        this.setState({
            selectedKeys: keys,
            isCriteriaUpdated: true,

        })
    }

    setTags(tags){
        console.log("setTags", tags)
        this.setState({
            selectedTags: tags,
            isCriteriaUpdated: true,

        })
    }

    setGenres(genres){
        this.props.deselectCrate();
        this.setState({
            selectedGenres: genres,
            isCriteriaUpdated: true,

        })
    
    }

    setEditors(editors){
        this.props.resetAdData();

        this.props.resetAdData();
        this.setState({
            selectedEditors: editors,
            isCriteriaUpdated: true,

        })
    }

    setMinBpm(e){
        let minBpm = e.target.value;
        if(minBpm < 0){
            minBpm = 0;
        }

        this.setState({
            minBpm: parseInt(minBpm),
            isCriteriaUpdated: true,

        })

        if(this.state.maxBpm === null){
            this.setState({
                maxBpm: 250,
            })
        } else {}
    }

    setMaxBpm(e){

        let newValue = e.target.value > 250 ? 250 : e.target.value;
    
        if(newValue < 0){
            newValue = 0
        }

        // debugger;
        this.setState({
            maxBpm: parseInt(newValue),
            isCriteriaUpdated: true,

        })

        if(this.state.minBpm === null){
            this.setState({
                minBpm: 0,
            })
        }
    }

    resetBpmFilter(){
        this.setState({
            minBpm: null,
            maxBpm: null,
            isCriteriaUpdated: true,
        })


    }

    resetYearFilter(){
        this.setState({
            minYear: null,
            maxYear: null,
            isCriteriaUpdated: true,
        })

        //first set state so that it changes the number in the input to the default ones, than set to null so that the tag with "X" will not show

    }

    resetEndDate(){
        this.setState({
            endDate: null,
        })
    }

    setMinYear(e){
        this.setState({
            minYear: parseInt(e.target.value),
            isCriteriaUpdated: true,
            
        })

        if(this.state.maxYear == null){
            this.setState({
                maxYear: new Date().getFullYear(),
            })
        }
    }

    setMaxYear(e){
        this.setState({
            isCriteriaUpdated: true,
            maxYear: parseInt(e.target.value),
        })

        if(this.state.minYear == null){
            this.setState({
                minYear: 1950,
            })
        }
    }

    setMinReleaseDate(e){
        this.setState({
            minReleaseDate: parseInt(e.target.value),
            isCriteriaUpdated: true,

        })
    }

    setMaxReleaseDate(e){
        this.setState({
            maxReleaseDate: parseInt(e.target.value),
            isCriteriaUpdated: true,

        })
    }

    toggleAdvanced(){
        this.setState({
            advancedToggled: !this.state.advancedToggled
        })
    }

    handleSearch(){
        const newConfig = this.state.previousConfig;
        this.getTracks(newConfig);
    }

    updateSearchQuery(query){
        this.setState({
            searchQuery: query,
            isCriteriaUpdated: true,
        })
    }

    componentDidMount(){

        const {hasAdvancedSearch, selectedCurrency} = this.props;

        if(hasAdvancedSearch && typeof selectedCurrency != 'undefined'){
            this.props.getAllTags();
            this.props.getAllGenres();
            this.props.getAllKeys();
            this.props.getAllEditors(selectedCurrency._id);
        }

        if(typeof this.props.selectedEditors != 'undefined' && this.props.selectedEditors.length > 0){
            this.setState({
                selectedEditors: this.props.selectedEditors
            })
        }


        if(typeof this.props.selectedGenres!= 'undefined' && this.props.selectedGenres.length > 0){
            this.setState({
                selectedGenres: this.props.selectedGenres
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log('next isPurchasing props', nextProps.isPurchasing);
        // console.log('this isPurchasing props', this.props.isPurchasing);

        return true;
    }

    componentDidUpdate(prevProps){
        if(prevProps.isPurchasing === true && this.props.isPurchasing === false){
        
            this.getTracks();
        }

        if(prevProps.addingToDownloadQueue === false && this.props.addingToDownloadQueue === true){
            // this.getTracks();
            // console.error('adding to dq');
            this.setState({
                isLoading: true,
            })
        }

        if(prevProps.addingToDownloadQueue === true && this.props.addingToDownloadQueue === false){
            // console.error('getting tracks')
            this.getTracks();
        }

        if(prevProps.selectedCrate._id !== this.props.selectedCrate._id){
            this.getTracks();
        }

        if(Array.isArray(this.props.adData) && !Array.isArray(prevProps.adData)){
            debugger;
            this.setState({
                selectedEditors: [],
            }, () => {
                this.getTracks();
            })
        }

        if(prevProps.adData._id != this.props.adData._id){
           
            if(!Array.isArray(this.props.adData)){
                this.getTracks();
                this.setState({
                    selectedEditors: [this.props.adData.editorOwner],
                    advancedToggled: true,
                })
            } else {
                this.getTracks();
                this.setState({
                    selectedEditors: [],
                    advancedToggled: true,
                })
            }
            
        }

        // console.error('visting libarary?', prevProps.visitingLibrary, this.props.visitingLibrary)
        if(this.props.libraryModal === true && prevProps.libraryModal === false){
            // this.setSource("my-library")
            // alert('library')
            this.openLibraryModal();
           
        }

        if(this.props.visitingLibrary === true && prevProps.visitingLibrary === false){
            this.setSource("my-library")
            // alert('library')
            // this.openLibraryModal();
           
        }



        if(prevProps.selectedCurrency && prevProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== prevProps.selectedCurrency._id){
            this.setState({
                tracks: [],
            })
            this.getTracks();
            this.props.getAllTags();
            this.props.getAllEditors(this.props.selectedCurrency._id);
            this.props.getAllKeys();
            this.props.getAllGenres();
            this.props.getCrates(this.props.selectedCurrency._id);
        }

    }

    openLibraryModal(){
        this.setState({
            isModalLibraryOpen: true,
        })
    }

    closeLibraryModal(){
        this.setState({
            isModalLibraryOpen: false,
        })
    }


    saveCrate(){
        this.setState({
            isModalOpen: true
        })
    }

    closeSaveCrate(){
        this.setState({
            isModalOpen: false,
            newCreateName: "",
            newCrateSaved: false,
        })
    }

    closeBuyDQ(){
        this.setState({
            isModalDQOpen: false,
        })
    }

    handleSaveCrate(input){
        const {selectedEditors, selectedGenres, selectedKeys, selectedTags, searchQuery, newCrateName, minBpm, maxBpm, minYear, maxYear} = this.state;
        const {selectedCurrency, createCrate, getCrates} = this.props;

        const crateData = {
            genres: selectedGenres,
            editors: selectedEditors,
            keys: selectedKeys,
            tags: selectedTags,
            textSearchField: searchQuery,
            name: newCrateName,
            startBpm: minBpm,
            endBpm: maxBpm,
            minYear: minYear,
            maxYear: maxYear,
        }        

        console.log('saving crate', newCrateName);
        // createCrate(selectedCurrency._id, crateData);

        this.setState({
            isSavingCrate: true,
        })

        axios({
            withCredentials: true,
            method: "POST",
            data: crateData,
            url: rootApiUrl + urls.crates.createCreate(selectedCurrency._id, crateData)
        }).then(response => {
            if(response.data && response.data.Crate){
                getCrates(selectedCurrency._id);           
            }
            this.setState({
                isSavingCrate: false,
                newCrateSaved: true,
                newCrateName: "",
            })
        })

    }

    attemptBuyAllDQ(){
        this.setState({
            isModalDQOpen: true,
        })
    }

    buyAllDQ(){
        // console.log('buying all')

        this.setState({
            isModalDQOpen: false
        })

        const theTracks = this.state.tracks;
        let trackIds = theTracks.map(track => track._id);

        const theCurrency = this.props.selectedCurrency._id;

        this.props.buyTrack(theCurrency, trackIds, {}, false);
    }

    getTracks(config){
        const {selectedKeys, selectedTags, selectedGenres, selectedEditors, minBpm, maxBpm, minYear, maxYear, sortBy, startDate, endDate, hidePurchases, limit, sitePicks, isDirty, isClean, isLoading, areTracksLoading} = this.state;


        if(areTracksLoading){
            console.log('change page already requested');
            return false;
        }

        this.setState({
            isCriteriaUpdated: false,
            forcePage: null,
        })

        //if source is declared by props, use that. Else, use it from state.
        let source = this.props.source || this.state.source;
        const {getParams, selectedCrate, adData} = this.props;

        const {packId} = this.props;

        if(config){
            if(!config.page){
                config.page = 0;
            }

            this.setState({
                isLoading: true,
                areTracksLoading: true,
                previousConfig: config,
            })
        } else {
            this.setState({
                isLoading: true
            })
        }

        const updatedConfig =  config != null ? config : this.state.previousConfig; 
        const selectedCurrency = this.props.selectedCurrency;

        if(typeof selectedCurrency == 'undefined'){
            return false;
        }

        // create the url based on set source
        const getUrl = () => {
            
            switch(source){
                case ("download-queue"): return rootApiUrl + urls.downloadQueue.get(selectedCurrency._id);
                case ("single-pack"): return rootApiUrl + urls.collections.getPackById(selectedCurrency._id, packId, updatedConfig.page);
                case ("my-library"): return rootApiUrl + urls.myLibrary(selectedCurrency._id, updatedConfig.page);
                case("hotbox"): return rootApiUrl + urls.tracks.getHotBox(selectedCurrency._id, updatedConfig.page)
                default: return rootApiUrl + urls.tracks.getTracks(selectedCurrency._id, updatedConfig.page);
            } 
        }

        let theUrl = getUrl();

        const params = {
            rows: this.props.pageSize || this.state.pageSize || updatedConfig.pageSize || 10,
            sortOrder: -1,
            sortField: this.state.sortBy || "publishDate",
            // hidePurchased: true
        }


        params.tags = [];

        if(selectedKeys){ params["keys"] = selectedKeys  }
        if(selectedGenres){ params["genres"] = selectedGenres  }

        if(selectedTags){ params.tags = Object.assign([], selectedTags)  }
        if(selectedEditors){ params['editors'] = selectedEditors } 
        if(minBpm){ params['startBpm'] = minBpm }
        if(maxBpm){ params['endBpm'] = maxBpm  }
        if(minYear){ params['minYear'] = minYear }
        if(maxYear){ params['maxYear'] = maxYear } 
        if(startDate){ params['startPublishDate'] = startDate.getTime() }
        if(endDate){ params['endPublishDate'] = endDate.getTime() }
        if(hidePurchases){ params['hidePurchased'] = hidePurchases }
        if(sitePicks){ params['sitePicks'] = sitePicks }
        if(limit){ params['limit'] = limit }
 
        if(isDirty && !isClean){ params.tags.push(isDirty); }
        if(isClean && !isDirty){ params.tags.push(isClean); }

        if(selectedCrate && Object.keys(selectedCrate).length > 0){
            if(selectedCrate.genres){ params['genres'] = selectedCrate.genres }
            if(selectedCrate.tags){ params['tags'] = selectedCrate.tags }
            if(selectedCrate.keys){ params['keys'] = selectedCrate.keys }
            if(selectedCrate.editors){ params['editors'] = selectedCrate.editors }
        }        

        //Params set by parent via props are being used in devaour of the ones already set.
        if(getParams){
            for(let key in getParams){
                params[key] = getParams[key];
            }
        }

        if(adData && typeof adData.editorOwner != 'undefined'){
            params['editors'] = [adData.editorOwner]
        }


        if(this.state.searchQuery.length){
            params["q"] = this.state.searchQuery
        }

        if(typeof updatedConfig != 'undefined' && typeof this.state.previousConfig != 'undefined'){

            if(updatedConfig.page != this.state.previousConfig.page && !this.state.isFirstTime){
               this.handleScrollToElement();
              
            }
        }

        this.setState({ 
            isFirstTime: false,
        })

        axios({
            withCredentials: true,
            url: theUrl,
            params: params
        }).then( async (response) => {
            this.setState({
                isLoading: false,
                areTracksLoading: false,
            })

            if(response && response.data && response.data.Tracks || source == "download-queue" || "single-pack"  ){
             
                let getTracks = () => {
                    
                    switch(source){
                        case("download-queue"): return response.data.DownloadQueue.tracks.results;
                        case("single-pack"): return response.data.Collection.tracks;
                        default: return response.data.Tracks.results
                    }
                }
            
                const tracks = getTracks();

                const getDataSource = () => {
                     
                    switch(source){
                        case("download-queue"): return response.data.DownloadQueue.tracks;
                        case("single-pack"): return response.data.Collection;
                        default: return response.data.Tracks
                    }
                }

                const dataSource = getDataSource();

                this.setState({
                    tracks: tracks,
                    totalPages: dataSource.totalPages,
                    totalTracks: dataSource.total,
                })

                let newTracks = tracks;

                const f1 = async function(that){
                    const currencyUrl = rootApiUrl + urls.downloadQueue.get(selectedCurrency._id);

                    let downloadQueueTracks = [];

                    await axios({
                        url: currencyUrl,
                        withCredentials: true,
                    }).then((response) => {
                        if(response.data && typeof response.data.DownloadQueue == "object"){
                         downloadQueueTracks = response.data.DownloadQueue.tracks.results;
                        }

                        that.setState({
                            downloadQueueTracks: downloadQueueTracks,
                        })

                        const allDQiDS = [];
                        downloadQueueTracks.map((track) => {
                            allDQiDS.push(track._id);
                        })


                        if(typeof tracks != 'undefined') tracks.map((dtrack,index) => {
                            
                            const currentPos =  allDQiDS.indexOf(dtrack._id);
                            // console.log('current pos', currentPos);
                            if(allDQiDS.indexOf(dtrack._id) !== -1){
                                tracks[index].isInDownloadQueue = true;
                            }
                        })
           
                    })
    
                    that.setState({
                        tracks: newTracks,
                    })

                }

                const f2 = async function(that){

                    if(typeof tracks != 'undefined' && tracks.length > 0){
                        await Promise.all(tracks.map((track, index) => {
                            // global.log('we have track');
                            if(isJsonWaveform(track.waveformImageSnippetFileUrl)){
                                return axios({
                                    url: track.waveformImageSnippetFileUrl
                                }).then(response => {
                                    newTracks[index].waveformDownloadedData = response.data;
                                })
                            }
                                
                        }))
                    }
        
                    that.setState({
                        tracks: newTracks,
                    })
                    ///
                }


                if(source !== 'download-queue'){
                    this.setState({
                        smallAdsEnabled:true,
                    })
                } else {
                    this.setState({
                        smallAdsEnabled:false,
                    })
                }

                // if(source !== 'download-queue') f1(this)
                if(source !== 'download-queueASD') f1(this)

                f2(this);

            }

        }).catch(response => {
            this.setState({
                isLoading: false,
                areTracksLoading: false,
            })
        })
    }


    render() { 
        const {isLoading, selectedGenres, selectedKeys, selectedTags, selectedEditors, isModalOpen, isSavingCrate, newCrateSaved, source} = this.state;
        const {areFiltersLoading, selectedCurrency, activeTrack, sourceEnabled, hasAdvancedSearch, isPurchasing, keys, tags, genres, editors, selectedCrate, adData} = this.props;

        return (    
            <section className="tracks--outer-wrapper">
                <div  ref={this.myRef} className="tracks--top-section">
                    
                </div>

                <SimpleTrackList
                onUpdateSearch={this.updateSearchQuery.bind(this)}
                onSubmitSearch={this.handleSearch.bind(this)}
                setSort={this.setSort.bind(this)}
                setSource={this.setSource.bind(this)}
                setKeys={this.setKeys.bind(this)}
                setTags={this.setTags.bind(this)}
                setGenres={this.setGenres.bind(this)}
                setEditors={this.setEditors.bind(this)}
                setMinBpm={this.setMinBpm.bind(this)}
                setMaxBpm={this.setMaxBpm.bind(this)}
                setMinYear={this.setMinYear.bind(this)}
                setMaxYear={this.setMaxYear.bind(this)}
                selectedCurrency={this.props.selectedCurrency}
                toggleAdvanced={this.toggleAdvanced.bind(this)}
                toggleHidePurchases={this.toggleHidePurchases.bind(this)}
                toggleDirty={this.toggleDirty.bind(this)}
                toggleClean={this.toggleClean.bind(this)}
                setStartDate={this.setStartDate.bind(this)}
                setEndDate={this.setEndDate.bind(this)}
                keys={keys}
                tags={tags}
                areFiltersLoading={areFiltersLoading}
                genres={genres}
                editors={editors}
                adData={adData}
                {...this.state}
                pageSize={this.props.pageSize || this.state.pageSize}
                deselectCrate={this.props.deselectCrate} 
                hasAdvancedSearch={hasAdvancedSearch}
                sourceEnabled={sourceEnabled}
                sortByEnabled={source != 'hotbox'}
                activeTrack={activeTrack}
                changePageSize={this.changePageSize.bind(this)}
                page={this.state.previousConfig.page}
                getTracks={this.getTracks.bind(this)}
                isPurchasing={isPurchasing}
                editorsDisabled={this.props.editorsDisabled}
                genresDisabled={this.props.genresDisabled}
                selectedCrate={selectedCrate}
                selectedGenres={selectedCrate.genres || selectedGenres}
                saveCrate={this.saveCrate.bind(this)}
                selectedTags={selectedCrate.tags || selectedTags}
                selectedEditors={selectedCrate.editors || selectedEditors}
                selectedKeys={selectedCrate.keys || selectedKeys}
                removeSelectedGenres={this.removeSelectedGenres.bind(this)}
                removeSelectedEditors={this.removeSelectedEditors.bind(this)}
                removeSelectedKeys={this.removeSelectedKeys.bind(this)}
                removeSelectedTags={this.removeSelectedTags.bind(this)}
                resetBpmFilter={this.resetBpmFilter.bind(this)}
                resetYearFilter={this.resetYearFilter.bind(this)}
                resetEndDate={this.resetEndDate.bind(this)}
                isCriteriaUpdated={this.state.isCriteriaUpdated}
                smallAdsEnabled={typeof this.state.smallAdsEnabled != 'undefined' ? this.state.smallAdsEnabled : this.props.smallAdsEnabled}
                buyAllDQ={this.buyAllDQ.bind(this)}
                attemptBuyAllDQ={this.attemptBuyAllDQ.bind(this)}
                forcePage={this.state.forcePage}
                />

                <Modal
                isOpen={this.state.isModalDQOpen}
                ariaHideApp={false}

                className="modal--wrapper"
                >
                    {typeof selectedCurrency != 'undefined' && <div className="buy-dq--logo packages--selected-currency--logo modal-package-logo">
                        <img src={ selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo } />
                    </div>}
                    <div className="buy-dq--text">
                       Do you wish to buy all listed items? Cost: {typeof this.state.tracks != 'undefined' && this.state.tracks.length}
                    </div>
                    <div className="buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--btn" onClick={() => {this.buyAllDQ()}}>Confirm</button>
                    </div>
                    <div className="modal-close buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--close" onClick={this.closeBuyDQ.bind(this)}>Close</button>
                    </div>
                </Modal>

                <Modal
                isOpen={this.state.isModalLibraryOpen}
                arhiaHideApp={false}
                className="modal--wrapper"
                >
                     {typeof selectedCurrency != 'undefined' && <div className="buy-dq--logo packages--selected-currency--logo modal-package-logo">
                        <img src={ selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo } />
                    </div>}
                    <div className="buy-dq--text">
                      Tracks have been added to your library
                    </div>
                    <div className="buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--btn" onClick={() => { this.closeLibraryModal();  this.setSource("my-library"); }}>Visit library</button>
                    </div>
                    <div className="modal-close buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--close" onClick={this.closeLibraryModal.bind(this)}>Close</button>
                    </div>
                </Modal>

                <Modal 
                isOpen={isModalOpen}
                ariaHideApp={false}
                handleSaveCrate={this.handleSaveCrate.bind(this)}
                className="modal--wrapper"
                >
                  
                    {isSavingCrate ? <Loader /> : 
                    newCrateSaved ? <div className="modal-paragraph">New crate is saved </div>  : <div className="modal-inner-content">
                        <div className="modal-heading">Save search parameters as a crate</div>
                        <div className="modal-paragraph">A crate is a way to store your search. It will not save your search results, but it will save your search parameters, so that you don't have to repeat the process next time</div>
                        <div classNmae="modal-paragraph">Enter crate name</div>
                        <div className="modal-input">
                            <input onChange={this.changeCrateName.bind(this)} defaultValue={this.state.newCrateName}></input>
                        </div>
                        <button onClick={() => {this.handleSaveCrate(this.input)}}>Save as crate</button>
                    </div>}
                    <div className="modal-close">
                        <button onClick={this.closeSaveCrate.bind(this)}>Close</button>
                    </div>
                </Modal>

            </section>
         );
    }


    handleScrollToElement(event) {
        this.myRef.current.scrollIntoView({behavior: 'smooth'})  
    }

}

Tracks.defaultProps = {
    getParams: {},
    sourceEnabled: true,
    hasAdvancedSearch: true,
    selectedCrate: {},
    adData: [],
}

const mapDispatchToProps = dispatch => ({
    getAllTags: () =>  dispatch(actions.getAllTags()),
    getAllKeys: () =>  dispatch(actions.getAllKeys()),
    getAllGenres: () =>  dispatch(actions.getAllGenres()),
    getAllEditors: (currencyId) => dispatch(actions.getAllEditors(currencyId)),
    resetAdData: () => dispatch(actions.resetAdData()),
    deselectCrate: () => dispatch(actions.deselectCrate()),
    getCrates: (currencyId) => dispatch(actions.getCrates(currencyId)),
    createCrate: (currencyId, crateData) => dispatch(actions.createCrate(currencyId, crateData)),
    buyTrack: (currencyId, trackId, trackData, autoDownload) => dispatch(actions.buyTrack(currencyId, trackId, trackData, autoDownload, false)),

})

const mapStateToProps = state => ({...state.currencyReducer, ...state.globalReducer})

 
export default connect(mapStateToProps,mapDispatchToProps)(Tracks);