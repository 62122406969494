import React from 'react';

export default function InputGroup({label, placeholder = label, inputName, type = "text", value, onChange}){
    return (
        <div className="edit-track-modal--input-group">
            <label className="edit-track-modal--label">{label}</label>
            {type === "text" && <input className="edit-track-modal--input-field" type="text" placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} />}
            {type === 'long-text' && <textarea className="edit-track-modal--input-field" value={value} onChange={e => onChange(e.target.value)} />}
        </div>
    )
}

InputGroup.defaultProps = {
    type: 'text',
}