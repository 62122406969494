import React, { Component } from 'react';


import {connect} from 'react-redux';

import * as actions from '../actions/index';

import Loader from 'react-loader-spinner';
import Logo from '../images/cc-logo.png';

import { withRouter, Link } from 'react-router-dom'

import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";


const  mapDispatchToProps = dispatch => ({
    handleLogin: (data) => dispatch(actions.login(data)),
    checkAccount: (data) => dispatch(actions.checkAccount(data))
})

class Login extends Component {
    constructor(props) {
        super(props); 
        this.state = { 
            email: "",
            password: "",
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }


    onLogin(event){
        
        // debugger;

        event.preventDefault();
        this.setState({
            isLoading: true,
        })

        let userData = {
            
            password: this.state.password,
            username: this.state.email,
        };
        // debugger;


        this.props.handleLogin(userData);
    } 

    handleSignup(){
     
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleRecoverPassword(){
       
    }

    handleCheck(){
        this.props.checkAccount();

    }

    componentDidUpdate(prevProps){
        const {history} = this.props;
        
        if(this.props.loggedIn && this.props.loggedIn != prevProps.loggedIn){
            console.log('has logged')
            setTimeout(() => {
                history.push('/')
            })
        }
    }

    componentDidMount(){

    }
    
    render() { 
        const {isLoading} = this.props;

        const isLoggedIn = this.props.loggedIn
        const loginTitle = isLoggedIn ? "You are logged" : "Please login";
        
        return ( 
     
            <section className="app--center-wrapper login-wrapper">
                <img className="login-page--logo" src={Logo} />
                
                <h3 className="login--title">{loginTitle}</h3>
                {!isLoggedIn && <Link to="/resetpassword/"><p className="login--sub-title">Forgot password?</p></Link>}

                {!isLoggedIn && <form className="bs-login-form" onSubmit={this.onLogin.bind(this)}>
                    <FormGroup controlId="email" >
                        {/* <FormLabel>Email</FormLabel> */}
                        <FormControl 
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder='email address'
                        />

                    </FormGroup>
                    <FormGroup controlId="password" >
                        {/* <FormLabel>Password</FormLabel> */}
                        <FormControl
                            placeholder="password"
                            onChange={this.handleChange}
                            type="password"
                        />
                    </FormGroup>
                    <Button
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                    >Login</Button>
                </form>}
                

                {this.props.loginRequested && !isLoading &&  (isLoggedIn ? <div>Success</div> : <div>There was a problem signin in</div>)}
                {isLoading && <Loader />}
                {this.props.login && <button onClick={this.handleCheck.bind(this)}>Random call</button>}
                {!this.props.loginRequested && <div><Link to="/register">Click here to register</Link></div>}
            </section>

         );
    }
}

export default connect(state => ({...state.userReducer}), mapDispatchToProps)(withRouter(Login))