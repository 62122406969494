import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import userReducer from './userReducer';
import currencyReducer from './currencyReducer';
import trackReducer from './trackReducer';
import playerReducer from './playerReducer';
import globalReducer from './globalReducer';

export default combineReducers({
	userReducer,
	simpleReducer,
	currencyReducer,
	trackReducer,
	playerReducer,
	globalReducer
});