import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMusic, faCoins} from '@fortawesome/free-solid-svg-icons'


class SidebarPacks extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            packs: [],
            isShown: true,
            sortField: 'updatedAt',
            sortOrder: -1
        }
    }

    getPacks(config){
        const {getParams, selectedCurrency} = this.props;
        const {sortField, sortOrder} = this.state;


        if(config){
            if(!config.page){
                config.page = 0;
            }
        }

        if(typeof selectedCurrency === 'undefined'){
            return false;
        }

        const theUrl = rootApiUrl + urls.collections.listCollections(selectedCurrency._id);

        const params = {
            rows: 10,
            sortField: sortField,
            sortOrder: sortOrder,
        }

        if(getParams){
            for(let key in getParams){
                params[key] = getParams[key];
            }
        }

        axios({
            withCredentials: true,
            url: theUrl,
            params: params,
        }).then(response => {
          
            if(response.data && response.data.Collections && response.data.Collections.results){
                this.setState({
                    isLoading: false, 
                    packs: response.data.Collections.results,
                })
            }
        }).catch(error => {
            console.error(error)
        })

    }

    toggleShow(){
        this.setState({
            isShown: !this.state.isShown,
        })
    }



    componentDidMount(){
        const clientWidth = window.innerWidth;
        if(clientWidth < 901){
            this.setState({isShown: false})
        }

        this.getPacks();
    }

    setSortMechanism(e){
        this.setState({
            sortField: e.target.value
        }, () => {
           
            this.getPacks();
        })
       
    }

    render() { 
        const {isLoading, packs, isShown, sortField} = this.state;
        const {title} = this.props;

        return ( 
            typeof this.props.selectedCurrency != 'undefined' ? <section className="sidebar-packs--main-wrapper">
                {isLoading ? <Loader /> : 
                <div >
                    {title && <div  style={{backgroundColor: this.props.selectedCurrency.color}} className="sidebar-packs--title sidebar--title">{title}
                    <span onClick={this.toggleShow.bind(this)}>toggle</span>                    
                    </div>}
                    {isShown && <div className="sidebar--dropdown">
                        <label className="sidebar--label">Sort by:</label>
                        <select onChange={this.setSortMechanism.bind(this)} className="sidebar--select " defaultValue={sortField}>
                        <option value="updatedAt">New releases</option>
                        <option value="last30DaysDownloadCount">Top movers</option>
                    </select></div>} 
                    {isShown && packs.map((pack, index) => {
                        const imgSrc = (pack.squareImage && pack.squareImage.url) || (pack.editorOwner.logoSquare.url);
                        
                        if(index < 10) return (
                        <Link key={index} to={"/packs/"+pack._id} >
                            <div key={index} className="sidebar-packs--single-pack">
                                <div className="single-pack--first-row">
                                    <div className="single-pack--image"><img src={imgSrc} /></div>
                                    <div className="single-pack--info">
                                        <div className="single-pack--name">{pack.name}</div>
                                        <div className="single-pack--data">
                                            <div className="single-pack--length">{pack.tracks.length}<FontAwesomeIcon className="single-pack--icon" icon={faMusic} /></div>
                                            <div className="single-pack--credits">{pack.credits}<FontAwesomeIcon className="single-pack--icon" icon={faCoins} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>)

                    })}
                    {true && <Link to={"/packs"}>
                    <div className="sidebar-packs--single-pack sidebar--view-all">
                        View all    
                    </div>
                    </Link>}
                </div>  
                }
            </section> : <section></section>
         );
    }
}

SidebarPacks.defaultProps = {
    getParams: {
    }
}


const mapStateToProps = state => ({...state.currencyReducer})

 
export default connect(mapStateToProps, {})(SidebarPacks);