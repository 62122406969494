import React, { Component } from 'react';
import {connect} from 'react-redux';

import axios from 'axios';
import * as actions from '../actions/index';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import Inventory from './admin/Inventory';
import Banner from './admin/Banner';
import PoorTracks from './admin/PoorTracks';

import { Button } from "react-bootstrap";

export default class AdminContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            section:'poor-tracks',
           
        }
    }
    
    setSection(section){
        this.setState({
            section: section
        })
    }

    componentDidMount(){
        // setTimeout(() => {
        //     this.setSection('poor-tracks')
            
        // }, 1)
    }

    render(){
        const {section} = this.state;

        return (
            <section className="app--center-wrapper admin-wrapper">
                <h2>Admin section</h2>

                <section className="admin--section">
                    <div className="admin--section-head">
                      
                        <div className="admin-section--choose">
                            <Button variant={section == 'inventory' ? "dark" :"secondary"} onClick={() => this.setSection('inventory')} >Inventory</Button>
                            <Button variant={section == 'banner' ? "dark" :"secondary"} onClick={() => this.setSection('banner')}> Banner control</Button>
                            <Button variant={section == 'poor-tracks' ? "dark" :"secondary"} onClick={() => this.setSection('poor-tracks')}> Poor tracks</Button>

                        </div>
                        <div className="admin-section--inner">
                            {section == 'inventory' && <Inventory />}
                            {section == 'banner' && <Banner />}
                            {section == 'poor-tracks' && <PoorTracks />}
                        </div>
                    </div>
                    <div className="admin--old-version">
                        <h5>Old v2 admin</h5>
                        <a href="https://v2-beta.crooklynclan.net">
                            <Button variant="dark">V2 admin</Button>
                        </a>

                    </div>
                </section>

            </section>
        )
    }

}