import React, { Component } from 'react';
// import scriptLoader from 'react-async-script-loader';
import {connect} from "react-redux";
// import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

import { PayPalButton } from "react-paypal-button-v2";

import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';
import axios from 'axios';

import * as actions from '../../actions/index';

class PaypalCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showButton: true,
         }

    }


    render() { 
        const {packageId} = this.props;
        const {showButton} = this.state;
        // const {isScriptLoadSucceed, isScriptLoaded} = this.props;
        
        // const PayPalButton = (isScriptLoaded && isScriptLoadSucceed) ? paypal.Button.driver('react', { React, ReactDOM }) : null;

        //

        return ( <div className="paypal-button--wrapper">
            {showButton && <PayPalButton 
            //  onSuccess={((details,data) => {
            //     console.log('payment success');
            // })}

            options={{
                clientId:"AXmJaF6UM8YID8hpThpbhY31Kg9QeXUJTC5FsC5qc7i6fcVO_AgqVljbSYurKCXkKrLQLte3kARhamuS"
            }}
            createOrder={async (data, actions) => {
                console.log("creating order!");

                return axios(rootApiUrl + urls.payment.purchasePackage(packageId), {
                    method: 'post',
                    withCredentials: true,
                }).then(function(res) {
                    let token;

                    for (let link of res.data.links) {
                        if (link.rel === 'approval_url') {
                            token = link.href.match(/EC-\w+/)[0];
                        }
                    }

                    return token;
                })

                
            }}

            onApprove={(data, actions) => {
                // Capture the funds from the transaction

                console.log('onApprove', data);
                
                return axios({
                    method: "POST",
                    url: rootApiUrl + urls.payment.executePayment(packageId),
                    withCredentials: true, 
                    data: {
                        paymentID: data.paymentID,
                        payerID: data.payerID,
                        agreementID: data.orderID,
                    }
                }).then(response => {
                    this.props.getCurrencies(true);
                    this.props.closeModal();
                })


                return actions.order.capture().then(function(details) {
                  // Show a success message to your buyer
                //   alert("Transaction completed by " + details.payer.name.given_name);
                    console.log('capture', details);
                    

                  // OPTIONAL: Call your server to save the transaction
                


                });
            }}

            />}
        </div> );
    }
}

const mapDispatchToProps = dispatch => ({
    
    getCurrencies: (refresh) => dispatch(actions.getCurrencies(refresh))
})

const mapStateToProps = state => ({...state.currencyReducer})
 
export default connect(mapStateToProps, mapDispatchToProps)(PaypalCheckout);