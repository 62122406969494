import React, { Component } from 'react';

class NotLogged extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (   
        <div className="app--center-wrapper game-container">
            <div className="not-logged--outer-wrapper">
                
                <div className="not-logged--inner-wrapper">
                    <h3 className="not-logged--text">You are not logged in</h3>   
                   
                </div>
            </div>
        </div> 
        );
    }
}
 
export default NotLogged;