import React, { Component } from 'react';

import {withRouter} from "react-router-dom";

import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';
import EditorContact from '../components/general/editorContact';

import TracksContainer from '../containers/tracksContainer';
import Packs from './sidebar/packs';

import {connect} from 'react-redux';
import {logout, setCurrency} from '../actions/index';

import { SocialIcon } from 'react-social-icons'



class EditorContainer extends Component {
    constructor(props) { 
        super(props);
        this.state = { 
            editorData: {},
            isLoading: true,
         }
    }

    getEditorData(stageName){
        const theUrl = rootApiUrl + urls.editors.getEditorByStageName(stageName);
        console.log(theUrl)        

        // debugger;
        return axios({
            withCredentials: true,
            url: theUrl
        }).then(response => {
            // console.log(response)

            this.setState({
                editorData: response.data.Editor,
                // isLoading: false
            })

            const editorCurrencies = response.data.Editor.currencies;

            // console.error('currencies', editorCurrencies);


            const isPresent = editorCurrencies.indexOf(this.props.selectedCurrency._id) != -1;

            if(editorCurrencies.length > 0 && typeof this.props.selectedCurrency != 'undefined' && editorCurrencies.indexOf(this.props.selectedCurrency._id) == -1 ){
                // alert('editor not in current currency');

                let allCurrencies = this.props.currencies.Currencies;

                let keys = Object.keys(allCurrencies)
                // debugger;

                let newCurrenciesObject = {};
                
                Object.keys(allCurrencies).map(key => {
                    let id = allCurrencies[key]._id;

                    newCurrenciesObject[id] = allCurrencies[key];
                })

                // debugger;
                this.props.setCurrency(newCurrenciesObject[editorCurrencies[0]])

                this.setState({
                    rerendering: true,
                })

            }   

            this.setState({
                isLoading: false,
            })

        }).catch(error => {
            // console.error("bug", error)
            
        })
    }

    componentDidMount(){
        if(typeof this.props.computedMatch != "undefined"){
            const {params} = this.props.computedMatch;
            console.log(params)   

            const stageName = params.id;

            this.setState({
                stageName: stageName,
            })
            return this.getEditorData(stageName)

        }
    }

    changeCurrency(currencyData){
        console.log('changing currency', currencyData);
        this.props.setCurrency(currencyData);

        this.closeMenu();
    }

    createMarkup(markup = 'First &middot; Second') {
        return {__html: markup};
    }

    render() { 
        
        const {editorData, isLoading} = this.state

        return ( 
        <div className="app--center-wrapper editor-page--outer-wrapper">
            {isLoading ? <Loader /> : 
            <div className="editor-page--wrapper">

                <div className="editor--intro">
                    <div className="editor--headline">
                        <div className="editor--name">{editorData.stageName}</div>
                    </div>
                    <div className="editor--social-media">

                        {typeof editorData.twitterUrl !== 'undefined'  && editorData.twitterUrl.length > 0 && <SocialIcon url={editorData.twitterUrl} style={{height: 25, width: 25}} />}

                        {typeof editorData.youtubeUrl !== 'undefined' && editorData.youtubeUrl.length > 0 && <SocialIcon url={editorData.youtubeUrl} style={{height: 25, width: 25}}  />}

                        {typeof editorData.facebookUrl !== 'undefined' && editorData.facebookUrl.length > 0 && <SocialIcon url={editorData.facebookUrl}  style={{height: 25, width: 25}} />}

                        {typeof editorData.soundCloudUrl !== 'undefined' && editorData.soundCloudUrl.length > 0 && <SocialIcon url={editorData.soundCloudUrl}  style={{height: 25, width: 25}}  />}

                        {typeof editorData.linkedinUrl !== 'undefined' && editorData.linkedinUrl.length > 0 && <SocialIcon url={editorData.linkedinUrl}  style={{height: 25, width: 25}}  />}

                        {typeof editorData.instagramUrl !== 'undefined' && editorData.instagramUrl.length > 0 && <SocialIcon url={editorData.instagramUrl} style={{height: 25, width: 25}}  />}
    
                    </div>
                    <img src={editorData.logoLong.url} />
                
                    <div className="editor--first-row">
                    <div className="editor--bio">
                        <div dangerouslySetInnerHTML={this.createMarkup(editorData.biography)} ></div>
                    </div>
                    <div className="editor--square-img">
                        <img src={editorData.photo.url} />
                    </div>
                </div>
                </div>

                <section className="editor-page--content-section main-content" >
                    <aside className="sidebar--main-container">
                        
                        <Packs 
                        title="My latest packs" 
                        getParams={{
                            editors: [editorData._id],
                            rows: 10,
                            sortField: "updatedAt",
                            sortOrder: -1,
                        }}
                        />

                        <EditorContact
                        editorData={editorData}
                        />

                    </aside>

                    
                    <TracksContainer
            
                    getParams={{
                        editors:[editorData._id]
                    }}
                    editorsDisabled={true}
                    selectedEditors={[editorData._id]}
                    sourceEnabled={false}
                    hasAdvancedSearch={true}
                    />
            
                </section>
            </div>
            }
        </div> );
    }
}

const mapStateToProps = state => ({
    ...state.currencyReducer
})
 
const mapDispatchToProps = dispatch => ({
    setCurrency: (currencyData) => dispatch(setCurrency(currencyData)),

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditorContainer));