import React, { Component } from 'react';

import {connect} from 'react-redux';

import {Link, withRouter} from 'react-router-dom';
import {logout, setCurrency, resetAdData} from '../actions/index';

import Logo from '../images/cc-logo.png';

import AVLogo from '../images/cc-av.png';
import VVLogo from '../images/cc-vv.png';
import i12logo from '../images/cc-i12.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMusic, faCoins, faTimes, faInfinity} from '@fortawesome/free-solid-svg-icons'

import ResponsiveMenu from 'react-responsive-navbar';

import ResponsiveNav from '../containers/responsiveNav';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';


const currenciesLogos = [
    {id: '573acd030424de743256b6e8', logo: AVLogo},
    {id: '573acdea0424de743256b6e9', logo: VVLogo},
    {id: '573acfcf0424de743256b6ec', logo: i12logo}
]


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuToggled: false,
          }
    }

    changeCurrency(currencyData){
        console.log('changing currency', currencyData);
        this.props.setCurrency(currencyData);

        const {history} = this.props;     
        
        this.props.resetAdData();
        

        setTimeout(() => {
            history.push('/')
        })

        this.closeMenu();


    }

    handleMenuToggle(e){
        // console.log('toggle');
        this.setState({
            menuToggled: !this.state.menuToggled
        })
    }

    closeMenu(){
        this.setState({
            menuToggled: false
        })
    }

    goToPackages(){
        const {history} = this.props;


        console.log('going to packages')
        setTimeout(() => {
            history.push('/packages')
        }, 500)
    }

    render() {  
        let loggedIn = this.props.loggedIn
        const {userData, currencies, selectedCurrency} = this.props
        const {menuToggled} = this.state;

        const pHeight = typeof this.navbarWrapper != 'undefined' ? this.navbarWrapper.clientHeight : 50
        const isUserData = typeof userData != 'undefined'

        return ( <div className="header--outer-wrapper" style={{height: pHeight + "px"}}>
    
            <Navbar expanded={menuToggled} onToggle={this.handleMenuToggle.bind(this)} expand="lg" ref={(navbarWrapper) => this.navbarWrapper = navbarWrapper}>
               
                    <Link className="navbar-brand" to="/"><div className="header--logo-wrapper"><img src={Logo} /></div></Link>
                               
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {/* <button onClick={this.handleMenuToggle.bind(this)}>Toggle</button> */}
                
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    {loggedIn && currenciesLogos.map((currency, index) => {
                            const isSelected = typeof selectedCurrency != 'undefined' && selectedCurrency._id === currency.id 
             
                            if(typeof currencies != 'undefined') return <a  target={"_blank"} key={index} className={isSelected ? "header--currency is-selected nav-link" : "nav-link header--currency"} 
                            onClick={() => {this.changeCurrency(currencies[index])}}>
                                {currencies[index].name}
                                {true &&  <div onClick={this.goToPackages.bind(this)} className="header--currency-balance"><FontAwesomeIcon className="single-pack--icon" icon={faCoins} /> {currencies[index].creditBalance === -1 ? <FontAwesomeIcon className="single-pack--icon" icon={faInfinity} /> : currencies[index].creditBalance }</div>  }
                            </a>
                        })}
                        {!loggedIn && <Link onClick={this.closeMenu.bind(this)}  className="nav-link" to="/login" >Login</Link>}
                        {loggedIn && <Link onClick={this.closeMenu.bind(this)} to="/packages" className="nav-link">Packages</Link>}
                        <a onClick={this.closeMenu.bind(this)}  className="nav-link" href="
https://www.crooklynclan.net/gear-shop">Gear shop</a>
                        
                    </Nav>

                    <Nav className="ml-auto menu-right">
                        {isUserData && userData.isAdmin === true &&  <Link to="/admin">Admin</Link> }
                        {/* {isUserData && userData.userRole === 'editor' && <a href="https://v2-beta.crooklynclan.net/editoradmin/welcome" className="nav-link">Editor</a>} */}
                        {/* {isUserData && userData.userRole === 'editor' && <a href="https://v2-beta.crooklynclan.net/editoradmin/welcome" className="nav-link">Editor</a>} */}
                        {loggedIn && <Link onClick={this.closeMenu.bind(this)}  className="nav-link" to="/my-account">Hey, {userData && userData.name.first}</Link>}
                        {loggedIn && <Nav.Link onClick={this.closeMenu.bind(this)} ><span onClick={this.props.logout}>Logout</span></Nav.Link>}
                    </Nav>

                </Navbar.Collapse>
            </Navbar>

        </div>);
    }
}
 
const mapDispatchToProps = dispatch => ({
    setCurrency: (currencyData) => dispatch(setCurrency(currencyData)),
    logout: () => dispatch(logout()),
    resetAdData: () => dispatch(resetAdData()),
})


export default connect(state => ({...state.userReducer, 
    currencies: state.currencyReducer.currencies && state.currencyReducer.currencies.Currencies,    
    selectedCurrency: state.currencyReducer.selectedCurrency,
    userData: state.userReducer.userData
}), mapDispatchToProps)(withRouter(withRouter(Header)))