import React, { Component } from 'react';

import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import TracksContainer from '../containers/tracksContainer';


class GenreContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            genreData: {}
        }
    }

    getGenreData(){
        let genreName;

        if(typeof this.props.computedMatch != "undefined"){
            const {params} = this.props.computedMatch;
 
            genreName = params.id; 
        }

        const theUrl = rootApiUrl + urls.genres.getGenreByName(genreName);

        axios({
            withCredentials: true,
            url: theUrl
        }).then(response => {
            if(response.data && response.data.Genre){
                this.setState({
                    genreData: response.data.Genre,
                    isLoading: false
                })
            } else if(response.data && response.data === "not found"){
                this.setState({
                    genreData: response.data,
                    isLoading: false
                })
            }
        }).catch(error => console.error(error))


    }

    componentDidMount(){
        this.getGenreData();
    }

    render() { 
        const {genreData, isLoading} = this.state;

        let genreName;
        if(typeof this.props.computedMatch != "undefined"){
            const {params} = this.props.computedMatch;
 
            genreName = params.id; 
        }

        const notFound = (genreData === 'not found');
        const params = {
            genres: [genreData._id],
        }
        
        return ( 
        <div className="app--center-wrapper genre-page--wrapper">
            <div className="genre-page--title"> Genre: {genreName}</div>
            {!isLoading && (notFound ? <div>Can't find data for this genre</div>:
            <div className="genre-page--inner-wrapper">
                <TracksContainer 
                getParams={params}
                genresDisabled={true}
                selectedGenres={[genreData._id]}
                />
            </div>)
            }
        </div> );
    }
}
 
export default GenreContainer;