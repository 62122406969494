import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Route, withRouter} from 'react-router-dom';
import NotLogged from './notLogged';
import Login from '../../containers/loginContainer';

import * as actions from '../../actions/index';

const mapStateToProps = state => ({...state.userReducer})

const mapDispatchToProps = dispatch => ({
    resetAdData: () => dispatch(actions.resetAdData())
})


class ProtectedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        this.props.resetAdData();
    }

    componentDidUpdate(prevProps){
        const {loggedIn, computedMatch} = this.props;

        if(computedMatch && computedMatch.path && (computedMatch.path != prevProps.computedMatch.path)){
            this.props.resetAdData();
        }
    }

    render() { 
        

        const Component = this.props.component;
        const {loggedIn, computedMatch} = this.props;

        return ( 
            <Route render={props => {
               return loggedIn ? <Component {...this.props}/> : <Login />
            }}/>
         );
    }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute);