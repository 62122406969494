import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faArrowCircleDown,
  faPlusCircle,
  faCartArrowDown,
  faCheckCircle,
  faInfoCircle,
  faTrashAlt,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";

import WaveformReact from "./waveform";

import Loader from "react-loader-spinner";

import * as actions from "../../actions/index";

function isJsonWaveform(waveformUrl) {
  return waveformUrl.indexOf(".json") !== -1;
}

function isPngWaveform(waveformUrl) {
  return waveformUrl.indexOf(".png") !== -1;
}

function normalizeSamples(samples) {
  let highestValue = 0;
  for (let i = 0; i <= samples.length; i++) {
    if (samples[i] > highestValue) {
      highestValue = samples[i];
    }
  }

  const newSamples = [];
  for (let j = 0; j <= samples.length; j++) {
    const newValue = samples[j] / highestValue;
    newSamples.push(newValue);
  }

  // console.log(newSamples);
  return newSamples;
}

class Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJson: null,
      isDetailedView: false,
    };
  }

  toMMSS = function (number) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  getRef() {
    return this.waveformCanvas;
  }

  componentDidMount() {
    const {
      _id,
      waveformImageFileUrl,
      waveformImageFileName,
      name,
      waveformDownloadedData,
    } = this.props.trackData;

    const isCurrentTrackPlaying =
      this.props.activeTrack != null && this.props.activeTrack._id === _id;

    const isJson = isJsonWaveform(waveformImageFileName);
    const isPng = isPngWaveform(waveformImageFileName);
  }

  shouldComponentUpdate(nextState, nextProps) {
    const { _id } = this.props.trackData;

    const isAnyTrackSelected =
      this.props.activeTrack != null && typeof this.props.activeTrack
        ? true
        : false;
    const isCurrentTrack =
      this.props.activeTrack != null &&
      typeof this.props.activeTrack != "undefined" &&
      this.props.activeTrack._id === _id;

    let isSameTrack = false;
    if (typeof nextProps.trackData != "undefined") {
      isSameTrack = _id === nextProps.trackData._id;
    } else {
      isSameTrack = false;
    }
    // console.log('is current track', isCurrentTrack);
    // if(isCurrentTrack) console.log('ddata', nextProps);

    // console.log('is any track selected', isAnyTrackSelected, isCurrentTrack);
    if (isSameTrack && isAnyTrackSelected && !isCurrentTrack) {
      return false;
    }

    if (nextProps.waveformDownloadedData) {
      console.log("we have downloaded data");
    }

    return true;
  }

  handleProgress(data) {
    const { _id } = this.props.trackData;
    const isCurrentTrack =
      this.props.activeTrack != null &&
      typeof this.props.activeTrack != "undefined" &&
      this.props.activeTrack._id === _id;

    if (isCurrentTrack) {
      this.props.setTrackProgress(_id, data);
    } else {
      this.props.setActiveTrack({ ...this.props.trackData });
    }
  }

  handlePlayButton(asd) {
    const { _id } = this.props.trackData;

    console.log("playing track", asd);

    const isCurrentTrackPlaying =
      typeof asd != "undefined" && typeof this.props.activeTrack != "undefined"
        ? this.props.activeTrack._id === asd._id
        : this.props.activeTrack != null &&
          typeof this.props.activeTrack != "undefined" &&
          this.props.activeTrack._id === _id;

    if (!isCurrentTrackPlaying) {
      this.props.setActiveTrack(
        typeof asd != "undefined" ? asd : { ...this.props.trackData }
      );
    } else {
      this.props.togglePlay();
    }
  }

  buyTrack(_id, currency, trackData) {
    // const {_id, currency} = this.props.trackData;

    const { source } = this.props;

    let the_id = _id || this.props.trackData._id;
    let theCurrency = currency || this.props.trackData.currency;
    let theTrackData = trackData || this.props.trackData;

    const confirmPurchase = source !== "my-library";

    if (!the_id || !theCurrency) {
      return;
    } else {
      // alert(source)
      this.props.buyTrack(
        theCurrency,
        [the_id],
        theTrackData,
        true,
        confirmPurchase
      );
    }
  }

  componentWillUnmount() {
    // global.log('unmounting');
  }

  handleDQ() {
    const { _id, currency } = this.props.trackData;
    this.props.addToDownloadQueue(currency, _id);
  }

  handleRemoveDQ() {
    console.log("removing");
    const { _id, currency } = this.props.trackData;
    this.props.removeFromDownloadQueue(currency, _id);
  }

  toggleDetailedView() {
    this.setState({
      isDetailedView: !this.state.isDetailedView,
    });
  }

  render() {
    // console.log(this.props.trackData, "this.props.trackData");
    const {
      _id,
      name,
      description,
      isPurchased,
      artistPrimaryName,
      artistsFeaturedDisplayName,
      editor,
      editorPrimaryName,
      publishDate,
      version,
      startBpm,
      inKey,
      releaseYear,
      trackLength,
      waveformDownloadedData,
      waveformImageSnippetFileUrl,
      isInDownloadQueue,
      genres,
      originalWorks,
      tags,
      cleanDirty,
      versionType,
      introType,
      outroType,
      tracksInSameReleases,
    } = this.props.trackData;
    const {
      isPlaying,
      isTrackLoading,
      isLoading,
      source,
      modifiedEditors,
      key,
      nr,
      selectedCurrency,
    } = this.props;

    const { isDetailedView } = this.state;

    const activeTrackInfo = {};

    let i = 0;
    let peaks = [];
    while (i < 800) {
      i++;
      peaks.push(1);
    }

    const formattedDate = publishDate.split("T")[0];
    const isCurrentTrackPlaying =
      this.props.activeTrack != null && this.props.activeTrack._id === _id;

    const isJson = isJsonWaveform(waveformImageSnippetFileUrl);
    const isPng = isPngWaveform(waveformImageSnippetFileUrl);

    const isWaveformDownloaded =
      waveformDownloadedData != null && waveformDownloadedData.length
        ? true
        : false;
    // console.log("isWaveformDownloaded", isWaveformDownloaded)

    window.editrs = modifiedEditors;
    const editr =
      typeof modifiedEditors != "undefined" && modifiedEditors[editor];
    const logoSquare =
      typeof modifiedEditors != "undefined" &&
      modifiedEditors[editor] &&
      modifiedEditors[editor].logoSquare;
    const artistLogoUrl =
      typeof modifiedEditors != "undefined" &&
      modifiedEditors[editor] &&
      modifiedEditors[editor].logoSquare.url;

    return (
      <div
        className={
          "tracklist--single-track " +
          (isPurchased ? "is-purchased " : " ") +
          (isCurrentTrackPlaying ? "is-playing " : " ")
        }
      >
        <div className="single-track--simple-data">
          <div
            className="single-track--info-btn"
            onClick={this.toggleDetailedView.bind(this)}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>

          <div className="single-track--main-data">
            {
              <div
                style={{ backgroundColor: selectedCurrency.color }}
                className="single-track--nr"
              >
                {!isLoading ? nr : " "}
              </div>
            }

            <div className="single-track--first-group">
              <div
                className="single-track--artist-image"
                style={{ backgroundImage: `url(${artistLogoUrl})` }}
              >
                {!artistLogoUrl && <div>{editorPrimaryName}</div>}
              </div>
              <div className="single-track--editor-primary">
                {editorPrimaryName}
              </div>
            </div>

            <div className="single-track--main-info">
              <div
                className="single-track--date"
                style={{ color: selectedCurrency.color }}
              >
                {formattedDate}
              </div>
              <div className="single-track--track-name">{name}</div>
              <div className="single-track--version">{version}</div>
              <div className="single-track--artist-primary">
                {artistPrimaryName}
              </div>
              <div className="single-track--artist-featured">
                {artistsFeaturedDisplayName.substring(0, 50)}{" "}
                {artistsFeaturedDisplayName.length > 50 && "... "}{" "}
              </div>
            </div>

            <div className="single-track--buttons">
              <div
                onClick={() => this.handlePlayButton()}
                className="single-track--btn single-track--play-btn"
              >
                {isPlaying && isCurrentTrackPlaying ? (
                  <FontAwesomeIcon
                    icon={faPauseCircle}
                    style={{ color: selectedCurrency.color }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    style={{ color: selectedCurrency.color }}
                  />
                )}
              </div>
              {!isPurchased &&
                (!source ||
                  (source &&
                    source != "download-queue" &&
                    source != "my-library")) && (
                  <div className="single-track--btn">
                    <FontAwesomeIcon
                      onClick={this.handleDQ.bind(this)}
                      icon={isInDownloadQueue ? faCheckCircle : faPlusCircle}
                      style={{ color: selectedCurrency.color }}
                    />
                  </div>
                )}
              {source && source == "download-queue" && (
                <div className="single-track--btn">
                  <FontAwesomeIcon
                    onClick={this.handleRemoveDQ.bind(this)}
                    icon={faTrashAlt}
                    style={{ color: selectedCurrency.color }}
                  />
                </div>
              )}
              <div
                className="single-track--btn"
                onClick={() => this.buyTrack()}
              >
                <FontAwesomeIcon
                  icon={
                    isPurchased || (source && source == "my-library")
                      ? faArrowCircleDown
                      : faCartArrowDown
                  }
                  style={{ color: selectedCurrency.color }}
                />
              </div>
            </div>
          </div>

          <div className="single-track--waveform-wrapper">
            <div className="single-track--waveform-main">
              <div className="single-track--aditional-data">
                <div>BPM: {startBpm}</div>
                {typeof inKey != "undefined" && (
                  <div>
                    KEY: {inKey.camelotKey}/{inKey.musicKey}
                  </div>
                )}
                <div>Year: {releaseYear}</div>
                <div>Length: {this.toMMSS(trackLength)}</div>
              </div>

              <div
                className="track-waveform-json"
                ref={(waveform) => {
                  this.waveformContainer = waveform;
                }}
              >
                {(isJson && isWaveformDownloaded) || isPng ? (
                  <div className="single-track">
                    {isCurrentTrackPlaying && isTrackLoading && (
                      <div className="waveform--loading-wrapper">
                        Loading...
                      </div>
                    )}
                    {isPng && (
                      <div className="single-track--png-image">
                        <img src={waveformImageSnippetFileUrl} />
                      </div>
                    )}
                    <WaveformReact
                      barWidth={1}
                      height={30}
                      duration={100}
                      pos={isCurrentTrackPlaying ? this.props.trackProgress : 0}
                      color="#676767"
                      progressColor={this.props.selectedCurrency.color}
                      peaks={
                        isJson
                          ? normalizeSamples(waveformDownloadedData)
                          : peaks
                      }
                      gap={isJson}
                      onClick={this.handleProgress.bind(this)}
                    />
                  </div>
                ) : (
                  <Loader height="40" />
                )}
                {false && (
                  <canvas
                    ref={(waveform) => {
                      this.waveformCanvas = waveform;
                    }}
                    width="800"
                    height="60"
                  />
                )}
              </div>

              <div className="advanced-data--group advanced-data--tags">
                {cleanDirty && (
                  <div
                    className={
                      cleanDirty?.name === "Dirty" ? "tag-dirty" : "tag-clean"
                    }
                  >
                    {cleanDirty?.name}
                  </div>
                )}
                {versionType && <div>{versionType?.name}</div>}
                {introType && <div>{introType?.name}</div>}
                {outroType && <div>{outroType?.name}</div>}
              </div>
            </div>
          </div>
        </div>

        {isDetailedView && (
          <div className="single-track--advanced-data-wrapper">
            {typeof description != "undefined" && description.length > 0 && (
              <div className="advanced-data--first-row advanced-data--description">
                {description}
              </div>
            )}

            <div className="advanced-data--second-row single-track--advanced-data">
              {typeof tracksInSameReleases != "undefined" &&
                tracksInSameReleases.length > 0 && (
                  <div className="thirds advanced-data--group advanced-data--tracks-included">
                    <div className="single-track--data-head">
                      Tracks Included:
                    </div>
                    <div>
                      {typeof tracksInSameReleases != "undefined" &&
                        tracksInSameReleases.map((track, index) => {
                          const isCurrentTrackPlaying =
                            this.props.activeTrack != null &&
                            this.props.activeTrack._id === track._id;

                          return (
                            <div
                              key={index}
                              className={
                                isCurrentTrackPlaying
                                  ? "single-track--included-track is-playing"
                                  : "single-track--included-track"
                              }
                            >
                              <div className="included-track--buttons">
                                <div
                                  onClick={() => this.handlePlayButton(track)}
                                >
                                  {isPlaying && isCurrentTrackPlaying ? (
                                    <FontAwesomeIcon icon={faPauseCircle} />
                                  ) : (
                                    <FontAwesomeIcon icon={faPlayCircle} />
                                  )}
                                </div>
                                <div
                                  className=""
                                  onClick={() => this.buyTrack(track._id)}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      isPurchased ||
                                      (source && source == "my-library")
                                        ? faArrowCircleDown
                                        : faCartArrowDown
                                    }
                                  />
                                </div>
                              </div>
                              {track.formattedName}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

              <div className="thirds advanced-data--group advanced-data--genres">
                <div className="single-track--data-head">Genres:</div>
                {typeof genres != "undefined" &&
                  genres.length &&
                  genres.map((genre, index) => {
                    return (
                      <div className="single-track--genre" key={index}>
                        {genre?.name}
                      </div>
                    );
                  })}
              </div>

              <div className="thirds advanced-data--group advanced-data--original-works">
                <div className="single-track--data-head">
                  Original Works Used:
                </div>
                {originalWorks.map((ow, index) => (
                  <div key={index}>{ow.displayName}</div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.playerReducer,
  ...state.globalReducer,
  ...state.currencyReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTrack: (trackId) => dispatch(actions.setActiveTrack(trackId)),
  setTrackProgress: (trackId, trackProgress) =>
    dispatch(actions.setTrackProgress(trackProgress, trackId, "waveform")),
  togglePlay: () => dispatch(actions.togglePlay()),
  buyTrack: (currencyId, trackId, trackData, autoDownload, confirm) =>
    dispatch(
      actions.buyTrack(currencyId, trackId, trackData, autoDownload, confirm)
    ),
  addToDownloadQueue: (currencyId, trackId) =>
    dispatch(actions.addToDownloadQueue(currencyId, trackId)),
  removeFromDownloadQueue: (currencyId, trackId) =>
    dispatch(actions.removeFromDownloadQueue(currencyId, trackId)),

  getAllTags: () => dispatch(actions.getAllTags),
  getAllKeys: () => dispatch(actions.getAllKeys),
  getAllGenres: () => dispatch(actions.getAllGenres),
});

export default connect(mapStateToProps, mapDispatchToProps)(Track);
