import React from 'react';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export default function ToBeEditedDrafts({drafts, playTrack, editTrack, askDeleteTrack, isLoading}){
    return (
        <div>
            {!isLoading ?  <div className="editor-dash--drafts-list editor-dash--list-wrapper"> 
            <div className="editor-dash--drafts-header editor-dash--drafts-row">
                <div className="drafts--header-cell">Track filename</div>
                <div className="drafts--header-cell">Track title</div>
                <div className="drafts--header-cell">Actions</div>
            </div>
            {drafts.map((draft,index) => {
                return <div key={index} className="editor-dash--single-draft editor-dash--drafts-row">
                        <div className="single-draft--cell">{draft.originalFileName}</div>
                        <div className="single-draft--cell">{draft.name}</div>
                        <div className="single-draft--cell">
                            {!draft.draftTrackUploadedToS3 ? <div className="draft-processing">Processing...</div> :
                            <div className="editor-dash--buttons">
                                <FontAwesomeIcon icon={faPlayCircle} onClick={() => {playTrack(draft)}} />
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => {editTrack(draft)}} />
                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => {askDeleteTrack(draft._id)}} />

                            </div>}
                        </div>
                </div>
            })}
            </div> : <div className="editor-dash--loader-wrapper"><Loader color={"#2969b3"} /></div> }
        </div>
    )
}