import React, { Component } from 'react';


import qs from 'qs'

import {connect} from 'react-redux';

import * as actions from '../actions/index';

import Loader from 'react-loader-spinner';
import Logo from '../images/cc-logo.png';

import { withRouter, Link } from 'react-router-dom'

import axios from 'axios';


import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';



import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";


const  mapDispatchToProps = dispatch => ({
    handleLogin: (data) => dispatch(actions.login(data)),
    checkAccount: (data) => dispatch(actions.checkAccount(data))
})

class setPassword extends Component {
    constructor(props) {
        super(props); 
        this.state = { 
            email: "",
            password: "",
            repeatPassword: '',
            resetRequested: false,
            isLoading: false,
            updateValidationError: '',
        }
    }

    validateForm() {
        return this.state.password.length > 0 && this.state.repeatPassword.length;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }


    handleCheck(){
        this.props.checkAccount();

    }

    componentDidUpdate(prevProps){
        const {history} = this.props;
        
        if(this.props.loggedIn && this.props.loggedIn != prevProps.loggedIn){
            setTimeout(() => {
                history.push('/')
            })
        }
    }

    updatePassword(event){

        event.preventDefault();

        this.setState({
            updateValidationError: ''
        })

        let url = this.props.location.search;

        if(!url){
            return this.setState({
                updateValidationError: 'url parameters are not present',
            })
        }

        url = url.substr(1);

        const params = qs.parse(url);

        const newData = {
            password: this.state.password,
            repeatPassword: this.state.repeatPassword,
            ...params
        }


        if(!newData.password || !newData.repeatPassword){
            this.setState({
                updateValidationError: 'please enter your password in both fields'
            })
            return false;
        }

        if(newData.password.length < 6){
            return this.setState({
                updateValidationError: 'The password should be at least 6 characters'
            })
        }

        this.setState({
            isLoading: true,
            resetRequested: false,
        })

 
        console.log('form data is', newData)

        axios({
            withCredentials: true,
            data: newData,
            method: "POST",
            url: rootApiUrl + urls.submitNewPassword,
        }).then(response => {
            console.log('request complete', response);
            this.setState({
                isLoading: false,
                resetRequested: true,
            })
        }).catch(error => {
            console.log(error);
            
            this.setState({
                isLoading: false,
                resetRequested: false
            })
            if(error.response && error.response.data && error.response.data.error){
                this.setState({
                    updateValidationError: error.response.data.error,
                })
            } else {
                this.setState({
                    updateValidationError: 'there was a problem with your request',

                })
            }
        })

    }
    
    render() { 
        const {isLoading, resetRequested, updateValidationError} = this.state;

        const isLoggedIn = this.props.loggedIn
        const loginTitle = isLoggedIn ? "You are logged in" : "Set your new password";
        
        return ( 
     
            <section className="app--center-wrapper login-wrapper">
                <img className="login-page--logo" src={Logo} />
                
                <h3 className="login--title">{loginTitle}</h3>


                {!isLoading && !resetRequested && <form className="bs-login-form set-password-form" onSubmit=
                {this.updatePassword.bind(this)}>

                <p>Please enter your password twice.</p>


                    <FormGroup controlId="password" >
                        {/* <FormLabel>Email</FormLabel> */}
                        <FormControl 
                            autoFocus
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder='your new password'
                        />
                    </FormGroup>

                    <FormGroup controlId="repeatPassword" >
                        {/* <FormLabel>Email</FormLabel> */}
                        <FormControl 
                            autoFocus
                            type="password"
                            value={this.state.repeatPassword}
                            onChange={this.handleChange}
                            placeholder='repeat the password'
                        />
                    </FormGroup>


                    <Button
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                    >Submit</Button>

                    <p>{updateValidationError}</p>
                </form>}

                
                
                {resetRequested && <p>Your request has been completed.</p>}
              
                {false && this.state.resetRequested && !isLoading &&  (isLoggedIn ? <div>Success</div> : <div>There was a problem signin in</div>)}
                {isLoading && <Loader />}
                {this.props.login && <button onClick={this.handleCheck.bind(this)}>Random call</button>}
               
            </section>

         );
    }
}

export default connect(state => ({...state.userReducer}), mapDispatchToProps)(withRouter(setPassword))