import React, { Component} from 'react';
import {connect} from 'react-redux';

import {Link, withRouter} from 'react-router-dom';

import axios from 'axios';
import * as actions from '../actions/index';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';
import { Button } from "react-bootstrap";

import AVLogo from '../images/cc-av.png';
import VVLogo from '../images/cc-vv.png';
import i12logo from '../images/cc-i12.png';

import Dropzone from '../components/general/trackDropzone';
import FormData from 'form-data';
import {NotificationManager} from 'react-notifications';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ToBeEditedDrafts from '../components/uploader/toBeEditedDrafts';
import RejectedFiles from '../components/uploader/rejectedFiles';
import PreparedFiles from '../components/uploader/preparedFiles';
import EditTrackModal from '../components/uploader/editTrackModal';
import CreateReleaseModal from '../components/uploader/createReleaseModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBan} from '@fortawesome/free-solid-svg-icons'


const TRACK_FIELDS = {
    name: ['length'],
    version: ['length'],
    artistText: ['length'],
    // gigTypes: ['length'],
    genres: ['length'],
    cleanDirty: ['length'],
    versionType: ['length'],
    introType: ['length'],
    outroType: ['length'],
    releaseYear: ['length'],
    startBpm: ['length', 'greaterThanZero', 'threeCharacterMaximum'],
    endBpm: ['threeCharacterMaximum'],
    originalWorks: ['length'],
    draftFlaggedAsDuplicate: ['isFalse'],
    isReleaseModalOpen: false,
    newReleaseName: '',
    releaseNameChecked: false,
    releaseNameValidity: false,
    creatingReleaseLoading: false,
};

class ProducerContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            section:'poor-tracks',
            selectedCurrency: '573acd030424de743256b6e8', 
            type: 'submited',
            isUploading:false,
            drafts: [],
            selectedTrack:{},
            rejectedFiles: [],
            preparedFiles: [],
            isSampleUploading:false,
            markedTracksForPublishing: [],
            markedReleasesForPublishing: [],
            markedNewTracksForPublishing: [],
            releases: {},
            allReleaseTracksIds: [],
            originalWorksSuggestions: [],
            showOriginalWorksSuggestions: false,
            uploadProgress: 0,
            searchResultsFromCurrentSession: [],
            showResultsFromCurrentState: false,
            currencyDetails: {},
        }

        this.trackValidationRequirements = TRACK_FIELDS;
        this.startUploadSample = this.startUploadSample.bind(this);
        this.submitModifications.bind(this)
    }

    openModal(theTrack) {
        this.setState({
            modalIsOpen: true,
            selectedTrack: theTrack
        })
    }
    
    closeModal() {
        this.setState({modalIsOpen: false});
    }

    getAllReleases(){
        this.setState({isLoading:true})

        axios({
            method:"GET",
            url:rootApiUrl + urls.editors.getAllReleases,
            withCredentials:true,
            params:{
                currencyId: this.state.selectedCurrency
            }
        }).then(({data}) => {
            if(data.Releases){
                let allReleaseTracksIds = [];
                data.Releases.map(release => {
                    return release.tracks && release.tracks.length && Promise.all(release.tracks.map(track => {
                        allReleaseTracksIds.push(track._id);
                    }))
                })
               
                this.setState({
                    allReleases: data.Releases,
                    allReleaseTracksIds,
                    isLoading:false
                })
            }
        }).catch(error => {
            NotificationManager.error('There was an error while updating the data');
            this.setState({isLoading:false})

        })
    }
    
    getDrafts(currencyId){
        this.setState({isLoading:true})

        axios({
            method:"GET",
            url:rootApiUrl + urls.editors.viewDrafts(currencyId),
            withCredentials:true,
        }).then(response => {

            let allTracks = response.data.Tracks ? response.data.Tracks.results : [];
            let validTrackIndexes;
            if(allTracks.length){
                allTracks.sort((a,b) => {
                    var keyA = new Date(a.createdAt);
                    var keyB = new Date (b.createdAt);
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                })

                allTracks = allTracks.filter(track => {
                     return track.crooklynClanv1AutoMigrated !== true;
                    
                })

                validTrackIndexes = this.validateTracks(allTracks);
            } else {
            }
            // console.log("valid track indexes are", validTrackIndexes)

            if(allTracks.length){
                
                let tracksReadyToPublish = allTracks.filter((item, index) => {
                    return validTrackIndexes.indexOf(index) !== -1;
                });

                // console.log('list of tracks ready to publish', tracksReadyToPublish);

                let tracksNotReadyToPublish = allTracks.filter((item, index) => {
                    return validTrackIndexes.indexOf(index) === -1;
                });

                // new releases
                let newReleases = {};

                tracksNotReadyToPublish.map((track, index) => {
                    tracksNotReadyToPublish[index].validation = {isTrackValid: false};

                    //if track is still processsing, periodically check the status
                    if(!track.draftTrackUploadedToS3){
                        this.getTrackStatus(track._id);
                    }

                })

                tracksReadyToPublish.map((track, index) => {
                    tracksReadyToPublish[index].validation = {isTrackValid: true}

                    //if the track is part of a release, than put it separately
                    if(track.releases && track.releases.length){
                        // debugger;
                        const theRelease = track.releases[0];
                        if(typeof newReleases[theRelease] === 'undefined'){
                            //if the release is not yet part of the list
                            newReleases[theRelease]= {tracks:[]};
                            newReleases[theRelease]['tracks'].push(track._id);

                            //get more details about each release
                            // const dummyData = {x:'y', a:'b'};
                            // setTimeout(() => {
                            //     newReleases[theRelease] = {
                            //         ...newReleases[theRelease],
                            //         ...dummyData
                            //     }
                            // })
                        } else {
                            newReleases[theRelease]['tracks'].push(track._id);
                        }
                    }
                    
                })

                // console.log('list of tracks not ready to publish', tracksNotReadyToPublish);

                this.setState({
                    isLoading:false,
                    drafts: tracksNotReadyToPublish,
                    preparedFiles: tracksReadyToPublish,
                });

            } else {
                this.setState({
                    isLoading: false,
                    drafts: [],
                    preparedFiles: [],
                })
            }


        }).catch(error => {
            console.error('error ocurred', error);  
            this.setState({
                isLoading: false,
            })         
        })
    }

    getTrackStatus(trackId){
        
        setTimeout(() => {
          
            axios({
                withCredentials:true,
                url:rootApiUrl + urls.tracks.getTrackById(trackId),
            }).then(({data}) => {
                if(data.Track){
                    const newTrackData = data.Track;
                    if(newTrackData.draftTrackUploadedToS3){   
                        //get existing tracks
                        let existingTracks = this.state.drafts;
                        existingTracks.map((track,index) => {
                            if(track._id === trackId){
                                existingTracks[index] = newTrackData;
                                this.setState({
                                    drafts:existingTracks,
                                }) 
                            }
                        })

                    } else {
                        this.getTrackStatus(newTrackData._id);
                    }
                }
            });
            
            
        }, 5000);
    }

    validateTracks(tracks){
        let validTrackIndexes = [];
        for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];
            track.validations = {};
            let validTrack = true;
            for (let property in tracks[i]) {
                
              if (this.trackValidationRequirements.hasOwnProperty(property)) {
                track.validations[property] =  this.validateTrackField(property, tracks[i][property], i);
                if(!this.validateTrackField(property, tracks[i][property], i)){
                    validTrack = false;
                }
              }
              //check if all fields are valid
            }
            // console.log(`track validations are for ${track.name}`, track.validations);
            if(validTrack){
                validTrackIndexes.push(i);
            }
            // console.log(` is track ${track.name} valid?`, validTrack )

          }
        return validTrackIndexes;
    }

    validateTrackField(fieldName, fieldValue, trackIndex) {
        let validationResults = { isValid: true, tests: [] };
    
        if (this.trackValidationRequirements[fieldName] && this.trackValidationRequirements[fieldName].length > 0) {
          for (let i = 0; i < this.trackValidationRequirements[fieldName].length; i++) {
            switch (this.trackValidationRequirements[fieldName][i]) {
              case 'length':
                {
                  validationResults.tests.push({
                    title: 'Is Length Greater Than Zero',
                    isValid: typeof fieldValue !== 'undefined' && fieldValue && typeof fieldValue !== null && fieldValue.length !== 0,
                    description: 'This field cannot be blank.'
                  });
                }
                break;
              case 'greaterThanZero':
                {
                  validationResults.tests.push({
                    title: 'Is Value Greater Than Zero',
                    isValid: typeof fieldValue !== 'undefined' && fieldValue && parseInt(fieldValue, 10) > 0,
                    description: 'This field must be greater than zero.'
                  });
                }
                break;
              case 'threeCharacterMaximum':
                {
                  validationResults.tests.push({
                    title: 'Is value no more than three digits long?',
                    isValid: typeof fieldValue !== 'undefined' && fieldValue && fieldValue.toString().length <= 3,
                    description: 'This field must be no more than three digits.'
                  });
                }
                break;
              case 'isFalse':
                {
                  validationResults.tests.push({
                    title: 'Is the value false?',
                    isValid: fieldValue === false,
                    description: 'This field must be false.'
                  });
                }
                break;
              default: {
                break;
              }
            }
          }
    
          for (let i = 0; i < validationResults.tests.length; i++) {
            validationResults.isValid = validationResults.isValid && validationResults.tests[i].isValid;
          }

        //   console.log(`the final validation results for track ${trackIndex} are `, validationResults)
        return validationResults.isValid;
    
        //   let validation = this.trackList.results[trackIndex].validation || {};
        //   this.trackList.results[trackIndex].validation = Object.assign({ isTrackValid: false, fields: {} }, validation);
        //   this.trackList.results[trackIndex].validation.fields[fieldName] = validationResults;
        }
      }
    
    
    setSection(section){
        this.setState({
            section: section
        })
    }

    playTrack(track){
        this.props.setActiveTrack(typeof track._id != 'undefined' ? track : {...track});
    }

    editTrack(track){
        this.openModal(track)
    }

    submitDeleteTrack(trackId){
        const {selectedCurrency} = this.state;

        axios({
            withCredentials:true,
            method:"GET",
            url: rootApiUrl + urls.editors.deleteTrack(trackId),
        }).then(response => {
            this.getDrafts(selectedCurrency)
        }).catch(error => {
            NotificationManager.error("There was a problem while trying to delete the track");

        })
    }

    askDeleteTrack(trackId){
            confirmAlert({
                title: 'Confirm delete',
                message: 'Are you sure to do delete the track?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        console.log('confirm deleting track', {trackId});
                        this.submitDeleteTrack(trackId)
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        console.log('Not deleting track', {trackId});

                    }
                  }
                ]
              });
            
            return false;            
    }

    askDeleteRelease(release){
     
            confirmAlert({
                title: 'Confirm delete',
                message: 'Are you sure to do delete the release?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        console.log('confirm deleting track', {release});
                        this.submitDeleteRelease(release)
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        console.log('Not deleting track', {release});

                    }
                  }
                ]
              });
            
            return false;    
    }

    unlinkReleasesFromTrack(file, callback){
        file.releases = [];
        file.gigTypes = [];
        file.isTrackValid = true;
        file.validation = {
            isTrackValid: true,
        }
        this.submitModifications(file, callback);
    }

    askDeleteReleaseTrack(file){
        console.log('deleting release track', file);

        confirmAlert({
            title: 'Confirm delete',
            message: 'Are you sure you want to delete this track from the release?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    console.log('confirm deleting track', {file});
                    // this.submitDeleteRelease(file)
                    this.unlinkReleasesFromTrack(file);

                }
              },
              {
                label: 'No',
                onClick: () => {
                    console.log('Not deleting track', {file});

                }
              }
            ]
          });
        
        return false;   
    };

    submitDeleteRelease(release){
        console.log('delete release', release);

        //first update every track from the release, so that it does not link to any release
        return Promise.all(release.tracks.map( async (track,index) => {
            return new Promise(resolve =>  {
                this.unlinkReleasesFromTrack(track, resolve);
            })
        })).then(() => {
            console.log('all tracks have ben unlinked');

            axios({
                url:rootApiUrl + urls.editors.deleteRelease(release._id),
                withCredentials:true,
            }).then(({data}) => {
                if(data && data.success){
                    NotificationManager.success("Release was deleted successfully");
                    this.refreshTrackData();
                } else {
                    NotificationManager.error("An error occurred while trying to remove the release");
    
                }
            })
        });

        
    }

    startUpload(file, binaryStr){
        const {selectedCurrency} = this.state;

        let formData = new FormData();
        this.setState({isUploading:true})

        formData.append( 'file', file);    

        this.setState({uploadProgress: 0})

        axios({
            method:"POST",
            withCredentials:true, 
            url: rootApiUrl + urls.editors.createPreview(selectedCurrency),
            data:formData,
            headers: { 'Content-Type': 'multipart/form-data' }, 
            onUploadProgress: (progressEvent) => {
                let uploadProgress = Math.round((progressEvent.loaded * 100) /progressEvent.total);
                console.log('loading progress',  uploadProgress)
                this.setState({uploadProgress});
            }
        }).then(response => {
            NotificationManager.success("Track uploaded successfully");
            this.getDrafts(selectedCurrency);
            this.setState({isUploading:false})

        }).catch((error) => {
            NotificationManager.error("There was a problem while uploading the track");
            this.setState({isUploading:false})
    
        });
    }

    startUploadSample(file, trackId){
        const {selectedCurrency} = this.state;

        let formData = new FormData();
        formData.append('sampleTrack_upload', file);    

        console.log('updating sample snippet', trackId,  file);
        this.setState({
            isSampleUploading:true,
        })

        //if isTrackValid is not found

        // if(typeof formData.validation == 'undefined'){
        //     return NotificationManager.error("There was a problem with track data validation. Please refresh page or contact an administrators");

        // }

        axios({
            method: "POST",
            withCredentials: true,
            url: rootApiUrl + urls.editors.updateTrack(trackId),
            data:formData,
            headers: { 'Content-Type': 'multipart/form-data' } 
        }).then(response => {
            NotificationManager.success("Snippet uploaded successfully");

            let newSnippetFile = response.data && response.data.Track && response.data.Track.customDraftSnippetFile;
            let newTrackValues = {...this.state.selectedTrack};
            newTrackValues.customDraftSnippetFile = newSnippetFile;

            this.setState({isSampleUploading: false, selectedTrack: newTrackValues})
        }).catch(error => {
            NotificationManager.error("There was a problem while uploading the snippet");
            this.setState({isSampleUploading: false})
            
        })
    }



    rejectFileType(file){
        NotificationManager.info(file.reason);

        this.setState({
            rejectedFiles: [
                ...this.state.rejectedFiles,
                file
            ]
        })
    }

    getCurrencyDetails(selectedCurrency){
        let currencyDetails = {};

        if(this.props.currencies && this.props.currencies.length){
            currencyDetails = this.props.currencies.filter(currency => {
                return currency._id === selectedCurrency
            })
        }

        this.setState({
            currencyDetails: currencyDetails[0] || {}
        })
    }

    changeCurrency(selectedCurrency){

        //get the currency details;

        this.setState({
            selectedCurrency: selectedCurrency,
        }, () => {
            this.refreshTrackData();
            this.getCurrencyDetails(selectedCurrency)

        })
    
    }

    changeType(type){
        this.setState({type})
    }

    changeTrackValue(field, value){
        let newTrackValues = {...this.state.selectedTrack};
        newTrackValues[field] = value;

        this.setState({
            selectedTrack : newTrackValues,
        })
    }

    submitModifications(track, callback){
        let selectedTrack = (typeof track != 'undefined' ? track : this.state.selectedTrack);

        this.closeModal();

        this.setState({isLoading:true});


        //selectedTrack.validation
        

        if(selectedTrack.isOriginal){
            if(selectedTrack.originalWorks.length < 1){
                let originalWork = {
                    name: selectedTrack.name,
                    version: selectedTrack.version,
                    artists: selectedTrack.artistText,
                    artistsFeatured: selectedTrack.artistsFeaturedText,
                    isOriginalWorkEntry: true,
                }
                selectedTrack.originalWorks.push(originalWork);
            }
        }

        if(typeof selectedTrack.validation === 'undefined'){
            return NotificationManager.error("There was a problem with track data validation. Please refresh page or contact an administrators");
        }

        axios({
            url: rootApiUrl + urls.editors.updatePreview(),
            method: "POST",
            withCredentials: true,
            data: selectedTrack,
        }).then((response) => {
            console.log('track was updated')
            NotificationManager.info('Track updated successfuly');
            this.refreshTrackData()
            this.setState({selectedTrack: {}});
            if(callback){
                callback();
            }
        }).catch(() => {
            NotificationManager.error('Track could not be updated')
            this.setState({isLoading:false})
        })
    }

    refreshTrackData(){
        const {selectedCurrency} = this.state;

        this.getDrafts(selectedCurrency)
        this.getAllReleases();
    }

    setExplicitRating(value){
        this.changeTrackValue('cleanDirty', value);
    }

    setVersion(version){
        this.changeTrackValue('versionType', version);
    }

    setIntro(value){
        this.changeTrackValue('introType', value);
    }

    setOutro(value){
        this.changeTrackValue('outroType', value);
    }

    setGenres(genre){
        const max = 3;

        let newTrackValues = {...this.state.selectedTrack};
        
            const theIndex = newTrackValues.genres.indexOf(genre);
            if(theIndex === -1){
                //if index is -1 it means that element is not in the list
                if(newTrackValues.genres.length < max){
                    // console.log('element not found and pushing', genre)
                    newTrackValues.genres.push(genre);
                }
            } else {
                // console.log('element found and remvoing', genre)
                newTrackValues.genres.splice(theIndex,1);
            }
            this.setState({
                selectedTrack: newTrackValues,
            })
        

        this.setState({
            selectedTrack: newTrackValues,
        })

    }

    togglePlaySnippet(snippet){
        console.log('playing snippet', snippet);

        snippet = {
            publishedLowBitRateFile: {
                ...snippet
            },
            ...snippet,
            fileType: 'snippet',
            editorPrimaryName: 'snippet',
            name: 'snippet',
            artistPrimaryName: 'snippet',
            version: 'snippet',
        }
    
        return this.props.setActiveTrack(snippet);

    }

    deleteSnippet(snippet){
        let newTrackValues = {...this.state.selectedTrack};

        newTrackValues.customDraftSnippetFile = "remove";

        this.setState({
            selectedTrack: newTrackValues,
        });
    }

    toggleIsOriginal(){
        let {selectedTrack} = this.state;
        selectedTrack.isOriginal = !selectedTrack.isOriginal;
        this.setState({
            selectedTrack
        })
    }

    toggleForPublishing(file){

        const {_id, publishDate} = file;

        let alreadyMarked;
        alreadyMarked = this.state.markedTracksForPublishing;
        let alreadyMarkedNewTracks = JSON.parse(JSON.stringify(this.state.markedNewTracksForPublishing));
        const theIndex = alreadyMarked.indexOf(_id);
        const theNewIndex = alreadyMarkedNewTracks.indexOf(_id);

        
        debugger;
        if(theIndex !== -1){
            //if the track is alrady marked;
    
            alreadyMarked.splice(theIndex,1);
            alreadyMarkedNewTracks.splice(theNewIndex,1);
        } else {
            //if the track is not marked for publishing
            alreadyMarked.push(_id); 
            if(typeof publishDate === 'undefined'){
                debugger;
                alreadyMarkedNewTracks.push(_id);
            }

        }

        this.setState({markedTracksForPublishing: alreadyMarked, markedNewTracksForPublishing: alreadyMarkedNewTracks})
    }

    toggleReleaseForPublishing(releaseId){
        let alreadyMarked = this.state.markedReleasesForPublishing;
        const theIndex = alreadyMarked.indexOf(releaseId)
        if(theIndex !== -1){
            //if the track is alrady marked;
            alreadyMarked.splice(theIndex,1);
            
        } else {
            //if the track is not marked for publishing
            alreadyMarked.push(releaseId); 
        }
        this.setState({markedReleasesForPublishing: alreadyMarked})
    }

    submitSelected(){
        const theSelectedTracks = this.state.markedTracksForPublishing;
        const theSelectedReleases = this.state.markedReleasesForPublishing;


        const finalArrayTracks = theSelectedTracks.map(track =>  {return {_id:track}})
        let finalArrayReleases = theSelectedReleases.map(release =>  {
            //iterate over all stored releases, and return the full one, that has the matching id
            const storedReleases = this.state.allReleases;
            const searchedRelease = storedReleases.map(savedRelease => {
                if(savedRelease._id === release){
                    return savedRelease
                }
            })
            return searchedRelease[0]
        });
        const data = {
            releases: finalArrayReleases,
            tracks: finalArrayTracks,
        }
        
        axios({
            method:"POST",
            url: rootApiUrl + urls.editors.publishTracks(),
            data: data,
            withCredentials: true,
        }).then(response => {
            if(response.data && response.data.status == 'published'){
                NotificationManager.success("The content was submitted successfuly");
                this.refreshTrackData();

            } else {
                NotificationManager.error("We could not mark the content for publishing, an error ocurred");
            }

        }).catch(error => {
            NotificationManager.error("We could not mark the content for publishing, an error ocurred");

        })

    }

    getOriginalWorkSuggestion(searchParams){

        this.setState({
            showOriginalWorksSuggestions: false,
        })
        //ajax call, do original works suggestion
        axios({
            withCredentials: true,
            url: rootApiUrl + urls.editors.getOriginalWorks,
            params: searchParams
        })
        .then(({data}) => {
            if(typeof data.OriginalWorks != 'undefined'){
                this.setState({
                    originalWorksSuggestions: data.OriginalWorks.results,
                    showOriginalWorksSuggestions:true,
                })
            }   
         
        })
        .catch((error) => {

        })
    }

    removeOriginalWork(index){

        let newTrackValues = {...this.state.selectedTrack};
        let newOriginalWorks = this.state.selectedTrack.originalWorks;
        newOriginalWorks.splice(index,1);
        newTrackValues.originalWorks = newOriginalWorks;
        this.setState({selectedTrack:newTrackValues})
    }

    addOriginalWork(originalWork){
        let newTrackValues = {...this.state.selectedTrack};

        let newOriginalWorks = this.state.selectedTrack.originalWorks;
        if(!Array.isArray(newOriginalWorks)){
            newOriginalWorks = [];
        }

        newOriginalWorks.push(originalWork);

        newTrackValues.originalWorks = newOriginalWorks;
        this.setState({selectedTrack:newTrackValues, showOriginalWorksSuggestions: false});
    }

    createRelease(){
        const theSelected = this.state.markedNewTracksForPublishing;

        //create release ajax call
        this.setState({isReleaseModalOpen: true});
    }

    changeNewReleaseName(e){
        const newReleaseName = e.target.value;
        this.setState({newReleaseName})

        this.setState({
            releaseNameChecked: false,
            releaseNameValidity: false,
        })

        if(newReleaseName === ''){
            return false;
        }

        axios({
            method:"POST",
            url:rootApiUrl + urls.editors.checkReleaseName(newReleaseName),
            withCredentials:true,
            data:{releaseName:newReleaseName}
        })
        .then(response => {
            const data = response.data;
            if(data.Release && data.Release._id != 'undefined'){
                // a release has been found
                this.setState({
                    releaseNameChecked: true,
                    releaseNameValidity: false,
                })
            } else {
                // release not found 
                this.setState({
                    releaseNameChecked: true,
                    releaseNameValidity: true,
                })
            }   
        })
        .catch(error => {
            this.setState({
                releaseNameChecked: false,
                releaseNameValidity: false,
            })
        })
    }

    submitCreateRelease(){
        this.closeReleaseModal();
        this.setState({isLoading:true})

        const data = {
            currency: this.state.selectedCurrency,
            mediaType: (this.state.selectedCurrency == "573acd030424de743256b6e9" ? 'video' : 'audio'), //if the selectedCurrency is Video Vault, then mark the media as video
            name: this.state.newReleaseName,
        };

        axios({
            withCredentials: true,
            url: rootApiUrl + urls.editors.createRelease(),
            data,
            method: "POST"
        })
        .then(({data}) => {
            NotificationManager.success("Release created successfully");
            
            this.closeReleaseModal();
            this.setState({isLoading:false, newReleaseName: ''})

            //step1: grab the release id
            const releaseId = data.Release._id;

            return Promise.all(this.state.markedNewTracksForPublishing.map(async (trackId) => {
                //search for appropiate track
               return Promise.all(this.state.preparedFiles.map( async (searchedFile) => {
                    //if the track id matches
                    
                    if(searchedFile._id === trackId){
                        let {releases} = searchedFile;
                        releases.push(releaseId); 

                        searchedFile.releases = releases;

                        return new Promise(resolve => {
                            this.submitModifications(searchedFile, resolve);
                        })

                    }
                })).then(() => {
                   this.setState({markedTracksForPublishing: [], markedNewTracksForPublishing: []})
                })
            }))

            //step2: get all the selected tracks and update them with the new release id,
        })
        .catch(error => {
            NotificationManager.error("There was a problem while trying to create the release");
            this.setState({isLoading:false})

        })        
    }

    closeReleaseModal(){
        this.setState({isReleaseModalOpen: false});
    }

    copyFromDataFromSomeOtherTrack(newTrackData){

        let {name, version, artistText, artistsFeaturedText, description, genres, versionType, introType, outroType, cleanDirty, isOriginal, originalWorks} = newTrackData;

        const newObject = {name, version, artistText, artistsFeaturedText, description, genres, versionType, introType, outroType, cleanDirty, isOriginal, originalWorks}

        this.setState({
            showResultsFromCurrentState: false,
            selectedTrack: {
                ...this.state.selectedTrack,
                ...newObject,   
            }
        });
    }

    searchTracksCurrentSession(trackName){
        let searchResults = this.state.preparedFiles.filter(track => {
            if(track.name.toLowerCase().indexOf(trackName.toLowerCase()) !== -1  ){
               
                return track;
            }
        })
        this.setState({searchResultsFromCurrentSession: searchResults, showResultsFromCurrentState: true})

    }

    componentDidMount(){
        // this.getDrafts(this.state.selectedCurrency)
        this.props.getAllTags();
        this.props.getAllGenres();
        this.props.getAllKeys();
        // this.getAllReleases();
        this.getCurrencyDetails(this.state.selectedCurrency);

        const editorCurrencies = this.props.userData.currencies;

        if(editorCurrencies.indexOf(this.state.selectedCurrency) == -1){
            //if the user has any currencies;
            if(editorCurrencies.length > 0){
                this.changeCurrency(editorCurrencies[0])
            }
        } else {
            this.refreshTrackData();
        };
    }

    render(){
        const {section, selectedCurrency, drafts, isLoading, isUploading, modalIsOpen, rejectedFiles, preparedFiles, type, selectedTrack, isSampleUploading, markedTracksForPublishing, markedNewTracksForPublishing, newReleaseName, isReleaseModalOpen, releaseNameChecked, releaseNameValidity, allReleases, allReleaseTracksIds, markedReleasesForPublishing, originalWorksSuggestions, uploadProgress, onlyNewTracks} = this.state;
        const {currencies, userData, globals} = this.props;
        const editorCurrencies = userData.currencies;

        return (
            <section className="app--center-wrapper editor-dashboard-wrapper">
                <h2 className="editor-dashboard--title">Editor dashboard - Uploader</h2>

                <section className="editor-dashboard-section">
                    <div className="editor-dash--currency-selector">
                        {currencies && currencies.length > 0 && currencies.map((currency,index) => {
                            const isSelected = currency._id == selectedCurrency

                            const disabled = editorCurrencies.indexOf(currency._id) == -1;

                            let image;
                            switch(currency._id){
                                case '573acd030424de743256b6e8':
                                    image = AVLogo;
                                    break;
                                case '573acdea0424de743256b6e9':
                                    image = VVLogo;
                                    break;
                                case '573acfcf0424de743256b6ec':
                                    image = i12logo;
                                    break;
                                default:
                                    image = i12logo;
                                    break;
                            }

                            return <button key={index} disabled={disabled} onClick={() => this.changeCurrency(currencies[index]._id)} className={"editor-dash--currency-btn " + (isSelected ? "selected " : 'not-selected ') + (disabled ? 'disabled ' : ' ') }>
                                {disabled && <span className="banned-icon">
                                    <FontAwesomeIcon icon={faBan}  />
                                </span>}
                                <div className="currency-switch-btn-img">
                                    <img src={image} />
                                </div>
                                
                            </button>
                        })}
                    </div>

                    <div className="editor-dash--dropzone">
                        <Dropzone
                            selectedCurrency={selectedCurrency}
                            startUpload={this.startUpload.bind(this)}
                            isUploading={isUploading}
                            rejectFileType={this.rejectFileType.bind(this)}
                            uploadProgress={uploadProgress}
                        />
                    </div>

                    <div className="editor-dash--type-selector">
                        <button className={"editor-dash--type " + (type === 'rejected' ? 'selected' : '') } onClick={() => {this.changeType('rejected')}}>Rejected {<div className="dash-type-count">({rejectedFiles.length })</div>}</button >
                        <button className={"editor-dash--type  " + (type === 'submited' ? 'selected' : '') } onClick={() => this.changeType('submited')}>Needs Editing {drafts.length && <div className="dash-type-count">({drafts.length})</div>}</button >
                        <button className={"editor-dash--type  " + (type === 'prepared' ? 'selected' : '') } onClick={() => this.changeType('prepared')} >Publish tracks & zip  {<div className="dash-type-count">({preparedFiles.length})</div>} </button >
                    </div>

                    {type === 'submited' && <ToBeEditedDrafts drafts={drafts} playTrack={this.playTrack.bind(this)} editTrack={this.editTrack.bind(this)} askDeleteTrack={this.askDeleteTrack.bind(this)} isLoading={isLoading} />}
                    {type === 'rejected' && <RejectedFiles files={rejectedFiles}  />}
                    {type === 'prepared' && <PreparedFiles m files={preparedFiles} playTrack={this.playTrack.bind(this)} editTrack={this.editTrack.bind(this)} askDeleteTrack={this.askDeleteTrack.bind(this)} selectedTracks={markedTracksForPublishing} selectedNewTracks={markedNewTracksForPublishing} selectedReleases={markedReleasesForPublishing} toggleForPublishing={this.toggleForPublishing.bind(this)} toggleReleaseForPublishing={this.toggleReleaseForPublishing.bind(this)} submitSelected={this.submitSelected.bind(this)} createRelease={this.createRelease.bind(this)} releases={allReleases} allReleaseTracksIds={allReleaseTracksIds} askDeleteRelease={this.askDeleteRelease.bind(this)} askDeleteReleaseTrack={this.askDeleteReleaseTrack.bind(this)} isLoading={isLoading}  />}

                </section>

                <div className="editor-dashboard-footer">
                    <Link to="My-editor-page" >My Editor Page</Link>
                </div>

                <EditTrackModal 
                    trackData={selectedTrack} 
                    modalIsOpen={modalIsOpen} 
                    closeModal={this.closeModal.bind(this)} 
                    changeTrackValue={this.changeTrackValue.bind(this)} 
                    submitModifications={() => this.submitModifications()}
                    playSnippet={this.togglePlaySnippet.bind(this)}
                    deleteSnippet={this.deleteSnippet.bind(this)}
                    functions={{
                        setIntro: this.setIntro.bind(this),
                        setOutro: this.setOutro.bind(this),
                        setGenres: this.setGenres.bind(this),
                        setVersion: this.setVersion.bind(this),
                        setExplicitRating: this.setExplicitRating.bind(this)
                    }}
                    startSampleUpload={(file) => this.startUploadSample(file, selectedTrack._id) }
                    isSampleUploading={isSampleUploading}
                    rejectFileType={this.rejectFileType.bind(this)}
                    removeOriginalWork={this.removeOriginalWork.bind(this)}
                    addOriginalWork={this.addOriginalWork.bind(this)}
                    toggleIsOriginal={this.toggleIsOriginal.bind(this)}
                    allowOriginals={false}
                    getOriginalWorksSuggestions={this.getOriginalWorkSuggestion.bind(this)}
                    originalWorksSuggestions={originalWorksSuggestions}
                    showOriginalWorksSuggestions={this.state.showOriginalWorksSuggestions}
                    searchTracksCurrentSession={this.searchTracksCurrentSession.bind(this)}
                    searchResultsFromCurrentSession={this.state.searchResultsFromCurrentSession}
                    showResultsFromCurrentState={this.state.showResultsFromCurrentState}
                    copyFromDataFromSomeOtherTrack={this.copyFromDataFromSomeOtherTrack.bind(this)}
                    closeResultsFromCurrentState={() => this.setState({showResultsFromCurrentState: false}) }
                    currencyDetails={this.state.currencyDetails}
                    selectedCurrency={selectedCurrency}
                />

                <CreateReleaseModal 
                    isOpen={isReleaseModalOpen}
                    createRelease={this.submitCreateRelease.bind(this)}
                    newReleaseName={newReleaseName}
                    closeModal={this.closeReleaseModal.bind(this)}
                    changeReleaseName={this.changeNewReleaseName.bind(this)}
                    releaseNameChecked={releaseNameChecked}
                    releaseNameValidity={releaseNameValidity}
                />

            </section>
        )
    }
}

const mapStateToProps = state => ({
    globals: state.globalReducer,
    currencies: state.currencyReducer.currencies && state.currencyReducer.currencies.Currencies,
})

const mapDispatchToProps = dispatch => ({
    setActiveTrack: (trackId) => dispatch(actions.setActiveTrack(trackId)),
    setTrackProgress: (trackId, trackProgress) => dispatch(actions.setTrackProgress(trackProgress, trackId, "waveform")),
    togglePlay: () => dispatch(actions.togglePlay()),
    getAllTags: () =>  dispatch(actions.getAllTags()),
    getAllKeys: () =>  dispatch(actions.getAllKeys()),
    getAllGenres: () =>  dispatch(actions.getAllGenres()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProducerContainer)) 