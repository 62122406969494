import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';
import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMusic, faCoins, faCheckCircle, faCheck} from '@fortawesome/free-solid-svg-icons'


class AllPackContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            packs: [],
            isShown: true,
            sortField: 'updatedAt',
            sortTimeFrame: 'allTimeBuys',
            sortOrder: -1,
            showing: 20,
            total: 0
          }
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedCurrency && prevProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== prevProps.selectedCurrency._id){
            console.log('currency has changed');
            this.getPacks();
        }
    }


    showMore(){
        this.setState({
            showing: this.state.showing + 20,
        })
    }

    getPacks(config){
        const {getParams, selectedCurrency} = this.props;
        const {sortOrder, sortTimeFrame, sortField} = this.state;

        this.setState({
            isLoading: true,
        })

        if(config){
            if(!config.page){
                config.page = 0;
            }
        }

        const theUrl = rootApiUrl + urls.collections.listCollections(selectedCurrency._id);


        let modifiedSortField = sortField;

        if(modifiedSortField === "top-movers"){
            modifiedSortField = sortTimeFrame;
        }

        const params = {
            rows: 100,
            sortField: modifiedSortField,
            sortOrder: sortOrder,
        }

        if(getParams){
            for(let key in getParams){
                params[key] = getParams[key];
            }
        }

        axios({
            withCredentials: true,
            url: theUrl,
            params: params,
        }).then(response => {
        
            if(response.data && response.data.Collections && response.data.Collections.results){
                this.setState({
                    isLoading: false, 
                    packs: response.data.Collections.results,
                    total: response.data.Collections.total,
                })
            }
        }).catch(error => {
            console.error(error)
        })

        }


    setSortMechanism(e){
        this.setState({
            sortField: e.target.value,
            showing: 20,
        }, () => {
            this.getPacks();
        })
    
    }

    setSortTimeFrame(e){
        this.setState({
            sortTimeFrame: e.target.value,
            showing: 20
        }, () => {
            this.getPacks();
        })
    }


    componentDidMount(){
        this.getPacks();
    }

    render() { 
        const {isLoading, packs, isShown, sortField, showing, total } = this.state;

        const {title, selectedCurrency} = this.props;

        const purchasedPacks = typeof selectedCurrency != 'undefined' && selectedCurrency.purchasedPacks;

        return ( 
        <section className="app--center-wrapper all-packs--main-wrapper">
           
          
                <div className="all-packs--main-content">
                    <div className="packs--page-intro">
                        <h3>All packs</h3>
                        <p>ALL DJ's know what a pack is. Our Editors offer up the BEST IN THE BIZ! Make your choice if you can :)</p>
                    </div>

                    {title && <div className="sidebar-packs--title sidebar--title">{title}
                    <span onClick={this.toggleShow.bind(this)}>toggle</span>                    
                    </div>}
                    <div className="packs--dropdown-wrapper">
                        <div className=""> 
                            <label className="packs--label">Sort by:</label>
                            <select onChange={this.setSortMechanism.bind(this)} className="packs--select " defaultValue={sortField}>
                                <option value="updatedAt">New releases</option>
                                <option value="top-movers">Top movers</option>
                            </select>
                        </div>
                        <div className=""> 
                            <label className="packs--label">Time frame:</label>
                            <select onChange={this.setSortTimeFrame.bind(this)} className="packs--select " defaultValue={sortField}>
                                <option value="allTimeBuys">All-time</option>
                                <option value="last30DaysDownloadCount">Last 30 days</option>
                            </select>
                        </div>
                    </div>

                    {isLoading ? <Loader /> :  <div className="all-packs--list"> 
                        {isShown && packs.map((pack, index) => {
                            const isPurchased = purchasedPacks.indexOf(pack._id) !== -1;
                            const imgSrc = (pack.squareImage && pack.squareImage.url) || (pack.editorOwner.logoSquare.url);
                            
                        if(index < showing) return (
                            <Link key={index} to={"/packs/"+pack._id} >
                                <div key={index} className={isPurchased ? "all-packs--single-pack is-purchased" : "all-packs--single-pack"}>
                                    <div className="single-pack--first-row">
                                        <div className="single-pack--image">
                                            <img src={imgSrc} />
                                            <div className="single-pack--nrs sidebar-pack--nrs">
                                                <div className="sidebar-pack--length"><FontAwesomeIcon className="single-pack--icon" icon={faMusic} />{pack.tracks.length}</div>
                                                {isPurchased ? <div className="single-pack--is-purchased"><FontAwesomeIcon className="sidebar-pack--icon" icon={faCheck} /></div> : 
                                                <div className="sidebar-pack--cost"><FontAwesomeIcon className="single-pack--icon" icon={faCoins} />{pack.credits}</div>}
                                                
                                            </div>
                                        </div>
                                        <div className="single-pack--info ">
                                            <div className="single-pack--editor-name">{pack.editorOwner.stageName}</div>
                                            <div className="single-pack--name">{pack.name}</div>
                                            <div className="single-pack--description">{pack.description.substring(0,50)} {pack.description.length > 50 && "... (click for full description)"} </div>
                                         
                                        </div>
                                    </div>
                                  
                                </div>
                            </Link>)

                        })}

                        {showing <= total &&  <div className="all-packs--show-more-wrapper">
                            <button onClick={this.showMore.bind(this)}>Show more</button>
                        </div>}
                    </div> }

                    {isShown && <Link to={"/packs"}>
                    
                    </Link>}
                </div>  
                
        </section> );
    }
}
const mapStateToProps = state => ({...state.currencyReducer})
 
export default connect(mapStateToProps, {})(AllPackContainer);