import React, { Component } from 'react';
import {connect} from 'react-redux';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPauseCircle, faPlayCircle, faArrowCircleDown, faCartArrowDown, faTimesCircle} from '@fortawesome/free-solid-svg-icons'


import * as actions from '../../actions/index';

class PlayedTracks extends Component {
    constructor(props) {
        super(props);
        this.state = {

          }
    }

    handlePlayButton(trackData){

        const {_id} = false;


        console.log('playing track', trackData);

        const isCurrentTrackPlaying = typeof trackData != 'undefined' && typeof this.props.activeTrack != 'undefined' ? (this.props.activeTrack._id === trackData._id) : false;

        if(!isCurrentTrackPlaying){
            this.props.setActiveTrack(typeof trackData != 'undefined' ? trackData : false);
        } else {
            this.props.togglePlay();
        }
    
    }

    buyTrack(trackData){
    }


    render() { 
        const {isVisible, playedTracks} = this.props;
    
        return (
        <div className="played-tracks--outer-wrapper">
        {isVisible && <div className="played-tracks--wrapper">
           <section >

                <div className="played-tracks--top-line">
                    <div className="played-tracks--close" onClick={this.props.close}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                    <div className="played-tracks--title">Played tracks</div>
                </div>
                
                <div className="played-tracks--list">
                {typeof playedTracks != 'undefined' && playedTracks.map((track,index) => {
                    
                    let isCurrentTrackPlaying =  (this.props.activeTrack != null && this.props.activeTrack._id === track._id);
                    let isPlaying = this.props.isPlaying;
                    let isPurchased = false;
                    let source = false;

                    return <div key={index} className={isCurrentTrackPlaying ? "single-track--included-track is-playing" : "single-track--included-track"}>
                    <div className="included-track--buttons" >
                        <div onClick={() => this.handlePlayButton(track)}>{isPlaying && isCurrentTrackPlaying ? <FontAwesomeIcon icon={faPauseCircle} /> :
                        <FontAwesomeIcon icon={faPlayCircle} /> }</div>
      
                         
                    </div>
                    {track.formattedName}
                    </div>

                })}
                </div>
            </section> 
        </div> }
        </div>
        );
    }
}

const mapStateToProps = state => ({...state.playerReducer})


const mapDispatchToProps = dispatch => ({
    checkAccount: () => dispatch(actions.checkAccount()),
    setActiveTrack: (trackId) => dispatch(actions.setActiveTrack(trackId)),
    togglePlay: () => dispatch(actions.togglePlay()),
  })

 
export default connect(mapStateToProps, mapDispatchToProps)(PlayedTracks);