import React from 'react';

export default function RejectedFiles({files}){
    return (
        <div>
            <div className="editor-dash--rejected-list editor-dash--list-wrapper">
                <div className="single-rejection-row single-rejection-header">
                    <div className="single-rejection-cell single-rejection-header-cell">Rejected file</div> 
                    <div className="single-rejection-cell single-rejection-header-cell">Rejected reason</div>  
                </div>
                {files.map((file,index) => {
                    return (
                    <div key={index} className="single-rejection-row r">
                        <div className="single-rejection-cell">{file.path}</div>
                        <div className="single-rejection-cell">{file.reason}</div>
                    </div>) 
                })}
            </div>
        </div>

        
    )
}