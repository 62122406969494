import React, { Component } from 'react';
import {connect} from 'react-redux';


import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';


class AllGenresContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            genres: [],
            sort: "last-month",
            nameFilter: '',
         }
    }

    getGenres(config){
        const {getParams, selectedCurrency} = this.props;

        if(config){
            if(!config.page){
                config.page = 0;
            }
        }

        const theUrl = rootApiUrl + urls.genres.getGenres(selectedCurrency._id);
        const params = {
            
        }

        if(getParams){
            for(let key in getParams){
                params[key] = getParams[key];
            }
        }

        this.setState({
            isLoading: true,
        })

        axios({
            withCredentials: true,
            url: theUrl,
            params: params,
        }).then(response => {
            console.log(response);
            if(response.data && response.data.Genres){
                this.setState({
                    genres: response.data.Genres
                })
            }
            this.setState({
                isLoading: false,
            })
        }).catch(error => {
            console.error(error)
        })

    }

    componentDidMount(){
        this.getGenres();
    }

    setSortMechanism(e){
        console.log('setting sort mechanism', e.target.value);

        const {genres} = this.state;

        const mechanism = e.target.value;

        if(!genres.length){ return false }

        switch(mechanism){
            case "all-time": {
                const sortedGenres = [].concat(genres).sort(function(a, b) {
                    if (a.downloadCount > b.downloadCount) return -1;
                    if (a.downloadCount < b.downloadCount) return 1;
                    return 0;
                });

                // debugger;

                const newRankings = [];

                sortedGenres.map((genre, index) => {
                    let theGenre = genre;

                    // console.log(theGenre.genre.stageName)
                    
                    if(index > 10){
                        theGenre.genre.isVisible = false;
                    } else {
                        theGenre.genre.isVisible = true;
                    }

                    newRankings.push(theGenre)
                })

                // debugger;

                console.log(newRankings)
                this.setState({
                    genres: newRankings
                });

                return;

            }
            case "this-month": {
                const sortGenres = [].concat(genres).sort(function(a, b) {
                    if (a.currentMonthRanking < b.currentMonthRanking) return -1;
                    if (a.currentMonthRanking > b.currentMonthRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortGenres.map((editor, index) => {
                    let theEditor = editor;
                    
                    if(index > 10){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible =true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    editors: newRankings
                });
                return;

            }

            case "a-z":{

                const sortedGenres = [].concat(genres).sort(function(a, b) {
                    if (a.genre.name < b.genre.name) return -1;
                    if (a.genre.name > b.genre.name) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedGenres.map((genre, index) => {
                    let theEditor = genre;

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    genres: newRankings
                });
                return;



            }
      

        }


    }

    filterName(e){
        this.setState({
            nameFilter: e.target.value
        })
    }
    
    render() { 
        const {genres, isLoading, seeAll, sort, nameFilter} = this.state;


        return ( 
        <section className="app--center-wrapper all-genres--main-wrapper">
            <div className="all-genres--title">{this.props.title || "Genres"}</div>
            {isLoading ? <Loader /> : 
            <div className="all-genres--list">

                <div className="all-genres--filters">
                    {!isLoading && <div className="all-editors--name-filter">
                        <label className="sidebar--label">Filter by name: </label>
                        <input onChange={this.filterName.bind(this)} />
                    </div>}

                    {!isLoading && <div className="all-editors--sort"> 
                        <label className="sidebar--label">Sort by:</label>
                        <select onChange={this.setSortMechanism.bind(this)} className="sidebar--select sidebar-editors--sorting" defaultValue={sort}>
                        <option value="all-time">Popularity</option>
                        <option value="a-z">A-Z</option>
  
                    </select></div>}
                </div>

                {genres.map((genre, index) => 
                {
                    const genreData = genre.genre;

                    const isSearchActive = (typeof nameFilter != 'undefined' && nameFilter != '');
                    const isPartOfSearch = typeof genreData.name != 'undefined' && genreData.name.toLowerCase().includes(nameFilter.toLowerCase());

                    if(!isSearchActive || (isSearchActive && isPartOfSearch)) return (
                        <div className="all-genres--single-genre">
                            <Link to={`/genres/${genreData.name}`}><div>{genreData.name}</div></Link>
                        
                        </div>
                    )
                })}
            </div>}
        </section>  );
    }
}
 
const mapStateToProps = state => ({...state.currencyReducer})

 
export default connect(mapStateToProps, {})(AllGenresContainer);