import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';

import dateFormat from "dateformat";

class SingleTransaction extends Component {
    constructor(props){
        super(props);
        this.state = {
            showDetails: false,
            simpleView: true
        }

    }

    toggleDetails(){
        this.setState({
            showDetails: !this.state.showDetails,
        })
    }



    render(){
        const {transaction, index} = this.props;
        const {showDetails, simpleView} = this.state;

        return ( 
            <div key={index} className="single-transaction--wrapper">
                <div className="single-transaction--main-info"> 
                    <div className="single-transaction--main-details">
                        <div className="single-transaction--date"><span className="mobile-only">From - to: </span>{dateFormat(transaction.startTime, "mediumDate")} to {dateFormat(transaction.endTime, "mediumDate")}</div>
                    </div>
                   
                    <div className="single-transaction--name"><span className="mobile-only">Package: </span>{transaction.name}</div>
                    <div className="single-transaction--price"><span className="mobile-only">Cost: </span>${transaction.price.toFixed(2)}</div>
                    <div className="single-transaction--view-details" ><Link to={`/my-account/transactions/${transaction._id}`}>Details</Link></div>
                </div>
                {showDetails && <div className="single-transaction--detailed-list">
                    {transaction.transactionItems.map((transactionItem, index) => {
                       return (
                       <div className="single-transaction--transaction-item" key={index}>
                            <h4 className="transaction-item--name">{transactionItem.name}</h4>
                            <div className="transaction-item--details">
                                <div className="transaction-item--main-details">
                                    <div>Credits: {transactionItem.credits}</div>
                                    <div>Credits used: {transactionItem.creditsUsed}</div>    

                                </div>
                                <div className="transaction-item--purchases">
                                    <div>Purchases / Downloads (displaying maximum 10):</div>
                                    <div className="transaction-item--purchases-list">
                                    {transactionItem.purchases.map((purchase,index) => {
                                        const track = purchase.track;
                                        return (
                                        <div key={index}>
                                            {index+1}. {track.editorPrimaryName} - {track.name}
                                        </div>
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                       </div> )
                    })}
                </div>}
            </div>
        )
    }
}

export default withRouter(SingleTransaction)