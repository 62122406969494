import React, { Component } from 'react';
import {connect} from 'react-redux';

import Loader from 'react-loader-spinner';

import axios from 'axios';
import * as actions from '../../actions/index';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import { Button } from "react-bootstrap";


export default class PoorTracks extends Component {

    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            tracks: [],
            totalTracks: 0,
            totalPages: 0,
            tracksPerPage: 0,
            currentPage: 1,
        }
    }

    componentDidMount(){
        this.setState({
            isLoading: true,
        })

        axios({
            url: rootApiUrl + urls.getPoorTracks,
            withCredentials: true,
        }).then(response => {
            if(response.data && response.data.Tracks){
                this.setState({
                    tracks: response.data.Tracks.results,
                    isLoading: false,
                    totalTracks: response.data.Tracks.total,
                    totalPages: response.data.Tracks.totalPages,
                    currentPage: response.data.Tracks.currentPage,

                })
            } else {
                this.setState({isLoading: false})
            }

        })
    }

    loadMoreTracks(){
        const {isLoading} = this.state;

        if(isLoading){
            return false;
        }

        return axios({
            url: rootApiUrl + urls.getPoorTracks,
            withCredentials: true,
            params:{
                pageNumber: this.state.currentPage + 1,
            }
        }).then(response => {
            let {tracks} = this.state;

            if(response.data && response.data.Tracks){

                let allTracks = [...tracks, ...response.data.Tracks.results];


                this.setState({
                    tracks: allTracks,
                    isLoading: false,
                    totalTracks: response.data.Tracks.total,
                    totalPages: response.data.Tracks.totalPages,
                    currentPage: response.data.Tracks.currentPage,

                })
            } else {
                this.setState({isLoading: false})
            }

        })
    }

    render(){
        const {isLoading, tracks, totalTracks, totalPages, currentPage} = this.state;

        return isLoading ? <Loader /> : (
        <div className="poor-tracks--outer-wrapper">
            <h5>Tracks with no downloads in the last 90 days</h5>
            <div class="poor-tracks--general-info">
                <table>
                    <tbody>
                        <tr>
                            <th>Total # of tracks</th>
                            <td>{totalTracks}</td>
                        </tr>
                        <tr>
                            <th>Total # of pages</th>
                            <td>{totalPages}</td>
                        </tr>
                        <tr>
                            <th># Pages loaded</th>
                            <td>{currentPage}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="poor-tracks--table-outer-wrapper">
                <table className="poor-tracks--table">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Track</th>
                            <th>Action</th>
                        </tr>
                        {tracks.map((track,index) => {
                            return <tr key={index}>
                                <td>{index+1}</td>
                                <td>{track.formattedName}</td>
                                <td>Action</td>
                            </tr>
                        })}
                    </tbody>
                </table>

                 <Button onClick={this.loadMoreTracks.bind(this)}>Load more tracks</Button>       

            </div>


        </div>)
    }
}