import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import * as currencyActions from '../currency/currency';


export const login = (userData) => dispatch => {
    dispatch({
        type: 'LOGIN_REQUEST',
        payload: {userData} 
    })

    const url = rootApiUrl + urls.login;

    const newObject = {
        email: userData.username,
        password: userData.password,
    }

    axios({
        method: "POST",
        url: url,
        withCredentials: true,    
        data: JSON.stringify(newObject),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if(typeof response.data != 'undefined' && response.data.success){
            dispatch({
                type: 'LOGIN_ATTEMPT_SUCCESS',
                payload: response.data.profileData,
                success: response.data.success 
            })  
            
            dispatch(currencyActions.getCurrencies());
        } else {
            dispatch({
                type: 'LOGIN_ATTEMPT_FAILURE',
                payload: response.data 
            })  
        }
       
    }).catch((error) => {
        dispatch({
            type: 'LOGIN_ERROR',
            payload: {userData} 
        }) 
    })

}

export const logout = () => dispatch => {

    dispatch({
        type: 'LOGOUT_ATTEMPT',
    })

    let theUrl = rootApiUrl + urls.logout;

    axios({
        method: 'GET',
        withCredentials: true,
        url: theUrl,
    }).then((response) => {
        dispatch({
            type: 'LOGOUT_SUCCESS',
            //payload: {response.data} 
        })
    })

}

export const checkAccount = () => dispatch => {

    let theUrl = rootApiUrl + urls.account;

    dispatch({
        type: 'CHECK_ACCOUNT_BEGIN', 
    }) 

    axios({
        method: 'GET', 
        withCredentials: true,
        url: theUrl,
    }).then((response) => {
        if(Object.keys(response.data.User).length === 0){
            console.log('no user data');
            dispatch({
                type: 'LOGOUT_SUCCESS',
                //payload: {response.data} 
            })
             dispatch(currencyActions.getCurrencies());

        } else {
            dispatch({
                type: 'LOGIN_ATTEMPT_SUCCESS',
                payload: response.data.User,
                response: true,
            })
            dispatch(currencyActions.getCurrencies());

        } 
    })

}
     