import React, { Component } from 'react';

import Editors from './sidebar/editors';
import Packs from './sidebar/packs';
import Genres from './sidebar/genres';
import Crates from './sidebar/crates';

import SidebarAds from '../containers/top-ads/SideAdsContainer';

class SidebarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <aside className="sidebar--main-container">
            <Editors />
            <SidebarAds />
            
            <Packs title="Packs" />
            <Genres />
            <Crates />
        </aside> );
    }
}
 
export default SidebarContainer;