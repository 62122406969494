import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import {Router, Route, IndexRoute} from 'react-router';
import {createBrowserHistory} from 'history';
import {syncHistoryWithStore} from 'react-router-redux';
import {Provider} from 'react-redux';
import {store, persistor} from './stores/generalStore';

import { PersistGate } from 'redux-persist/integration/react';


import * as serviceWorker from './serviceWorker';


// This has to be changed. We need to sync the react router with redux, but that library is being deprecated.
//const history = syncHistoryWithStore(browserHistory, store);

//the history without redux sync
const history = createBrowserHistory({});


ReactDOM.render(
<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <Router history={history} >
            <App />
        </Router>
   </PersistGate>
</Provider>,  
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
