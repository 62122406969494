import React, { Component } from 'react';
import {connect} from 'react-redux';


import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';
import _ from 'underscore';



class SidebarGenres extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            genres: [],
            seeAll: false,
            isShown: true,
            sortOrder: "popularity",
         }
    }

    getGenres(config){
        const {getParams, selectedCurrency} = this.props;

        if(config){
            if(!config.page){
                config.page = 0;
            }
        }

        if(typeof selectedCurrency === 'undefined'){
            return false;
        }

        const theUrl = rootApiUrl + urls.genres.getGenres(selectedCurrency._id);
        const params = {
            
        }

        if(getParams){
            for(let key in getParams){
                params[key] = getParams[key];
            }
        }

        this.setState({
            isLoading: true,
        })

        axios({
            withCredentials: true,
            url: theUrl,
            params: params,
        }).then(response => {
            console.log(response);
            if(response.data && response.data.Genres){
                this.setState({
                    genres: response.data.Genres
                })
            }
            this.setState({
                isLoading: false,
            })
        }).catch(error => {
            console.error(error)
        })

    }

    componentDidMount(){
        const clientWidth = window.innerWidth;
        if(clientWidth < 901){
            this.setState({isShown: false})
        }


        this.getGenres();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedCurrency && prevProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== prevProps.selectedCurrency._id){
            console.log('currency has changed');
            this.getGenres();
        }
    }

    toggleShow(){
        this.setState({
            isShown: !this.state.isShown,
        })
    }

    sortGenres(sortMechanism){
        let genres = this.state.genres;

        if(sortMechanism === "popularity"){
            const newGenres = _.sortBy(genres, (e) => {return e.downloadCount}).reverse();
            this.setState({genres: newGenres})
        } else if (sortMechanism === "a-z"){
            const newGenres = _.sortBy(genres, (e) => {return e.genre.name});
            this.setState({genres: newGenres})
        } else if (sortMechanism === "z-a"){
            const newGenres = _.sortBy(genres, (e) => {return e.genre.name}).reverse();
            this.setState({genres: newGenres})
        }
        
       
    }

    setSortMechanism(e){
        this.setState({
            sortField: e.target.value
        }, (e) => {
            
        })
       
        this.sortGenres(e.target.value);
    }


    render() { 
        const {genres, isLoading, seeAll, isShown, sortField} = this.state;

        return ( 
            typeof this.props.selectedCurrency != 'undefined' ? <section className="sidebar-genres--main-wrapper">
            <div  style={{backgroundColor: this.props.selectedCurrency.color}}  className="sidebar-genres--title sidebar--title">
            {this.props.title || "Genres"}
            <span onClick={this.toggleShow.bind(this)}>toggle</span>                                
            </div>
            {isLoading ? <Loader /> : <div>
                {isShown && <div className="sidebar--dropdown">
                <label className="sidebar--label">Sort by:</label>
                <select onChange={this.setSortMechanism.bind(this)} className="sidebar--select " defaultValue={sortField}>
                    <option value="popularity">Popularity</option>
                    <option value="a-z">A-Z</option>
                    <option value="z-a">Z-A</option>
                </select></div>} 
                {isShown && genres.map((genre, index) => 
                {
                    const genreData = genre.genre;

                    if(seeAll || !seeAll && index < 10) return (
                        <div key={index} className="sidebar--single-genre">
                            <Link to={`/genres/${genreData.name}`}><div>{genreData.name}</div></Link>
                        </div>
                    )
                })}
                {genres.length > 1 && <Link to="/genres" className="">
                {true && <div className="sidebar--view-all">
                    View all
                </div>}
                </Link>}
            </div>}
        </section> : <section></section>);
    }
}

const mapStateToProps = state => ({...state.currencyReducer})

 
export default connect(mapStateToProps, {})(SidebarGenres);