import React, { Component } from 'react';
import {connect} from 'react-redux';

import axios from 'axios';
import * as actions from '../actions/index';

import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import {Button, FormGroup, FormControl, FormLabel} from 'react-bootstrap';

import SingleTransaction from '../components/general/singleTransaction';

import {CSVLink} from 'react-csv';
import dateFormat from "dateformat";

class MyAccountContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            transactions: [],
            isLoading: true,
            changePasswordLoading: false,
            newAccountData: {},
            updateValidationError: '',
            updatePasswordValidationError: '',
            updateResult: '',
            accountUpdated: false,
            transactionsCSVData:[],
        }
    }

    getTransactions(){
        this.setState({
            isLoading: true,
        })

        axios({
            withCredentials: true,
            url: rootApiUrl + urls.myTransactions,
        }).then(response => {
            if(response.data.Transactions && typeof response.data.Transactions.length != 'undefined'){
               
                this.setState({
                    isLoading: false,
                    transactions: response.data.Transactions,
                })

                let transactionsCSVData = [];

            
                response.data.Transactions.map(transaction => {
                    
                    const transactionDate = "" + dateFormat(transaction.startTime, "mediumDate") + " to " + dateFormat(transaction.endTime, "mediumDate");
                    transactionsCSVData.push([transactionDate, transaction.name, transaction.price.toFixed(2)])
                });

                this.setState({
                    transactionsCSVData: transactionsCSVData
                })
            }
        })



    }

    updateAccount(){

        const {userData} = this.props;
        const email = userData.email;

        this.setState({
            updateValidationError: ''
        })

        let newAccountData = Object.assign({}, this.state.newAccountData);

        newAccountData.email = email;

        if(typeof newAccountData.firstName != 'undefined' || typeof newAccountData.lastName != 'undefined'){

            if(typeof newAccountData.firstName == 'undefined'){
                newAccountData.firstName = userData.name.first
            }

            if(typeof newAccountData.lastName == 'undefined'){
                newAccountData.lastName = userData.name.last
            }

            if(!newAccountData.firstName || !newAccountData.lastName){
                this.setState({updateValidationError: 'Each name input should have at least 1 character'})
                return false;

            }

        }

        console.log('updating account', newAccountData);

        //check if nothing was updated
        if(Object.keys(newAccountData).length < 2){
            return this.setState({updateValidationError: 'there is nothing to update'})
        }
    
        this.setState({changePasswordLoading: true})

        delete newAccountData.password;
        delete newAccountData.repeatPassword;

        axios({
            withCredentials: true,
            data: newAccountData,
            method: "POST",
            url: rootApiUrl + urls.updateAccount,
        }).then(respose => {
            
            this.setState({
                accountUpdated: true,
            })

            setTimeout(() => this.props.checkAccount(), 2000)
        }).catch(error => {
            if(error){
                this.setState({

                })
            }
        })


    }

    updatePassword(){
        console.log('updating account password'); 

        this.setState({updatePasswordValidationError: ''})

        const {userData} = this.props;
        const email = userData.email;

        let newAccountData = {
            email: email,
            passwords: this.state.newAccountData,
        };


        console.log('new account data', newAccountData)

        if(!newAccountData.passwords.password || !newAccountData.passwords.repeatPassword){
            this.setState({updatePasswordValidationError: 'password field(s) cannot be empty'});
            return false;
        }

        if(newAccountData.passwords.password !== newAccountData.passwords.repeatPassword){
            this.setState({updatePasswordValidationError: 'password fields must match'});
            return false;
        }

        if(newAccountData.passwords.password.length < 6){
            this.setState({updatePasswordValidationError: 'password must be at least 6 characters'});
            return false;
        }

        this.setState({
            changePasswordLoading: true,
        })

        axios({
            withCredentials: true,
            data: newAccountData,
            method: "POST",
            url: rootApiUrl + urls.updateAccount,
        }).then(respose => {
           
            this.setState({
                accountUpdated: true,
            })

            setTimeout(() => this.props.checkAccount(), 2000)

        }).catch(error => {
            if(error){
                this.setState({

                })
            }
        })

    }

    handleChange = event => {

        this.setState({
            newAccountData: {
                ...this.state.newAccountData,
                [event.target.id]: event.target.value,
            }
        })
        
    }

    componentDidMount(){
        this.getTransactions();
    }
    
    render() { 
        const {transactions, isLoading, changePasswordLoading, updateValidationError, updatePasswordValidationError, accountUpdated, transactionsCSVData} = this.state;
        const {userData} = this.props;

        const csvData=[
            ["From - To", "Package", "Cost"],
            ...transactionsCSVData
        ];
    
        return (
            <section className="my-account--wrapper">
                <h2 className="my-account--title">My Account</h2> 

                <section className="my-account--section">
                    <div className="my-account--section-head">
                        <span>My Transactions</span>
                        
                        <CSVLink 
                        data={csvData} 
                        target="_blank"
                        filename={userData.name.first + "-" + userData.name.last + "-transactions-crooklyn-clan.csv"}
                         >
                            <Button>Download CSV</Button>
                        </CSVLink>
                    </div>
                    <div className="my-transactions--header single-transaction--wrapper">
                        <div className="single-transaction--main-info">
                            <div className="head">From - To</div>
                            <div className="head">Package</div>
                            <div className="head">Cost</div>
                            <div className="head">Details</div>
                        </div>  
             
                    </div>
                    {isLoading ? <div className="my-transactions--loading"><Loader /></div> : transactions.map((transaction,index) => {
                        return <SingleTransaction transaction={transaction} index={index} />
                    })}

                </section>

                <section className="my-account--section">
                    <div className="my-account--section-head">Update account info</div>
                    {accountUpdated ? <div>Account has been updated. Refreshing...</div> : changePasswordLoading ? <Loader /> : <div className="my-account--password-zone">
                        <div className="my-account--form-row">
                            <FormGroup  controlId="email">
                                <FormLabel>Email (used to login)</FormLabel>
                                <FormControl 
                                   
                                    type="text"
                                    onChange={this.handleChange}
                                    disabled
                                    value={userData.email}
                                >
                                </FormControl>
                            </FormGroup>
                        </div>
                        <div className="my-account--form-row">
                        <FormGroup controlId="firstName">
                                <FormLabel>First name</FormLabel>
                                <FormControl 
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={userData.name.first}
                                >
                                </FormControl>
                            </FormGroup>
                            <FormGroup  controlId="lastName">
                                <FormLabel>Last name</FormLabel>
                                <FormControl 
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={userData.name.last}
                                >
                                </FormControl>
                            </FormGroup>
                        </div>
                        <div className="my-account--form-row">
                            <div>{updateValidationError}</div>
                        </div>
                        <div className="my-account--form-row">
                            <Button onClick={this.updateAccount.bind(this)}>Update account</Button>
                        </div>
                        <div className="my-account--form-row">
                            <FormGroup controlId="password">
                                <FormLabel>New password</FormLabel>
                                <FormControl 
                                    type="password"
                                    onChange={this.handleChange}
                                >
                                </FormControl>
                            </FormGroup>
                            <FormGroup  controlId="repeatPassword">
                                <FormLabel>Repeat password</FormLabel>
                                <FormControl 
                                    type="password"
                                    onChange={this.handleChange}
                                >
                                </FormControl>
                            </FormGroup>
                        </div>
                        <div className="my-account--form-row">
                            <div>{updatePasswordValidationError}</div>
                        </div>
                        <div className="my-account--form-row">
                            <Button onClick={this.updatePassword.bind(this)}>Update password</Button>
                        </div>
                    </div>}
                </section>
            </section>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    checkAccount: () => dispatch(actions.checkAccount())
})

export default connect(state => ({...state.userReducer}),mapDispatchToProps)(MyAccountContainer);