import React, { Component } from 'react';



class TopAdsContainer extends Component {
    state = {  }
    render() { 
        return ( 
            <section className="top-ads--wrapper">
                  <iframe src="https://www.crooklynclan.net/" frameBorder="0" scrolling="no"></iframe>
            </section >
         );
    }
}
 


export default TopAdsContainer;