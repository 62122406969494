import React from 'react';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPencilAlt, faTrashAlt, faCheck, faListUl } from '@fortawesome/free-solid-svg-icons'


export default function PreparedFiles({files, newFiles, playTrack, editTrack, askDeleteTrack, selectedTracks, selectedNewTracks, selectedReleases, toggleForPublishing, toggleReleaseForPublishing, submitSelected, createRelease, releases, allReleaseTracksIds, askDeleteRelease, askDeleteReleaseTrack, isLoading}){
    return (
        <div>
        {isLoading ? <div className="editor-dash--loader-wrapper"><Loader color={"#2969b3"} /></div> : <div >
             <div className="editor-dash--prepared-list editor-dash--list-wrapper">
                <div className="single-rejection-row single-rejection-header">
                    <div className="single-rejection-cell single-rejection-header-cell">Prepared file</div> 
                    <div className="single-rejection-cell single-rejection-header-cell">Actions</div>  
                </div>
                <div className="prepared-list--actions-wrapper">
                    <div className="prepared-actions--left"></div>
                    <div className="prepared-actions--right">
                        <button className="prepared-tracks--btn" disabled={selectedTracks.length < 1 && selectedReleases.length < 1} onClick={submitSelected}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faCheck}/>
                            </div>
                            Submit selected
                        </button>
                        <button className="prepared-tracks--btn" disabled={selectedNewTracks.length < 1} onClick={createRelease}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faListUl}/>
                            </div>
                            Create release from selected</button>

                    </div>
                </div>
                {files.map((file,index) => {
                    const isPartOfARelease = allReleaseTracksIds.indexOf(file._id) !== -1;
                    const isPublished = file.publishDate;
                    // const isPartOfARelease = false;
                    return (
                    <div key={index} className={"single-rejection-row r " + (isPartOfARelease ? "hidden" : "") }>
                       
                        <div className={"single-rejection-cell "  }>
                            <input className="prepared-checkbox" type="checkbox" checked={selectedTracks.indexOf(file._id) !== -1} onChange={() => toggleForPublishing(file)} />
                    {file.name} {isPublished && <div className="editor-single-file--is-published">Published / In revision</div>}</div>
                        <div className="single-rejection-cell">
                        <div className="editor-dash--buttons">
                                <FontAwesomeIcon icon={faPlayCircle} onClick={() => {playTrack(file)}} />
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => {editTrack(file)}} />
                                {!isPublished && <FontAwesomeIcon icon={faTrashAlt} onClick={() => {askDeleteTrack(file._id)}} />}

                            </div>
                        </div>
                    </div>) 
                    })}
                </div>
                
                <div className="releases-list--wrapper">
                    {releases && releases.length > 0 && releases.map((release,index) => {
                        return <div key={index} className="single-release-wrapper">
                                <div className="single-release--header">
                                    <div className="single-release--title">
                                        <input className="prepared-checkbox" type="checkbox" checked={selectedReleases.indexOf(release._id) !== -1} onChange={() => toggleReleaseForPublishing(release._id)} />
                                        Release name: - {release.name}
                                        
                                    </div>
                                    <div className="single-release--delete">
                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => {askDeleteRelease(release)}} />
                                    </div>
                                </div>
                                <div className="release-tracks--list">
                                    {release.tracks && release.tracks.length && release.tracks.map((file,index) => {
                                        return (
                                            <div key={index} className="single-rejection-row r">
                                            
                                                <div className="single-rejection-cell">
                                                    
                                                    {file.name}</div>
                                                <div className="single-rejection-cell">
                                                <div className="editor-dash--buttons">
                                                        <FontAwesomeIcon icon={faPlayCircle} onClick={() => {playTrack(file)}} />
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => {askDeleteReleaseTrack(file)}} />
                        
                                                    </div>
                                                </div>
                                            </div>) 
                                    })}
                                </div>
                               
                            </div>
                    })}
                </div>
        </div>}
        </div>

    )
}