import React, { Component } from 'react';

import {Switch, Route} from 'react-router';
import ProtectedRoute from '../components/general/ProtectedRoute'
import EditorProtectedRoute from '../components/general/EditorProtectedRoute'


import Login from './loginContainer';
import Home from './homeContainer';
import Tracks from './tracksContainer';
import EditorContainer from './editorContainer';
import PackContainer from './packContainer';
import PackagesContainer from './packagesContainer';
import GenreContainer from './genreContainer';
import AllGenresContainer from './allGenresContainer';
import AllPacksContainer from './allPacksContainer';
import AllEditorsContainer from './allEditorContainer';
import MyAccount from './myAccountContainer';
import Register from './registerContainer';
import ResetPassword from './resetPasswordContainer';
import SetPassword from './setPasswordContainer';
import VerifyCustomer from './verifyCustomerContainer';
import Transaction from './transactionContainer';
import AdminC from './adminContainer';
import Producer from './producerContainer';
import EditorAdministrationContainer from './editorAdministrationPage';
import VersionContainer from './versionContainer';

class Main extends Component {

    render() { 
        return ( 
            <Switch>
                <ProtectedRoute exact path="/" component={Home} />
                <ProtectedRoute path="/tracks" component={Tracks} />
                <ProtectedRoute path="/editors/:id" component={EditorContainer} />
                <ProtectedRoute path="/packs/:id" component={PackContainer} />
                <ProtectedRoute path="/packages" component={PackagesContainer} />
                <ProtectedRoute path="/genres/:id" component={GenreContainer} />
                <ProtectedRoute path="/genres" component={AllGenresContainer} />
                <ProtectedRoute path="/packs" component={AllPacksContainer} />
                <ProtectedRoute path="/editors" component={AllEditorsContainer} />
                <ProtectedRoute path="/my-account/transactions/:id" component={Transaction} />
                <ProtectedRoute path="/my-account/transactions" component={MyAccount} />
                <ProtectedRoute path="/my-account" component={MyAccount} />
                <ProtectedRoute path="/admin" component={AdminC} />
                <EditorProtectedRoute path="/my-editor-page" component={EditorAdministrationContainer} />
                <Route path="/login/new-password" component={SetPassword} />
                <Route path="/login" component={Login} />
                <Route path="/register/customer" component={VerifyCustomer} />
                <Route path="/register" component={Register} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path="/version" component={VersionContainer} />
            </Switch>
        );
    }
}
 
export default Main; 
