import React, { Component } from 'react';

import Logo from '../images/cc-logo.png';


class FooterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <footer className="footer--main-wrapper">
                <div className="footer--left-section">
                    <div className="footer-row">
                        <a className="footer-menu-element">Home</a>
                        <a href="https://www.crooklynclan.net/membership-agreement" className="footer-menu-element">Membership agreement</a>
                        <a href="https://www.crooklynclan.net/terms-of-service" className="footer-menu-element">Terms of use</a>
                        <a href="https://www.crooklynclan.net/privacy-policy" className="footer-menu-element">Privacy policy</a>
                    </div>
                    <div className="footer-row">
                        <a href="https://www.crooklynclan.net/" className="footer-menu-element">About us</a>
                        <a href="https://www.crooklynclan.net/gear-shop" className="footer-menu-element">Gear shop</a>
                        <a href="https://www.crooklynclan.net/dmca" className="footer-menu-element">DMCA</a>
                        <a href="https://www.crooklynclan.net/" className="footer-menu-element">Contact us</a>
                    </div>
                </div>
                <div className="footer--right-section">
                    <div className="footer--logo">
                        <img src={Logo} />
                    </div>
                </div>
            </footer>
         );
    }
}
 
export default FooterContainer;