import React, { Component } from 'react';
import {connect} from 'react-redux';

import axios from 'axios';
import * as actions from '../actions/index';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import { Button } from "react-bootstrap";

import AgreementPage from '../components/general/agreementPage';


export default class EditorAdministrationPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            section:'poor-tracks',
           
        }
    }
    
    setSection(section){
        this.setState({
            section: section
        })
    }

    componentDidMount(){

    }

    render(){
        const {section} = this.state;

        return (
            <section className="app--center-wrapper agreement--outer-wrapper">
            
                <AgreementPage />
            </section>
        )
    }

}