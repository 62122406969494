import React, { PureComponent } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';


import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import {Button, FormGroup, FormControl, FormLabel} from 'react-bootstrap';

import {CSVLink} from 'react-csv';
import dateFormat from "dateformat";

class TransactionContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            simpleView: true,
            transactionData : {},
            totalDownloads: 0,
            downloadsCSVData:[],
            // allDownloads: [],
        }
    }

    componentDidMount(){

        let transactionId;
        if(typeof this.props.computedMatch != 'undefined'){
            const {params} = this.props.computedMatch;

            transactionId = params.id;

            const theUrl = rootApiUrl + urls.transactions.getSingleTransaction(transactionId);
            console.log(theUrl);

            return axios({
                url: theUrl,
                withCredentials: true,
            }).then(response => {
                if(response.data.transaction){

                    const transaction = response.data.transaction;

                    let allDownloads = [];

                    transaction.transactionItems.map(transactionItem => {
                        allDownloads = [...allDownloads, ...transactionItem.purchases]
                    })

                    this.setState({
                        transactionData: response.data.transaction,
                        isLoading: false,
                        allDownloads: allDownloads,
                    })
                }
            })

        }

    }

    render() { 

        const {isLoading, transactionData, simpleView, allDownloads} = this.state;

        const {userData} = this.props;

        const csvData=[
            ["Date", "Track", "Currency"],
            // ...transactionsCSVData
        ];

        allDownloads && allDownloads.map(download => {
                csvData.push([
                    download.accountedOn ?  download.accountedOn.split("T")[0] : "Undefined",
                    download.track != null ? download.track.formattedName : 'undefined',
                    download.currency != null ? download.currency.name : 'undefined', 
                ])

                return download
            // debugger;
            }
        )

        let transactionId;
        if(typeof this.props.computedMatch != 'undefined'){
            const {params} = this.props.computedMatch;
            transactionId = params.id; 
        }


        return ( 
            <div className="my-account--wrapper">
                <Link to="/my-account/transactions"> {"<-"} Back to transactions</Link>
                <h2 className="my-account--title">Transaction details</h2>
                
                {isLoading ? <Loader /> : <section className="my-account--section">
                    <div className="my-account--section-head">
                        <span>{transactionData.name}</span>
                        <CSVLink
                        data={csvData}
                        target="_blank"
                        filename={userData.name.first + "-" + userData.name.last + "-transactions-crooklyn-clan.csv"}

                        >
                            <Button>Download CSV</Button>    
                        </CSVLink>
                    </div>
                    <div className="transaction--general-details">
                        <div>Transaction date: {transactionData.createdAt && transactionData.createdAt.split("T")[0]}</div>
                        {allDownloads && <div>Total downloads for transaction: {allDownloads.length}</div>}
                        
                    </div>
                    <div></div>
                    {isLoading ? <Loader /> : (
                    simpleView ? <div className="simple-view">
                            <div className="simple-downloads--list">
                                <div className="simple-downloads--item-wrapper simple-downloads--head">
                                    <div className="head">Date</div>
                                    <div className="head">Track</div>
                                    <div className="head">Currency</div>
                                </div>
                               {allDownloads.length && allDownloads.map((download, index) => 
                                   {
                                    // debugger;
                                   return <div key={index}>
                                        <div className="simple-downloads--item-wrapper">
                                            <div>{download.accountedOn && download.accountedOn.split("T")[0]}</div>
                                            <div>{download.track && download.track.formattedName}</div>
                                            <div>{download.currency && download.currency.name}</div>
                                        </div>
                                   </div>
                                    }

                                   )} 
                            </div>
                        </div> : <div className="complex-view">
                        Transaction data
                        <div className="transaction-page--transaction-items-table">
                            <div className="transaction-items--head transaction-items--row">
                                <div>Transaction Name</div>
                                <div>Accounted on</div>
                                <div>Credits</div>
                                <div>Credits Used</div>
                                <div>Downloads</div>
                            </div>
                        </div>
                        {transactionData.transactionItems.map((transactionItem, index) => {
                            return <TransactionItem transactionItem={transactionItem} />
                        })}
                    </div>
                    )}
                </section>
                }
           </div>
         );
    }
}

class TransactionItem extends PureComponent{
    constructor(props){
        super(props);

        this.state = {
            viewDownloads: false,
        }
    }

    toggleDownloads(){
        this.setState({
            viewDownloads: !this.state.viewDownloads,
        })
    }

    render(){
        const {transactionItem} = this.props;
        const {viewDownloads} = this.state;

        return ( 
        <div className="single-transaction-item">
        <div className="transaction-items--row">
            <div>{transactionItem.name}</div>
            <div>{transactionItem.accountedOn &&  transactionItem.accountedOn.split("T")[0]}</div>
            <div>{transactionItem.credits}</div>
            <div>{transactionItem.creditsUsed}</div>
            <div onClick={this.toggleDownloads.bind(this)}>Downloads</div>
        </div>
        <div className="transaction-items--details">
            
            <div className="transaction-item--purchases">
                <div style={{display: 'none'}} >Purchases / Downloads (displaying maximum 10):</div>
                {viewDownloads && <div className="transaction-item--purchases-list">
                {transactionItem.purchases.map((purchase,index) => {
                    const track = purchase.track;
                    return (
                    <div key={index}>
                        {index+1}. {track.editorPrimaryName} - {track.name}
                    </div>
                    )
                })}
                </div>}
            </div>

        </div>
        </div>)
    }
}

const mapDispatchToProps = dispatch => ({
})
 
export default connect(state => ({...state.userReducer}),mapDispatchToProps)(withRouter(TransactionContainer));