import axios from 'axios';
import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

export const setActiveTrack = (track) => dispatch =>  {
    dispatch({
        type: "SET_ACTIVE_TRACK_SUCCESS",
        payload: track
    })

    if(track.editor){

        axios({
            withCredentials: true,
            url: rootApiUrl + urls.editors.getEditorById(track.editor)
        }).then(response => {
            if(response.data && response.data.Editor){
                track['editorData'] = response.data.Editor
            }

            dispatch({
                type: "UPDATE_ACTIVE_TRACK_DATA",
                payload: track
            })    
        }).catch(error => {
            console.error(error)
        })

    }
}



export const setVolume = (volume) => dispatch => {
    dispatch({
        type:"SET_VOLUME",
        volume
    })
}

export const togglePlay = () => dispatch => {
    dispatch({
        type: "TOGGLE_PLAY",
    })
}

export const pausePlayer = () => dispatch => {
    dispatch({
        type: "STOP_PLAY",
    })
}

export const setTrackProgress = (progress, trackId, changer) => dispatch => {
    dispatch({
        type: "SET_TRACK_PROGRESS",
        progress,
        trackId,
        changer
    })
}

export const setPreviewDuration = duration => dispatch => {
    dispatch({
        type: "SET_PREVIEW_DURATION",
        duration
    })
}

export const trackIsLoaded = duration => dispatch => {
    dispatch({
        type: "TRACK_IS_LOADED"
    })
}


