import React, { Component } from 'react';

import {Form, Field} from 'react-final-form';

import Loader from 'react-loader-spinner';

import axios from 'axios';

import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Logo from '../images/cc-logo.png';

import {Button, FormGroup, FormControl, FormLabel} from 'react-bootstrap';


class RegisterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isFormSubmitted: false,
            isLoading: false,
            responseMessage: 'fail',
            formValidationMessage: '',
            formDetails: {
                firstName: '',
                lastName: '',
                email: '',
            }
         }
    }

    handleChange = event => {

        this.setState({
            formDetails: {...this.state.formDetails, [event.target.id]: event.target.value}
        });
    }

    submitForm(formData){
        console.log('form data', formData);

        this.setState({
            isLoading: true,
            isFormSubmitted: true,
        })

        axios({
            withCredentials: true,
            data: formData,
            method: "POST",
            url: rootApiUrl + urls.register,
        }).then(response => {
            if(typeof response.data.status != 'undefined' && response.data.status == 'created'){
                this.setState({
                    responseMessage: "Please verify your email",
                    isLoading: false,
                    isFormSubmitted: true,
                })
            } else {
                this.setState({
                    responseMessage: response.data.detail,
                    isLoading: false,
                    isFormSubmitted: true,
                })``
            }
        }).catch(error => {

            if(error.response.data){
       
                let theError = "there was a problem registering your account";
                if(error.response.data.detail.err === 'user-found-with-email'){
                    theError = 'This email is in use';
                } else if(error.response.data.detail.err){
                    theError = error.response.data.detail.err;
                } 
  
                this.setState({   
                    responseMessage: theError,  
                    isLoading: false,
                    isFormSubmitted: true,
                })
            }  
        })
    }

    preValidateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    validate(e){
        
        console.log(e)
        e.preventDefault();

        this.setState({
            formValidationMessage: '',
        })

        // return false;

        let formData = this.state.formDetails;
        console.log('validating', formData);

        // return false;
        if(this.state.isFormSubmitted){
            return false;
        }

        let isEmailValid = true;

        if(formData.firstName.length < 1){
            return this.setState({
                formValidationMessage: 'Please complete all form fields'
            })
        }

        if(formData.lastName.length < 1){
            return this.setState({
                formValidationMessage: 'Please complete all form fields'
            })
        }

        if(formData.email.length < 1){
            return this.setState({
                formValidationMessage: 'Please complete all form fields'
            })

        }

        console.log('registration form data is ok');

        if(isEmailValid){
            return this.submitForm(formData);
        }

    }

    render() { 

        const {isFormSubmitted, isLoading, responseMessage, formValidationMessage} = this.state;

        return ( 
        <section className="register-page--wrapper app--center-wrapper">
          
            <div className="register-page--top">
                <img className="register-page--logo" src={Logo} />
                <h2 className="register--page-main-title">
                Register an account
                </h2>
                <h3 className="register--page-title">
                    Complete your account details
                </h3>
            </div>

            {isLoading ? <Loader /> : true && <div className="register--inner-wrapper">
                    
                    {isFormSubmitted ? <div className="register--response-message">{responseMessage}</div> :
                        <form className="register-form" >
                
                            <FormGroup controlId="firstName" >
                                <FormLabel>First name</FormLabel>
                                <FormControl
                                    type="text"
                                    onChange={this.handleChange}
                                    placeholder='John'
                                />

                            </FormGroup>

                            <FormGroup controlId="lastName" >
                                <FormLabel>Last name</FormLabel>
                                <FormControl 
                                    type="text"
                                    onChange={this.handleChange}
                                    placeholder='Doe '
                                />

                            </FormGroup>

                            <FormGroup controlId="email" >
                                <FormLabel>Email address</FormLabel>
                                <FormControl 
                                    type="email"
                                    onChange={this.handleChange}
                                    placeholder='john@doe.com'
                                />

                            </FormGroup>
                            
                            <Button
                                block
                                bsSize="large"
                                disabled={false}
                                type="submit"
                                onClick={this.validate.bind(this)}
                            >Register</Button>

                        </form>
                    } 
                    <div className="register-form--errors">{formValidationMessage}</div>


                </div>}
        </section>
         );
    }
}
 
export default RegisterContainer;