import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Link, withRouter} from 'react-router-dom';

import axios from 'axios';
import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';

import TracksContainer from '../containers/tracksContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMusic, faCoins} from '@fortawesome/free-solid-svg-icons'

import * as actions from '../actions/index';

import AVLogo from '../images/cc-av.png';
import VVLogo from '../images/cc-vv.png';
import i12logo from '../images/cc-i12.png';

import Modal from 'react-modal';

import {NotificationManager} from 'react-notifications';

class PackContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            packData: {},
            isModalOpen: false,
            isModalLibraryOpen: false,
        }
    }


    purchasePack(packData){
        console.log('purchasing pack');
        const selectedCurrency = this.props.selectedCurrency._id;

        this.setState({
            isModalOpen: false,
        })

        axios({
            withCredentials: true, 
            url: rootApiUrl + urls.collections.purchasePack(selectedCurrency),
            data: [packData],

            method: "POST",

        }).then(response => {
            
            // debugger;

            let packId;
            if(typeof this.props.computedMatch != "undefined"){
                const {params} = this.props.computedMatch;
    
                packId = params.id; 
            }

            if(response.data){
                if(response.data.err){
                    if(response.data.err === "insufficient-credits"){
                        NotificationManager.error("Not enough credits");

                    }
                } else {
    
                    this.getPackData(selectedCurrency, packId);

                    const {history} = this.props;

                    debugger;

                    this.setState({
                        isModalLibraryOpen: true,
                    })

                    if(response.data && response.data.creditsDeducted > 0){
                        
                    }
    
                }
            } 

        })
    }

    moveToLibrary(){
        const {history} = this.props;

        history.push('/')

        setTimeout(() => {
            this.props.visitLibrary();
        }, 500)

    }

    getPackData(selectedCurrency, packId){
        
        const theUrl = rootApiUrl + urls.collections.getPackById(selectedCurrency, packId);

        console.log(theUrl);

        return axios({
            url: theUrl,
            withCredentials: true,
        }).then(response => {
            if(response.data.Collection){
                this.setState({
                   
                    packData: response.data.Collection
                })

                const editorGetUrl = rootApiUrl + urls.editors.getEditorById(response.data.Collection.editorOwner);

            
                axios({
                    url: editorGetUrl,
                    withCredentials: true,
                }).then(response => {
                    this.setState({
                        isLoading: false,
                        editorData: response.data.Editor
                    })

                })
            }
        })
    }

    closeModal(){
        this.setState({
            isModalOpen: false,

        })

        // this.props.checkAccount();

    }

    openModal(){
        this.setState({
            isModalOpen: true,

        })

    }

    openLibraryModal(){
        this.setState({
            isModalLibraryOpen: true,
        })
    }

    closeLibraryModal(){
        this.setState({
            isModalLibraryOpen: false,
        })

        this.props.checkAccount();
    }


    componentDidMount(){
        const selectedCurrency = this.props.selectedCurrency._id;

        let packId;
        if(typeof this.props.computedMatch != "undefined"){
            const {params} = this.props.computedMatch;
 
            packId = params.id; 
        }

        this.getPackData(selectedCurrency, packId);
    }

    render() { 
        const {packData, editorData, isLoading} = this.state

        const {selectedCurrency} = this.props;

        const purchasedPacks = typeof selectedCurrency != 'undefined' && selectedCurrency.purchasedPacks;


        let packId = '';

        if(typeof this.props.computedMatch != "undefined"){
            const {params} = this.props.computedMatch;
 
            packId = params.id; 
        }

        const isPurchased = typeof purchasedPacks != 'undefined' && purchasedPacks.indexOf(packData._id) !== -1;

        return ( 
        <div className="app--center-wrapper pack-page--outer-wrapper">
            {isLoading ? <Loader /> : 
                
            <div className="pack-page--wrapper">
                <div className="pack-page--top">
            
                    <section className="pack-page--main-info">
                        <div className="pack-page--editor-name">
                            {editorData && editorData.stageName}
                        </div>
                        <div className="pack-page--pack-name">
                            {packData.name}
                        </div>
                        <div className="pack-page--description">
                            {packData.description}
                        </div>
                        <div className="pack-page--info single-pack-page--info">
                            <div className="single-pack--length"><FontAwesomeIcon className="single-pack--icon" icon={faMusic} />{packData.tracks.length}</div>
                            <div className="single-pack--credits"><FontAwesomeIcon className="single-pack--icon" icon={faCoins} />{packData.credits}</div>
                        </div>
                        {!isPurchased && <button className="pack-page--buy" onClick={() => this.openModal() }>Buy now</button>}
                    </section>
                    {editorData ? <div className="single-pack--editor-square-img">
                        <img src={editorData.photo.url} />
                    </div> : <Loader />}
                </div>
                <TracksContainer 
                source={"single-pack"}
                packId={packId}
                editorsDisabled={true}
                selectedEditors={typeof editorData != 'undefined' && [editorData._id]}
                // getParams={{
                //     // rows: 10
                // }} 
                smallAdsEnabled={false}
                sourceEnabled={false}
                />
            </div>
        }


                <Modal
                isOpen={this.state.isModalLibraryOpen}
                arhiaHideApp={false}
                className="modal--wrapper"
                >
                     {typeof selectedCurrency != 'undefined' && <div className="buy-dq--logo packages--selected-currency--logo modal-package-logo">
                        <img src={ selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo } />
                    </div>}
                    <div className="buy-dq--text">
                      Tracks have been added to your library
                    </div>
                    <div className="buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--btn" onClick={() => { this.closeLibraryModal();  this.moveToLibrary(); }}>Visit library</button>
                    </div>
                    <div className="modal-close buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--close" onClick={this.closeLibraryModal.bind(this)}>Close</button>
                    </div>
                </Modal>

                <Modal
                isOpen={this.state.isModalOpen}
                ariaHideApp={false}

                className="modal--wrapper"
                >
                    {typeof selectedCurrency != 'undefined' && <div className="buy-dq--logo packages--selected-currency--logo modal-package-logo">
                        <img src={ selectedCurrency.name == 'Audio Vault' ? AVLogo : selectedCurrency.name == 'Video Vault' ? VVLogo : i12logo } />
                    </div>}
                    <div className="buy-dq--text">
                       Confirm buying the pack? Cost:  {packData.credits} <FontAwesomeIcon className="single-pack--icon" icon={faCoins} />
                    </div>
                    <div className="buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--btn" onClick={() => {this.purchasePack(packData)}}>Confirm</button>
                    </div>
                    <div className="modal-close buy-dq--btn-wrapper">
                        <button className="regular-btn buy-dq--close" onClick={this.closeModal.bind(this)}>Close</button>
                    </div>
                </Modal>

        </div>  );
    }
}
 

const mapStateToProps = state => ({...state.currencyReducer})


const mapDispatchToProps = dispatch => ({
    checkAccount: () => dispatch(actions.checkAccount()),
    visitLibrary: () =>dispatch(actions.visitLibrary()),
  })


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackContainer));