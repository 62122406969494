import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import axios from 'axios';

import urls from '../../constants/async';
import {rootApiUrl} from '../../constants/async';

import Loader from 'react-loader-spinner';



class SidebarEditors extends Component {
    constructor(props) {
        super(props);
        this.state = { 
           editors: [1,2,3,4] ,
           isLoading: true,
           sort: "this-month",
           isShown: true,
           showAll: false,
         }
    }

    toggleShow(){
        this.setState({
            isShown: !this.state.isShown,
        })
    }

    toggleViewAll(){
        this.setState({
            showAll: !this.state.showAll,
        })

       
    }


    setSortMechanism(e){
        console.log('setting sort mechanism', e.target.value);

        const {editors} = this.state;

        const mechanism = e.target.value;

        if(!editors.length){ return false }

        this.setState({
            sort: mechanism,
        })

        switch(mechanism){
            case "all-time": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.allTimeRanking < b.allTimeRanking) return -1;
                    if (a.allTimeRanking > b.allTimeRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;  
                     
                    // console.log('findex', theEditor.editor.stageName, index);
                    if(index > 9){ 
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible = true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                this.setState({
                    editors: newRankings
                });

                return;

            }
            case "this-month": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.currentMonthRanking < b.currentMonthRanking) return -1;
                    if (a.currentMonthRanking > b.currentMonthRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;
                    
                    // console.log('findex', theEditor.editor.stageName, index);

                    if(index > 9){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible =true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    editors: newRankings
                });
                return;

            }
            case "last-month": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.lastMonthRanking < b.lastMonthRanking) return -1;
                    if (a.lastMonthRanking > b.lastMonthRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;

                    // console.log('findex', theEditor.editor.stageName, index);
                    // console.log(theEditor.editor.stageName)
                    
                    if(index > 9){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible = true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                this.setState({
                    editors: newRankings
                });
            }
            // default: {
            //     console.log('default')
            // };

        }


    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedCurrency && prevProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== prevProps.selectedCurrency._id){
            console.log('currency has changed');
            this.getEditors();
            //debugger;
            this.setState({
                sort: 'this-month',
            })
        }

        
    }
    

    componentDidMount(){

        // debugger;

        const clientWidth = window.innerWidth;
        if(clientWidth < 901){
            this.setState({isShown: false})
        }

        this.getEditors();


    }

    getEditors(){
        const {selectedCurrency} = this.props;
        
        if(typeof selectedCurrency === 'undefined'){
            return false;
        }
        const theUrl = rootApiUrl + urls.editors.getChart(selectedCurrency._id);

        this.setState({
            isLoading: true,
        })

        axios({
            url: theUrl,
            withCredentials: true,
        }).then(response => {
            // debugger;
            if(response && typeof response.data.Charts == 'object'){
                
                let count = 0;
                const allTimeRankings = [].concat(response.data.Charts).sort(function(a, b) {
                    // if(count > 10) return 0;
                    // count++;
                    if (a.currentMonthRanking < b.currentMonthRanking) return -1;
                    if (a.currentMonthRanking > b.currentMonthRanking) return 1;
                    return 0;
                  });

                const newRankings = [];

                 allTimeRankings.map((editor, index) => {
                    let theEditor = editor;
                    
                    if(index > 9){
                        // theEditor.editor.stageName = "ion"
                        theEditor.editor.isVisible = false;
                        // console.log(theEditor)
                    } else {
                        theEditor.editor.isVisible = true;
                    }
                    newRankings.push(theEditor)

                 })

            
                this.setState({
                    editors: newRankings,
                })

                this.setState({
                    isLoading: false,
                })

                // debugger;
            }
         
        })
    }

    render() { 

        const {editors, isLoading, sort, isShown} = this.state;

        return ( 
            typeof this.props.selectedCurrency != 'undefined' ? <section className="sidebar-editors--main-wrapper">
                <div className="sidebar-editors--title sidebar--title" style={{backgroundColor: this.props.selectedCurrency.color}}>
                Top editors
                <span onClick={this.toggleShow.bind(this)}>toggle</span>
                </div>
                {isShown && <div className="sidebar--dropdown">
                    <label className="sidebar--label">Sort by:</label>
                    <select onChange={this.setSortMechanism.bind(this)} className="sidebar--select sidebar-editors--sorting" value={sort}>
                    <option value="this-month">This Month</option>
                    <option value="all-time">All-Time</option>
                    <option value="last-month">Last Month</option>
                </select></div>}
                {isLoading ? <Loader /> : typeof editors != 'undefined' && (isShown) && editors.map((editor, index) => {
                    const editorData = editor.editor;
                    if(editorData.isVisible || this.state.showAll) return <div key={index} className="sidebar-editors--single-editor">
                        <div className="sidebar-editor--image"><img src={editorData.logoSquare.url} /><div className="sidebar--numbering">{index+1}</div></div>
                        <div className="sidebar-editor--stage-name"><Link to={"editors/"+editorData.stageName}>{editorData.stageName}</Link></div>
                    </div>
                })}
               {true && 
                <div onClick={this.toggleViewAll.bind(this)} className="sidebar-packs--single-pack sidebar--view-all">
                    View all    
                </div>
                }
            </section> : <section></section>
         );
    }
}

const mapDispatchToProps = dispatch => ({
})

const mapStateToProps = state => ({...state.currencyReducer})
 
export default connect(mapStateToProps, mapDispatchToProps)(SidebarEditors);