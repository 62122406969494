import {DOWNLOAD_FILE} from '../middleware/downloadMiddleware';
import { arrowFunctionExpression } from '@babel/types';

export default (state = {isLoading: false, visitingLibrary: false, libraryModal: false}, action) => {
    switch(action.type){
        case 'GET_CURRENCIES_BEGIN':
            return {
                ...state,
                isLoading: true,
            }
        case 'GET_CURRENCIES_SUCCESS':
            let newSelectedCurrency;
            if(action.payload.Currencies && action.payload.Currencies.length > 0){
                newSelectedCurrency = action.payload.Currencies[0];
            }  else {
                newSelectedCurrency = state.selectedCurrency;
            }
            //if there is an already selected currency, pick that one.
            action.payload.Currencies.map(currency => {
                if(state.selectedCurrency && state.selectedCurrency._id === currency._id){
                    newSelectedCurrency = currency;
                }
            })


            return {
                ...state,
                isLoading: false,
                currencies: action.payload,
                modifiedCurrencies: action.modifiedCurrencies,
                selectedCurrency: newSelectedCurrency
            }
        case "SET_CURRENCY":
            return {
                ...state,
                selectedCurrency: action.payload
            }
        case "GET_CURRENCIES_FAILURE":
            return {
                ...state,
                isLoading: false,
            }
        //reset this reducer on logout
        case "LOGOUT_SUCCESS":
            return {};
        case "ADD_TO_DOWNLOAD_QUEUE_BEGIN":
            return {
                ...state,
                addingToDownloadQueue: true,
            };
        case "ADD_TO_DOWNLOAD_QUEUE_SUCCESS":
            return {
                ...state,
                addingToDownloadQueue: false,
            };
        case "REMOVE_FROM_DOWNLOAD_QUEUE_BEGIN":
            return {
                ...state,
                addingToDownloadQueue: true,
            };
        case "REMOVE_FROM_DOWNLOAD_QUEUE_SUCCESS":
            return {
                ...state,
                addingToDownloadQueue: false,
            };
        case "DOWNLOAD_TRACK_BEGIN":
            return {
                ...state,
                isPurchasing: true,
                visitingLibary: false
            }
            
        case "DOWNLOAD_TRACK_FAILURE":
            return {
                ...state,
                isPurchasing: false
            }
        case "DOWNLOAD_TRACK_SUCCESS":
            return {
                ...state,
                isPurchasing: false,

            }
        case "VISIT_LIBRARY_START":
            return {
                ...state,
                visitingLibrary: true,
            }
        case "VISIT_LIBRARY_END":
            return {
                ...state,
                visitingLibrary: false,
            }
        case "OPEN_LIBRARY_MODAL_START":
            return {
                ...state,
                libraryModal: true,
            }
        case "OPEN_LIBRARY_MODAL_END":
            return {
                ...state,
                libraryModal: false,
            }
        default:
        return state
    }
}