import React from 'react'

function MultipleOptions({options, chooseOption, selectedOptions}) {
    return (
        <div className="multiple-options--wrapper">
            {options.map((option, index) => {
                const selectedClass = (selectedOptions.indexOf(option._id) !== -1) ? "selected"  : "" ;
                return <div className={"multiple-options--single-option " + selectedClass} onClick={() => chooseOption(option._id)} key={index} id={option._id}>{option.name}</div>
            })}
        </div>
    )
}

export default MultipleOptions
