import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';


import axios from 'axios';

import urls from '../constants/async';
import {rootApiUrl} from '../constants/async';

import Loader from 'react-loader-spinner';



class AllEditorsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            editors: [],
            sort: "last-month",
            nameFilter: '',
         }
    }

    setSortMechanism(e){
        console.log('setting sort mechanism', e.target.value);

        const {editors} = this.state;

        const mechanism = e.target.value;

        if(!editors.length){ return false }

        switch(mechanism){
            case "all-time": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.allTimeRanking < b.allTimeRanking) return -1;
                    if (a.allTimeRanking > b.allTimeRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;

                    // console.log(theEditor.editor.stageName)
                    
                    if(index > 10){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible = true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                this.setState({
                    editors: newRankings
                });

                return;

            }
            case "this-month": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.currentMonthRanking < b.currentMonthRanking) return -1;
                    if (a.currentMonthRanking > b.currentMonthRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;
                    
                    if(index > 10){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible =true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    editors: newRankings
                });
                return;

            }
            case "last-month": {
                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.lastMonthRanking < b.lastMonthRanking) return -1;
                    if (a.lastMonthRanking > b.lastMonthRanking) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;

                    // console.log(theEditor.editor.stageName)
                    
                    if(index > 10){
                        theEditor.editor.isVisible = false;
                    } else {
                        theEditor.editor.isVisible = true;
                    }

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                this.setState({
                    editors: newRankings
                });
            }
            case "a-z":{

                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.editor.stageName < b.editor.stageName) return -1;
                    if (a.editor.stageName > b.editor.stageName) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    editors: newRankings
                });
                return;



            }
            case "z-a":{

                const sortedEditors = [].concat(editors).sort(function(a, b) {
                    if (a.editor.stageName > b.editor.stageName) return -1;
                    if (a.editor.stageName < b.editor.stageName) return 1;
                    return 0;
                });

                const newRankings = [];

                sortedEditors.map((editor, index) => {
                    let theEditor = editor;

                    newRankings.push(theEditor)
                })

                console.log(newRankings)
                // debugger;
                this.setState({
                    editors: newRankings
                });
                return;

            }


        }


    }

    componentDidUpdate(previousProps){
        if(previousProps.selectedCurrency && previousProps.selectedCurrency._id && this.props.selectedCurrency && this.props.selectedCurrency._id && this.props.selectedCurrency._id !== previousProps.selectedCurrency._id){
            this.getEditors();
        }
    }

    getEditors(){

        this.setState({
            isLoading: true,
            editors: [],
            sort: "last-month"
        })

        const {selectedCurrency} = this.props

        // const theUrl = rootApiUrl + urls.editors.getChart(selectedCurrency._id);
        const theUrl = rootApiUrl + urls.editors.getChart(selectedCurrency._id);

        axios({
            withCredentials: true,
            url: theUrl
        }).then(response => {
            if(response.data && response.data.Charts){
                this.setState({
                    editors: response.data.Charts,
                    isLoading: false,
                })
            }
        })

    }

    componentDidMount(){
        this.getEditors();
    }

    filterName(e){
        this.setState({
            nameFilter: e.target.value
        })
    }

    render() { 
        const {editors, sort, isLoading, nameFilter} = this.state;
        const {selectedCurrency} = this.props;

        return ( 
            <section className="app--center-wrapper all-editors--main-wrapper">
                <div className="all-editors--title">All editors - {selectedCurrency.name} </div>

                <div className="all-editors--filters">
                    {!isLoading && <div className="all-editors--name-filter">
                        <label className="sidebar--label">Filter by name: </label>
                        <input onChange={this.filterName.bind(this)} />
                    </div>}

                    {!isLoading && <div className="all-editors--sort"> 
                        <label className="sidebar--label">Sort by:</label>
                        <select onChange={this.setSortMechanism.bind(this)} className="sidebar--select sidebar-editors--sorting" defaultValue={sort}>
                        <option value="all-time">Top: all-time</option>
                        <option value="this-month">Top: this month</option>
                        <option value="last-month">Top: last month</option>
                        <option value="a-z">A-Z</option>
                        <option value="z-a">Z-A</option>
                    </select></div>}
                </div>
                
                {!isLoading && editors.length > 1 ? <div className="editor-page--list">{editors.map((editor, index) => {
                    const editorData = editor.editor;

                    const isSearchActive = (typeof nameFilter != 'undefined' && nameFilter != '');
                    const isPartOfSearch = typeof editorData.stageName != 'undefined' && editorData.stageName.toLowerCase().includes(nameFilter.toLowerCase());

                    if(!isSearchActive || (isSearchActive && isPartOfSearch)) return (
                    <Link key={index} to={`editors/${editorData.stageName}`}>
                    <div className="single-editor--wrapper">
                        {typeof editorData.photo != 'undefined' && <div className="single-editor--image" style={{backgroundImage: `url(${editorData.photo.url})`}}></div>}
                        <div className="single-editor--name"><img src={editorData.logoSquare.url} />{editorData.stageName}</div>
                    </div> 
                    </Link>
                )})}</div> : <div>{!isLoading && "no editors found"}</div>}
                {isLoading && <Loader />}
                
            </section>
        );
    }
}
 

const mapDispatchToProps = dispatch => ({
})

const mapStateToProps = state => ({...state.currencyReducer})
 
export default connect(mapStateToProps, mapDispatchToProps)(AllEditorsContainer);