// import console = require("console");

const initialState = {
    volume: 100,
    isPlayerActive: false, 
    trackProgress: 0,
    progressChanger: "player",
    playedTracks: [],
    playedTracksIds: [],
} 

export default (state = initialState, action) => {
    switch(action.type){
        case "RELOAD_ACTIVE_TRACK_DATA": {
            return {
                ...state,
                activeTrack: {
                    ...state.activeTrack,
                    isPurchased: typeof action.isPurchased != 'undefined' ? action.isPurchased : state.activeTrack.isPurchased ,
                    isInDownloadQueue: action.isInDownloadQueue,
                }
            }
        }
        case "SET_ACTIVE_TRACK_SUCCESS":
            // let newTracks = [];
            // newTracks.push[action.payload];
            let newTracks = state.playedTracks;
            let newTrackIds = state.playedTracksIds;

            if(newTrackIds.indexOf(action.payload._id) == -1){
                // debugger;

                console.log('new tracks', newTrackIds);
                newTracks.push(action.payload);
                newTrackIds.push(action.payload._id);
            }
            
            
            
            const lastTrack = newTracks[newTracks.length];

            return {
                ...state,
                isPlayerActive: true,
                activeTrack: action.payload,   
                trackProgress: 1,
                isPlaying: true,
                isTrackLoading: true,
                playedTracks: newTracks,
                playedTracksIds: newTrackIds,
                lastTracks: lastTrack
            }
        case "UPDATE_ACTIVE_TRACK_DATA":
            return {
                ...state,
                activeTrack: action.payload,
            }
        case "TRACK_IS_LOADED":
            return {
                ...state,
                isTrackLoading: false,
        }
        case "SET_TRACK_PROGRESS":
            return {
                ...state,
                trackProgress: parseInt(action.progress),
                progressChanger: action.changer,
            }
        case "TOGGLE_PLAY":
            return {
                ...state,
                isPlaying: !state.isPlaying,
            }
        case "STOP_PLAY":
            // alert('stopped playing')
            return {
                ...state,
                isPlaying: false,
            }
        case "SET_VOLUME":
            return {
                ...state, 
                volume: action.volume
            }
        default: 
            return state
    }
}